/*===> Begin page name <===*/
.page-name {
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	min-height: 355px;
	padding: 40px 0;
	background-size: cover;
	background-position: center center;

	&::after {
		// content: url(../img/cloud.png);
		position: absolute;
		z-index: -1;
		top: 0;
		left: 10%;
	}

	.container {
		z-index: 10;

		* {
			margin-bottom: 0;
		}
	}

	.page-name-img {
		position: absolute;
		z-index: -1;
		top: -15px;
		left: 22%;
	}

	.layer-background {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		overflow: hidden;
	}

	svg {
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		width: 100%;
		height: 100%;
		min-width: 1920px;
		transform: translate(-50%, -50%);
	}

	@include breakpoint(small) {
		min-height: 300px;
	}

	@include breakpoint(extra-small) {
		min-height: 260px;
	}

	@include breakpoint(mobile) {
		min-height: 200px;
	}
}
/*===> End page name <===*/