/*===> Begin header <===*/
.header {
	position: relative;
	z-index: 100;
	padding: 30px 0;
	transform: translate(0, 0);
	transition: transform .3s;

	&.is-scroll {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 900;
		width: 100%;
		padding: 5px 0;
		background: #fff;
		box-shadow: 0px 6px 12px rgba(3,109,136,0.14);

		.top-line {
			&::before, &::after {
				display: none;
			}
		}

		.top-img {
			bottom: 80%;
		}
	}

	.top-line {
		position: relative;
		&::before {
			// content: url('../img/cloud.png');
			position: absolute;
			left: 5%;
			top: 130%;
			width: 307px;
			height: 132px;
		}
		&::after {
			// content: url('../img/cloud.png');
			position: absolute;
			bottom: 80%;
			right: -5%;
			width: 307px;
			height: 132px;
		}
	}

	.top-img {
		position: absolute;
		bottom: 55%;
		right: 2%;
		width: 307px;
		height: 132px;
	}

	@include breakpoint(leptop) {
		.top-line {
			&::after {
				right: 0%;
			}
		}
	}

	@include breakpoint(small) {
		padding: 15px 0;
		.top-line {
			&::before, &::after {
				display: none;
			}
		}

		.top-img {
			display: none;
		}
	}
}
/*===> End header <===*/