/*===> Begin quickLinks <===*/
.quickLinks-wrap {
	position: fixed;
	top: 50%;
	right: 0;
	z-index: 500;
	transform: translateY(-50%);

	&.mobile {
		position: relative;
		z-index: 950;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		transform: translateY(0);

		.quickLinks-item {
			flex-grow: 1;
			width: 25%;

			&:hover {
				.quickLinks-desc {
					visibility: hidden;
				}
			}

			&:nth-child(1) {
				order: 4;
			}

			&:nth-child(2) {
				order: 5;
			}

			&:nth-child(3) {
				order: 6;
			}

			&:nth-child(4) {
				order: 7;
			}

			.quickLinks-desc.active {
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}

		.quickLinks-head {
			width: 100%;
			height: 65px;
			margin-bottom: 0;
			border-radius: 0;

			img {
				height: 18px;
				margin-bottom: 5px;
			}
		}

		.quickLinks-desc {
			position: static;
			display: none;
			width: 100%;
			padding-top: 0;
			visibility: visible;
			opacity: 1;
			transition: all 0s;
			border-radius: 0;

			&.show {
				display: block;
			}

			&:nth-child(5) {
				order: 0;
				background: $main_color;
			}

			&:nth-child(6) {
				order: 1;
				background: $component_color_1;
			}

			&:nth-child(7) {
				order: 2;
				background: $component_color_2;
			}

			&:nth-child(8) {
				order: 3;
				background: $component_color_3;
			}

			.quickLinks-content {
				padding: 15px 20px;
				background-color: transparent;

				h4 {
					display: none;
				}

				.contact-list a, .contact-list p {
					color: #fff;

					img {
						filter: brightness(0) invert(1);
					}
				}

				.download-list {
					p {
						justify-content: flex-start;
						span {
							color: #fff;
							img {
								filter: brightness(0) invert(1);
							}
						}

						a {
							padding-left: 15px;
							color: #fff;
						}
					}
				}
			}
		}

		.book-form {
			color: #fff;

			::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			    color: #fff;
			    opacity: 1; /* Firefox */
			}

			:-ms-input-placeholder { /* Internet Explorer 10-11 */
			    color: #fff;
			}

			::-ms-input-placeholder { /* Microsoft Edge */
			    color: #fff;
			}

			img {
				filter: brightness(0) invert(1);
			}

			.check-list-wrap {
				* {
					color: #fff !important;
				}
			}

			input {
				color: #fff;
			}
		}
	}

	.quickLinks-item {
		position: relative;

		&:hover {
			.quickLinks-head {
				position: relative;
				border-radius: 0;
			}

			.quickLinks-desc {
				top: 0;
				opacity: 1;
				visibility: visible;
			}
		}

		&:nth-child(1) {
			.quickLinks-head, .quickLinks-desc {
				background: $main_color;
			}
		}

		&:nth-child(2) {
			.quickLinks-head, .quickLinks-desc {
				background: $component_color_1;
				box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
			}
		}

		&:nth-child(3) {
			.quickLinks-head, .quickLinks-desc {
				background: $component_color_2;
				box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
			}
		}

		&:nth-child(4) {
			.quickLinks-head, .quickLinks-desc {
				background: $component_color_3;
				box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
			}
		}
	}

	.quickLinks-head {
		width: 104px;
		height: 98px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 2px;
		border-radius: 9px 0 0 9px;
		background: $main_color;
		cursor: pointer;
		transition: border-radius .3s;
		transition: width .3s;

		img {
			margin-bottom: 10px;
			transition: margin .3s, width .3s, height .3s;
		}

		p {
			margin-bottom: 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			color: #fff;
			white-space: nowrap;
			transition: transform .3s height .3s;
		}
	}

	.quickLinks-desc {
		position: absolute;
		top: 10%;
		right: 100%;
		width: 445px;
		padding-top: 4px;
		border-radius: 9px 0 9px 9px;
		box-shadow: 0px 9px 14px rgba(113, 146, 189, 0.5);
		opacity: 0;
		visibility: hidden;
		transition: all .3s;

		&.hover {
			opacity: 1;
			visibility: visible;
		}

		&:nth-child(5) {
			background: $main_color;
		}

		&:nth-child(6) {
			background: $component_color_1;
			box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
		}

		&:nth-child(7) {
			background: $component_color_2;
			box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
		}

		&:nth-child(8) {
			background: $component_color_3;
			box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
		}

		.quickLinks-content {
			padding: 25px 30px;
			border-radius: 9px;
			background: #fff;
		}

		.map-block {
			height: 300px;
			border-radius: 9px;
		}
	}

	@include breakpoint(leptop) {
		top: 45%;
	}

	@media (min-width: 991px) {
		&:not(.mobile) {
			&.scroll {
				opacity: .7;

				&:hover {
					opacity: 1;
					.quickLinks-head {
						width: 104px;

						img {
							margin-bottom: 10px;
							width: auto;
						}

						p {
							height: auto;
							transform: scale(1);
						}
					}
				}

				.quickLinks-head {
					width: 25px;

					img {
						margin: 0;
						width: 15px;
					}

					p {
						transform: scale(0);
						height: 0;
					}
				}
			}
		}
	}

	@media (max-height: 700px) {
		.quickLinks-item {
			position: relative;

			&:hover {
				&:nth-child(3), &:nth-child(4) {
					.quickLinks-desc {
						bottom: 0;
					}
				}
			}

			&:nth-child(3), &:nth-child(4) {
				.quickLinks-desc {
					top: auto;
					bottom: 0;
					padding-top: 0px;
					padding-bottom: 4px;
					border-radius: 9px 9px 0 9px;

					.quickLinks-content {
						padding: 25px 30px;
						border-radius: 9px;
						background: #fff;
					}
				}
			}
		}
	}

	@include breakpoint(mobile) {
		.quickLinks-item {
			.quickLinks-head {
				height: 36px;
				img {
					margin-bottom: 0;
				}

				p {
					display: none;
				}
			}
		}
	}
}
/*===> End quickLinks <===*/