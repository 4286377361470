/*===> Begin practices-list-wrap <===*/
.practices-list-wrap {
	position: relative;
	padding-left: 100px;
	z-index: 1;

	.practices-ico {
		position: absolute;
		top: 0;
		left: 0;
		width: 70px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $main_color;
		border-radius: 8px;

		&.prohibited {
			background: $component_color_1;
		}

		img {
			height: 22px;
			filter: brightness(0) invert(1);
		}

	}

	.practices-list {
		&.prohibited {
			li {
				&::after {
					content: url('../img/close.svg');
				}
			}
		}

		li {
			&::after {
				content: url('../img/check.svg');
			}
		}
	}

	@include breakpoint(extra-small) {
		padding-left: 50px;
		.practices-ico {
			width: 30px;
			height: 30px;

			img {
				height: 18px;
			}

		}
	}
}

.guidance-section {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		right: 15%;
		top: 120px;
		width: 300px;
		height: 146px;
		background: url(../img/cloud.svg);
		background-size: auto 100%;
		background-repeat: no-repeat;
	}

	.row {
		z-index: -10;
	}

	@include breakpoint(extra-small) {
		&::after {
			display: none;
		}
	}
}
/*===> End practices-list-wrap <===*/