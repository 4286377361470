/*===> Begin programs-list <===*/
.programs-list {
	>li {
		padding-left: 0;
		&::after {
			display: none;
		}

		&:nth-child(even) {
			.program-preview {
				flex-direction: row-reverse;

				.program-description {
					&::after {
						right: auto;
						left: 0;
					}
				}
			}
		}

		&:nth-child(3n+1) {
			.program-preview {
				border: 4px solid $border_testimonial_1;
				box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);

				.program-description {
					&::after {
						color: $border_testimonial_1;
					}
				}
			}

			.program-slider-wrap {
				.program-age {
					background: $component_color_1;
					box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);

					p {
						span {
							color: $component_color_1_light;
						}
					}
				}
			}
		}

		&:nth-child(3n+2) {
			.program-preview {
				border: 4px solid $border_testimonial_2;
				box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21);

				.program-description {
					&::after {
						color: $border_testimonial_2;
					}
				}
			}

			.program-slider-wrap {
				.program-age {
					background: $component_color_2;
					box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21);

					p {
						span {
							color: $component_color_2_light;
						}
					}
				}
			}
		}

		&:nth-child(3n+3) {
			.program-preview {
				border: 4px solid $border_testimonial_3;
				box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14);

				.program-description {
					&::after {
						color: $border_testimonial_3;
					}
				}
			}

			.program-slider-wrap {
				.program-age {
					background: $component_color_3;
					box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14);

					p {
						span {
							color: $component_color_3_light;
						}
					}
				}
			}
		}
	}

	@include breakpoint(small) {
		>li {
			&:nth-child(even) {
				.program-preview {
					flex-direction: column;
				}
			}
		}
	}
}
/*===> End programs-list <===*/