/*===> Begin svg-block <===*/
.svg-block {

	&.image-left {
		image {
			transform: translateX(-19%);
		}
	}

	&.video-block {
		position: relative;

		p {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		img {
			border-radius: 12px;
		}
	}

	svg {
		width: 100%;
		mask {
			mask-type: alpha;
		}
	}

	@include breakpoint(extra-small) {
		max-width: 540px;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint(mobile) {
		max-width: 300px;
	}
}
/*===> End svg-block <===*/