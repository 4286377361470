/*===> Begin resources-block <===*/
.resources-block {
	max-width: 195px;
	margin-bottom: 40px;
	margin-left: auto;
	margin-right: auto;
	
	.resources-img {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 170px;
		margin-bottom: 25px;
		padding: 45px;
		background: #FFFFFF;
		box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
		border-radius: 12px;

		.resource-ico {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 75px;
		}
	}

	.resources-text {
		text-align: center;

		p {
			margin-bottom: 10px;
			font-family: $head_color;
			font-weight: 500;
			color: $head_color;
		}

		a {
			font-family: $head_color;
			font-weight: 500;
			color: $main_color;

			img {
				margin-right: 10px;
			}
		}
	}
}
/*===> End resources-block <===*/