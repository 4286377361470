/*===> Begin form controls <===*/
input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 0;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $border_color;
    font-family: $main_font;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: $text_color;

    @include breakpoint(small) {
        font-size: 14px;
    }
}

.input-area {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .input-wrap {
        width: 50%;
        padding: 0 15px;

        input {
        }
    }

    @include breakpoint(mobile) {
        .input-wrap {
            width: 100%;
        }
    }
}

textarea {
    display: block;
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 20px 0;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $border_color;
    font-family: $main_font;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    color: $text_color;
    resize: none;

    @include breakpoint(small) {
        font-size: 14px;
    }
}

.successform {
    display: none;
    p {
        font-weight: 500;
        color: $main_color;
    }
}

.errorform {
    display: none;
    p {
        font-weight: 500;
        color: $error_color;
    }
}

label.error {
    display: block;
    font-size: 12px;
    color: $error_color;
}
/*===> End form controls <===*/