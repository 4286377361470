/*===> Begin testimonial-list <===*/
.testimonial-list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: -60px;

	 &.grid {
		margin-left: 0;
		margin-right: 0;
	}

	li {
		width: 33.33%;
		margin-bottom: 0;
		padding-left: 15px;
		padding-right: 15px;

		&::after {
			display: none;
		}

		&:nth-child(3n+1) {
			.testimonial-block {
				border: 4px solid $border_testimonial_1;
				box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
			}
		}

		&:nth-child(3n+2) {
			.testimonial-block {
				border: 4px solid $border_testimonial_2;
				box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21);
			}
		}

		&:nth-child(3n+3) {
			.testimonial-block {
				border: 4px solid $border_testimonial_3;
				box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14);
			}
		}
	}

	.slick-dots {
		padding-top: 25px;
		display: none;
	}

	@include breakpoint(medium) {
		.slick-dots {
			display: flex;
			margin-bottom: 40px;
			padding-top: 0;
		}
	}

	@include breakpoint(small) {
		li {
			width: 50%;
		}
	}

	@include breakpoint(extra-small) {
		li {
			width: 100%;
		}
	}
}

.testimonial-wrap {
	margin-left: -15px;
	margin-right: -15px;
}
/*===> End testimonial-list <===*/