@charset "UTF-8";
/* ============================================================

 Theme Name: Child IT
 Theme URI: http://
 Description: The Child IT Multipage Template
 Author: MPIThemes
 Author URI: https://
 Version: 1.0

============================================================== */
/* ==============================================================

 ====>> Table Of Content

	* COLORS
	* Fonts
	* base style

	* ===== Components ===== *
		* section header
		* square icon
		* round icon
		* icons list
		* first letter
		* table
		* button
		* checkbox
		* time picker
		* datetimepicker
		* read-more
		* svg-block
		* video-btn
		* centre-tab-list
		* pagination
		* on-scroll
		* up-btn
	

	* ==== Slick ==== *
		* slick arrow
		* slick-dots
		

	* ===== Forms ===== *
		* Main form
		* signup form
		* book form
		* form controls
		* search-form

	* ===== Widgets ===== *
		* page name
		* wave block
		* contact list
		* soc link
		* quickLinks
		* download list
		* calendar table
		* main slider
		* block-header
		* text-block
		* education short
		* learning-elements-wrap
		* education-slier
		* video-section
		* lightbox
		* advantage-list
		* food-section
		* order-list-2
		* centre-tab-content
		* teacher-card
		* teacher-slider
		* tab-values
		* testimonial-block
		* testimonial-list
		* gallery-prewiev-wrap
		* news-block
		* news-slider
		* terms-list
		* pricing-packages-card
		* pricing-packages-slider
		* statement-card
		* count-list
		* accordion-block
		* program-preview
		* sidebar
		* calenar
		* side-post
		* tag-list
		* blog-post
		* post-author
		* blog-post-nav
		* comment-block
		* comments-list
		* comments
		* parent-info-list
		* parent-info-item
		* resources-list
		* resources-block
		* practices-list-wrap
		* full-width-gallery-grid
		* boxed-gallery-grid
		* event-table
		* single-event
		* short-event
		* contact-container

		* ==== Footer ==== *
			* footer
		
		* ==== Header ==== *
			* header
			* main nav
			* top line
			* main nav list
			* header controls

	* ===== Layouts ===== *
		* 
		


============================================================== */
/*COLORS*/
/*------------------------------------------------------------------
# [Color codes]
#main_color				 : #46AADC;
#main_color_light		 : #F0F8FB;
#hover_color			 : #46AADD;
#component_color_1		 : #FFAF60; // (orange)
#component_color_1_light : #FFD1AA; // (orange)
#component_color_2		 : #76BEBA; // (green)
#component_color_2_light : #B5D9D7; // (green)
#component_color_3		 : #7192BD; // (blue)
#component_color_3_light : #9FCFE9; // (blue)
#table_color			 : #EAF5F8;
#light_color			 : #F8FBFB;
#border_color			 : #CEDDE0;
#border_dark			 : #555555;
#bloquote_bg			 : #F0F8FA;

#border_testimonial_1	 : #EAF5F8;
#border_testimonial_2	 : #ECF5EE;
#border_testimonial_3	 : #F1EFEC;

#head_color				 : #3B4757;
#text_color				 : #777777;
#text_dark				 : #333333;

# */
/*FONTS*/
/*------------------------------------------------------------------
# [Fonts]

main_font   : 'Roboto'
head_font   : 'Poppins'
second_font : 'Georgia'

# */
h3 + h1 {
  margin-top: 10px; }

.letter-spacing-0 {
  letter-spacing: 0; }

.pt-0 {
  padding-top: 0 !important; }

.pt-5px 8px {
  padding-top: 5px 8px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-18 {
  padding-top: 18px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-31 {
  padding-top: 31px !important; }

.pt-32 {
  padding-top: 32px !important; }

.pt-33 {
  padding-top: 33px !important; }

.pt-34 {
  padding-top: 34px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-36 {
  padding-top: 36px !important; }

.pt-37 {
  padding-top: 37px !important; }

.pt-38 {
  padding-top: 38px !important; }

.pt-39 {
  padding-top: 39px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-105 {
  padding-top: 105px !important; }

.pt-110 {
  padding-top: 110px !important; }

.pt-115 {
  padding-top: 115px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pt-125 {
  padding-top: 125px !important; }

.pt-130 {
  padding-top: 130px !important; }

.pt-135 {
  padding-top: 135px !important; }

.pt-140 {
  padding-top: 140px !important; }

.pt-145 {
  padding-top: 145px !important; }

.pt-150 {
  padding-top: 150px !important; }

.pt-155 {
  padding-top: 155px !important; }

.pt-160 {
  padding-top: 160px !important; }

.pt-170 {
  padding-top: 170px !important; }

.pt-175 {
  padding-top: 175px !important; }

.pt-180 {
  padding-top: 180px !important; }

.pt-190 {
  padding-top: 190px !important; }

.pt-200 {
  padding-top: 200px !important; }

.pt-210 {
  padding-top: 210px !important; }

.pt-220 {
  padding-top: 220px !important; }

.pt-240 {
  padding-top: 240px !important; }

.pt-280 {
  padding-top: 280px !important; }

@media (min-width: 1px) {
  .pt-xs-0 {
    padding-top: 0 !important; }
  .pt-xs-5px 8px {
    padding-top: 5px 8px !important; }
  .pt-xs-10 {
    padding-top: 10px !important; }
  .pt-xs-15 {
    padding-top: 15px !important; }
  .pt-xs-18 {
    padding-top: 18px !important; }
  .pt-xs-20 {
    padding-top: 20px !important; }
  .pt-xs-25 {
    padding-top: 25px !important; }
  .pt-xs-30 {
    padding-top: 30px !important; }
  .pt-xs-31 {
    padding-top: 31px !important; }
  .pt-xs-32 {
    padding-top: 32px !important; }
  .pt-xs-33 {
    padding-top: 33px !important; }
  .pt-xs-34 {
    padding-top: 34px !important; }
  .pt-xs-35 {
    padding-top: 35px !important; }
  .pt-xs-36 {
    padding-top: 36px !important; }
  .pt-xs-37 {
    padding-top: 37px !important; }
  .pt-xs-38 {
    padding-top: 38px !important; }
  .pt-xs-39 {
    padding-top: 39px !important; }
  .pt-xs-40 {
    padding-top: 40px !important; }
  .pt-xs-45 {
    padding-top: 45px !important; }
  .pt-xs-50 {
    padding-top: 50px !important; }
  .pt-xs-55 {
    padding-top: 55px !important; }
  .pt-xs-60 {
    padding-top: 60px !important; }
  .pt-xs-65 {
    padding-top: 65px !important; }
  .pt-xs-70 {
    padding-top: 70px !important; }
  .pt-xs-75 {
    padding-top: 75px !important; }
  .pt-xs-80 {
    padding-top: 80px !important; }
  .pt-xs-85 {
    padding-top: 85px !important; }
  .pt-xs-90 {
    padding-top: 90px !important; }
  .pt-xs-95 {
    padding-top: 95px !important; }
  .pt-xs-100 {
    padding-top: 100px !important; }
  .pt-xs-105 {
    padding-top: 105px !important; }
  .pt-xs-110 {
    padding-top: 110px !important; }
  .pt-xs-115 {
    padding-top: 115px !important; }
  .pt-xs-120 {
    padding-top: 120px !important; }
  .pt-xs-125 {
    padding-top: 125px !important; }
  .pt-xs-130 {
    padding-top: 130px !important; }
  .pt-xs-135 {
    padding-top: 135px !important; }
  .pt-xs-140 {
    padding-top: 140px !important; }
  .pt-xs-145 {
    padding-top: 145px !important; }
  .pt-xs-150 {
    padding-top: 150px !important; }
  .pt-xs-155 {
    padding-top: 155px !important; }
  .pt-xs-160 {
    padding-top: 160px !important; }
  .pt-xs-170 {
    padding-top: 170px !important; }
  .pt-xs-175 {
    padding-top: 175px !important; }
  .pt-xs-180 {
    padding-top: 180px !important; }
  .pt-xs-190 {
    padding-top: 190px !important; }
  .pt-xs-200 {
    padding-top: 200px !important; }
  .pt-xs-210 {
    padding-top: 210px !important; }
  .pt-xs-220 {
    padding-top: 220px !important; }
  .pt-xs-240 {
    padding-top: 240px !important; }
  .pt-xs-280 {
    padding-top: 280px !important; } }

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-5px 8px {
    padding-top: 5px 8px !important; }
  .pt-sm-10 {
    padding-top: 10px !important; }
  .pt-sm-15 {
    padding-top: 15px !important; }
  .pt-sm-18 {
    padding-top: 18px !important; }
  .pt-sm-20 {
    padding-top: 20px !important; }
  .pt-sm-25 {
    padding-top: 25px !important; }
  .pt-sm-30 {
    padding-top: 30px !important; }
  .pt-sm-31 {
    padding-top: 31px !important; }
  .pt-sm-32 {
    padding-top: 32px !important; }
  .pt-sm-33 {
    padding-top: 33px !important; }
  .pt-sm-34 {
    padding-top: 34px !important; }
  .pt-sm-35 {
    padding-top: 35px !important; }
  .pt-sm-36 {
    padding-top: 36px !important; }
  .pt-sm-37 {
    padding-top: 37px !important; }
  .pt-sm-38 {
    padding-top: 38px !important; }
  .pt-sm-39 {
    padding-top: 39px !important; }
  .pt-sm-40 {
    padding-top: 40px !important; }
  .pt-sm-45 {
    padding-top: 45px !important; }
  .pt-sm-50 {
    padding-top: 50px !important; }
  .pt-sm-55 {
    padding-top: 55px !important; }
  .pt-sm-60 {
    padding-top: 60px !important; }
  .pt-sm-65 {
    padding-top: 65px !important; }
  .pt-sm-70 {
    padding-top: 70px !important; }
  .pt-sm-75 {
    padding-top: 75px !important; }
  .pt-sm-80 {
    padding-top: 80px !important; }
  .pt-sm-85 {
    padding-top: 85px !important; }
  .pt-sm-90 {
    padding-top: 90px !important; }
  .pt-sm-95 {
    padding-top: 95px !important; }
  .pt-sm-100 {
    padding-top: 100px !important; }
  .pt-sm-105 {
    padding-top: 105px !important; }
  .pt-sm-110 {
    padding-top: 110px !important; }
  .pt-sm-115 {
    padding-top: 115px !important; }
  .pt-sm-120 {
    padding-top: 120px !important; }
  .pt-sm-125 {
    padding-top: 125px !important; }
  .pt-sm-130 {
    padding-top: 130px !important; }
  .pt-sm-135 {
    padding-top: 135px !important; }
  .pt-sm-140 {
    padding-top: 140px !important; }
  .pt-sm-145 {
    padding-top: 145px !important; }
  .pt-sm-150 {
    padding-top: 150px !important; }
  .pt-sm-155 {
    padding-top: 155px !important; }
  .pt-sm-160 {
    padding-top: 160px !important; }
  .pt-sm-170 {
    padding-top: 170px !important; }
  .pt-sm-175 {
    padding-top: 175px !important; }
  .pt-sm-180 {
    padding-top: 180px !important; }
  .pt-sm-190 {
    padding-top: 190px !important; }
  .pt-sm-200 {
    padding-top: 200px !important; }
  .pt-sm-210 {
    padding-top: 210px !important; }
  .pt-sm-220 {
    padding-top: 220px !important; }
  .pt-sm-240 {
    padding-top: 240px !important; }
  .pt-sm-280 {
    padding-top: 280px !important; } }

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-5px 8px {
    padding-top: 5px 8px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pt-md-18 {
    padding-top: 18px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pt-md-31 {
    padding-top: 31px !important; }
  .pt-md-32 {
    padding-top: 32px !important; }
  .pt-md-33 {
    padding-top: 33px !important; }
  .pt-md-34 {
    padding-top: 34px !important; }
  .pt-md-35 {
    padding-top: 35px !important; }
  .pt-md-36 {
    padding-top: 36px !important; }
  .pt-md-37 {
    padding-top: 37px !important; }
  .pt-md-38 {
    padding-top: 38px !important; }
  .pt-md-39 {
    padding-top: 39px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pt-md-45 {
    padding-top: 45px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pt-md-55 {
    padding-top: 55px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pt-md-65 {
    padding-top: 65px !important; }
  .pt-md-70 {
    padding-top: 70px !important; }
  .pt-md-75 {
    padding-top: 75px !important; }
  .pt-md-80 {
    padding-top: 80px !important; }
  .pt-md-85 {
    padding-top: 85px !important; }
  .pt-md-90 {
    padding-top: 90px !important; }
  .pt-md-95 {
    padding-top: 95px !important; }
  .pt-md-100 {
    padding-top: 100px !important; }
  .pt-md-105 {
    padding-top: 105px !important; }
  .pt-md-110 {
    padding-top: 110px !important; }
  .pt-md-115 {
    padding-top: 115px !important; }
  .pt-md-120 {
    padding-top: 120px !important; }
  .pt-md-125 {
    padding-top: 125px !important; }
  .pt-md-130 {
    padding-top: 130px !important; }
  .pt-md-135 {
    padding-top: 135px !important; }
  .pt-md-140 {
    padding-top: 140px !important; }
  .pt-md-145 {
    padding-top: 145px !important; }
  .pt-md-150 {
    padding-top: 150px !important; }
  .pt-md-155 {
    padding-top: 155px !important; }
  .pt-md-160 {
    padding-top: 160px !important; }
  .pt-md-170 {
    padding-top: 170px !important; }
  .pt-md-175 {
    padding-top: 175px !important; }
  .pt-md-180 {
    padding-top: 180px !important; }
  .pt-md-190 {
    padding-top: 190px !important; }
  .pt-md-200 {
    padding-top: 200px !important; }
  .pt-md-210 {
    padding-top: 210px !important; }
  .pt-md-220 {
    padding-top: 220px !important; }
  .pt-md-240 {
    padding-top: 240px !important; }
  .pt-md-280 {
    padding-top: 280px !important; } }

@media (min-width: 992px) {
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-5px 8px {
    padding-top: 5px 8px !important; }
  .pt-lg-10 {
    padding-top: 10px !important; }
  .pt-lg-15 {
    padding-top: 15px !important; }
  .pt-lg-18 {
    padding-top: 18px !important; }
  .pt-lg-20 {
    padding-top: 20px !important; }
  .pt-lg-25 {
    padding-top: 25px !important; }
  .pt-lg-30 {
    padding-top: 30px !important; }
  .pt-lg-31 {
    padding-top: 31px !important; }
  .pt-lg-32 {
    padding-top: 32px !important; }
  .pt-lg-33 {
    padding-top: 33px !important; }
  .pt-lg-34 {
    padding-top: 34px !important; }
  .pt-lg-35 {
    padding-top: 35px !important; }
  .pt-lg-36 {
    padding-top: 36px !important; }
  .pt-lg-37 {
    padding-top: 37px !important; }
  .pt-lg-38 {
    padding-top: 38px !important; }
  .pt-lg-39 {
    padding-top: 39px !important; }
  .pt-lg-40 {
    padding-top: 40px !important; }
  .pt-lg-45 {
    padding-top: 45px !important; }
  .pt-lg-50 {
    padding-top: 50px !important; }
  .pt-lg-55 {
    padding-top: 55px !important; }
  .pt-lg-60 {
    padding-top: 60px !important; }
  .pt-lg-65 {
    padding-top: 65px !important; }
  .pt-lg-70 {
    padding-top: 70px !important; }
  .pt-lg-75 {
    padding-top: 75px !important; }
  .pt-lg-80 {
    padding-top: 80px !important; }
  .pt-lg-85 {
    padding-top: 85px !important; }
  .pt-lg-90 {
    padding-top: 90px !important; }
  .pt-lg-95 {
    padding-top: 95px !important; }
  .pt-lg-100 {
    padding-top: 100px !important; }
  .pt-lg-105 {
    padding-top: 105px !important; }
  .pt-lg-110 {
    padding-top: 110px !important; }
  .pt-lg-115 {
    padding-top: 115px !important; }
  .pt-lg-120 {
    padding-top: 120px !important; }
  .pt-lg-125 {
    padding-top: 125px !important; }
  .pt-lg-130 {
    padding-top: 130px !important; }
  .pt-lg-135 {
    padding-top: 135px !important; }
  .pt-lg-140 {
    padding-top: 140px !important; }
  .pt-lg-145 {
    padding-top: 145px !important; }
  .pt-lg-150 {
    padding-top: 150px !important; }
  .pt-lg-155 {
    padding-top: 155px !important; }
  .pt-lg-160 {
    padding-top: 160px !important; }
  .pt-lg-170 {
    padding-top: 170px !important; }
  .pt-lg-175 {
    padding-top: 175px !important; }
  .pt-lg-180 {
    padding-top: 180px !important; }
  .pt-lg-190 {
    padding-top: 190px !important; }
  .pt-lg-200 {
    padding-top: 200px !important; }
  .pt-lg-210 {
    padding-top: 210px !important; }
  .pt-lg-220 {
    padding-top: 220px !important; }
  .pt-lg-240 {
    padding-top: 240px !important; }
  .pt-lg-280 {
    padding-top: 280px !important; } }

@media (min-width: 1230px) {
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-5px 8px {
    padding-top: 5px 8px !important; }
  .pt-xl-10 {
    padding-top: 10px !important; }
  .pt-xl-15 {
    padding-top: 15px !important; }
  .pt-xl-18 {
    padding-top: 18px !important; }
  .pt-xl-20 {
    padding-top: 20px !important; }
  .pt-xl-25 {
    padding-top: 25px !important; }
  .pt-xl-30 {
    padding-top: 30px !important; }
  .pt-xl-31 {
    padding-top: 31px !important; }
  .pt-xl-32 {
    padding-top: 32px !important; }
  .pt-xl-33 {
    padding-top: 33px !important; }
  .pt-xl-34 {
    padding-top: 34px !important; }
  .pt-xl-35 {
    padding-top: 35px !important; }
  .pt-xl-36 {
    padding-top: 36px !important; }
  .pt-xl-37 {
    padding-top: 37px !important; }
  .pt-xl-38 {
    padding-top: 38px !important; }
  .pt-xl-39 {
    padding-top: 39px !important; }
  .pt-xl-40 {
    padding-top: 40px !important; }
  .pt-xl-45 {
    padding-top: 45px !important; }
  .pt-xl-50 {
    padding-top: 50px !important; }
  .pt-xl-55 {
    padding-top: 55px !important; }
  .pt-xl-60 {
    padding-top: 60px !important; }
  .pt-xl-65 {
    padding-top: 65px !important; }
  .pt-xl-70 {
    padding-top: 70px !important; }
  .pt-xl-75 {
    padding-top: 75px !important; }
  .pt-xl-80 {
    padding-top: 80px !important; }
  .pt-xl-85 {
    padding-top: 85px !important; }
  .pt-xl-90 {
    padding-top: 90px !important; }
  .pt-xl-95 {
    padding-top: 95px !important; }
  .pt-xl-100 {
    padding-top: 100px !important; }
  .pt-xl-105 {
    padding-top: 105px !important; }
  .pt-xl-110 {
    padding-top: 110px !important; }
  .pt-xl-115 {
    padding-top: 115px !important; }
  .pt-xl-120 {
    padding-top: 120px !important; }
  .pt-xl-125 {
    padding-top: 125px !important; }
  .pt-xl-130 {
    padding-top: 130px !important; }
  .pt-xl-135 {
    padding-top: 135px !important; }
  .pt-xl-140 {
    padding-top: 140px !important; }
  .pt-xl-145 {
    padding-top: 145px !important; }
  .pt-xl-150 {
    padding-top: 150px !important; }
  .pt-xl-155 {
    padding-top: 155px !important; }
  .pt-xl-160 {
    padding-top: 160px !important; }
  .pt-xl-170 {
    padding-top: 170px !important; }
  .pt-xl-175 {
    padding-top: 175px !important; }
  .pt-xl-180 {
    padding-top: 180px !important; }
  .pt-xl-190 {
    padding-top: 190px !important; }
  .pt-xl-200 {
    padding-top: 200px !important; }
  .pt-xl-210 {
    padding-top: 210px !important; }
  .pt-xl-220 {
    padding-top: 220px !important; }
  .pt-xl-240 {
    padding-top: 240px !important; }
  .pt-xl-280 {
    padding-top: 280px !important; } }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-5px 8px {
  padding-bottom: 5px 8px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-18 {
  padding-bottom: 18px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-31 {
  padding-bottom: 31px !important; }

.pb-32 {
  padding-bottom: 32px !important; }

.pb-33 {
  padding-bottom: 33px !important; }

.pb-34 {
  padding-bottom: 34px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-36 {
  padding-bottom: 36px !important; }

.pb-37 {
  padding-bottom: 37px !important; }

.pb-38 {
  padding-bottom: 38px !important; }

.pb-39 {
  padding-bottom: 39px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-105 {
  padding-bottom: 105px !important; }

.pb-110 {
  padding-bottom: 110px !important; }

.pb-115 {
  padding-bottom: 115px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pb-125 {
  padding-bottom: 125px !important; }

.pb-130 {
  padding-bottom: 130px !important; }

.pb-135 {
  padding-bottom: 135px !important; }

.pb-140 {
  padding-bottom: 140px !important; }

.pb-145 {
  padding-bottom: 145px !important; }

.pb-150 {
  padding-bottom: 150px !important; }

.pb-155 {
  padding-bottom: 155px !important; }

.pb-160 {
  padding-bottom: 160px !important; }

.pb-170 {
  padding-bottom: 170px !important; }

.pb-175 {
  padding-bottom: 175px !important; }

.pb-180 {
  padding-bottom: 180px !important; }

.pb-190 {
  padding-bottom: 190px !important; }

.pb-200 {
  padding-bottom: 200px !important; }

.pb-210 {
  padding-bottom: 210px !important; }

.pb-220 {
  padding-bottom: 220px !important; }

.pb-240 {
  padding-bottom: 240px !important; }

.pb-280 {
  padding-bottom: 280px !important; }

@media (min-width: 1px) {
  .pb-xs-0 {
    padding-bottom: 0 !important; }
  .pb-xs-5px 8px {
    padding-bottom: 5px 8px !important; }
  .pb-xs-10 {
    padding-bottom: 10px !important; }
  .pb-xs-15 {
    padding-bottom: 15px !important; }
  .pb-xs-18 {
    padding-bottom: 18px !important; }
  .pb-xs-20 {
    padding-bottom: 20px !important; }
  .pb-xs-25 {
    padding-bottom: 25px !important; }
  .pb-xs-30 {
    padding-bottom: 30px !important; }
  .pb-xs-31 {
    padding-bottom: 31px !important; }
  .pb-xs-32 {
    padding-bottom: 32px !important; }
  .pb-xs-33 {
    padding-bottom: 33px !important; }
  .pb-xs-34 {
    padding-bottom: 34px !important; }
  .pb-xs-35 {
    padding-bottom: 35px !important; }
  .pb-xs-36 {
    padding-bottom: 36px !important; }
  .pb-xs-37 {
    padding-bottom: 37px !important; }
  .pb-xs-38 {
    padding-bottom: 38px !important; }
  .pb-xs-39 {
    padding-bottom: 39px !important; }
  .pb-xs-40 {
    padding-bottom: 40px !important; }
  .pb-xs-45 {
    padding-bottom: 45px !important; }
  .pb-xs-50 {
    padding-bottom: 50px !important; }
  .pb-xs-55 {
    padding-bottom: 55px !important; }
  .pb-xs-60 {
    padding-bottom: 60px !important; }
  .pb-xs-65 {
    padding-bottom: 65px !important; }
  .pb-xs-70 {
    padding-bottom: 70px !important; }
  .pb-xs-75 {
    padding-bottom: 75px !important; }
  .pb-xs-80 {
    padding-bottom: 80px !important; }
  .pb-xs-85 {
    padding-bottom: 85px !important; }
  .pb-xs-90 {
    padding-bottom: 90px !important; }
  .pb-xs-95 {
    padding-bottom: 95px !important; }
  .pb-xs-100 {
    padding-bottom: 100px !important; }
  .pb-xs-105 {
    padding-bottom: 105px !important; }
  .pb-xs-110 {
    padding-bottom: 110px !important; }
  .pb-xs-115 {
    padding-bottom: 115px !important; }
  .pb-xs-120 {
    padding-bottom: 120px !important; }
  .pb-xs-125 {
    padding-bottom: 125px !important; }
  .pb-xs-130 {
    padding-bottom: 130px !important; }
  .pb-xs-135 {
    padding-bottom: 135px !important; }
  .pb-xs-140 {
    padding-bottom: 140px !important; }
  .pb-xs-145 {
    padding-bottom: 145px !important; }
  .pb-xs-150 {
    padding-bottom: 150px !important; }
  .pb-xs-155 {
    padding-bottom: 155px !important; }
  .pb-xs-160 {
    padding-bottom: 160px !important; }
  .pb-xs-170 {
    padding-bottom: 170px !important; }
  .pb-xs-175 {
    padding-bottom: 175px !important; }
  .pb-xs-180 {
    padding-bottom: 180px !important; }
  .pb-xs-190 {
    padding-bottom: 190px !important; }
  .pb-xs-200 {
    padding-bottom: 200px !important; }
  .pb-xs-210 {
    padding-bottom: 210px !important; }
  .pb-xs-220 {
    padding-bottom: 220px !important; }
  .pb-xs-240 {
    padding-bottom: 240px !important; }
  .pb-xs-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 576px) {
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-5px 8px {
    padding-bottom: 5px 8px !important; }
  .pb-sm-10 {
    padding-bottom: 10px !important; }
  .pb-sm-15 {
    padding-bottom: 15px !important; }
  .pb-sm-18 {
    padding-bottom: 18px !important; }
  .pb-sm-20 {
    padding-bottom: 20px !important; }
  .pb-sm-25 {
    padding-bottom: 25px !important; }
  .pb-sm-30 {
    padding-bottom: 30px !important; }
  .pb-sm-31 {
    padding-bottom: 31px !important; }
  .pb-sm-32 {
    padding-bottom: 32px !important; }
  .pb-sm-33 {
    padding-bottom: 33px !important; }
  .pb-sm-34 {
    padding-bottom: 34px !important; }
  .pb-sm-35 {
    padding-bottom: 35px !important; }
  .pb-sm-36 {
    padding-bottom: 36px !important; }
  .pb-sm-37 {
    padding-bottom: 37px !important; }
  .pb-sm-38 {
    padding-bottom: 38px !important; }
  .pb-sm-39 {
    padding-bottom: 39px !important; }
  .pb-sm-40 {
    padding-bottom: 40px !important; }
  .pb-sm-45 {
    padding-bottom: 45px !important; }
  .pb-sm-50 {
    padding-bottom: 50px !important; }
  .pb-sm-55 {
    padding-bottom: 55px !important; }
  .pb-sm-60 {
    padding-bottom: 60px !important; }
  .pb-sm-65 {
    padding-bottom: 65px !important; }
  .pb-sm-70 {
    padding-bottom: 70px !important; }
  .pb-sm-75 {
    padding-bottom: 75px !important; }
  .pb-sm-80 {
    padding-bottom: 80px !important; }
  .pb-sm-85 {
    padding-bottom: 85px !important; }
  .pb-sm-90 {
    padding-bottom: 90px !important; }
  .pb-sm-95 {
    padding-bottom: 95px !important; }
  .pb-sm-100 {
    padding-bottom: 100px !important; }
  .pb-sm-105 {
    padding-bottom: 105px !important; }
  .pb-sm-110 {
    padding-bottom: 110px !important; }
  .pb-sm-115 {
    padding-bottom: 115px !important; }
  .pb-sm-120 {
    padding-bottom: 120px !important; }
  .pb-sm-125 {
    padding-bottom: 125px !important; }
  .pb-sm-130 {
    padding-bottom: 130px !important; }
  .pb-sm-135 {
    padding-bottom: 135px !important; }
  .pb-sm-140 {
    padding-bottom: 140px !important; }
  .pb-sm-145 {
    padding-bottom: 145px !important; }
  .pb-sm-150 {
    padding-bottom: 150px !important; }
  .pb-sm-155 {
    padding-bottom: 155px !important; }
  .pb-sm-160 {
    padding-bottom: 160px !important; }
  .pb-sm-170 {
    padding-bottom: 170px !important; }
  .pb-sm-175 {
    padding-bottom: 175px !important; }
  .pb-sm-180 {
    padding-bottom: 180px !important; }
  .pb-sm-190 {
    padding-bottom: 190px !important; }
  .pb-sm-200 {
    padding-bottom: 200px !important; }
  .pb-sm-210 {
    padding-bottom: 210px !important; }
  .pb-sm-220 {
    padding-bottom: 220px !important; }
  .pb-sm-240 {
    padding-bottom: 240px !important; }
  .pb-sm-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 768px) {
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-5px 8px {
    padding-bottom: 5px 8px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pb-md-18 {
    padding-bottom: 18px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pb-md-31 {
    padding-bottom: 31px !important; }
  .pb-md-32 {
    padding-bottom: 32px !important; }
  .pb-md-33 {
    padding-bottom: 33px !important; }
  .pb-md-34 {
    padding-bottom: 34px !important; }
  .pb-md-35 {
    padding-bottom: 35px !important; }
  .pb-md-36 {
    padding-bottom: 36px !important; }
  .pb-md-37 {
    padding-bottom: 37px !important; }
  .pb-md-38 {
    padding-bottom: 38px !important; }
  .pb-md-39 {
    padding-bottom: 39px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pb-md-45 {
    padding-bottom: 45px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pb-md-55 {
    padding-bottom: 55px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pb-md-65 {
    padding-bottom: 65px !important; }
  .pb-md-70 {
    padding-bottom: 70px !important; }
  .pb-md-75 {
    padding-bottom: 75px !important; }
  .pb-md-80 {
    padding-bottom: 80px !important; }
  .pb-md-85 {
    padding-bottom: 85px !important; }
  .pb-md-90 {
    padding-bottom: 90px !important; }
  .pb-md-95 {
    padding-bottom: 95px !important; }
  .pb-md-100 {
    padding-bottom: 100px !important; }
  .pb-md-105 {
    padding-bottom: 105px !important; }
  .pb-md-110 {
    padding-bottom: 110px !important; }
  .pb-md-115 {
    padding-bottom: 115px !important; }
  .pb-md-120 {
    padding-bottom: 120px !important; }
  .pb-md-125 {
    padding-bottom: 125px !important; }
  .pb-md-130 {
    padding-bottom: 130px !important; }
  .pb-md-135 {
    padding-bottom: 135px !important; }
  .pb-md-140 {
    padding-bottom: 140px !important; }
  .pb-md-145 {
    padding-bottom: 145px !important; }
  .pb-md-150 {
    padding-bottom: 150px !important; }
  .pb-md-155 {
    padding-bottom: 155px !important; }
  .pb-md-160 {
    padding-bottom: 160px !important; }
  .pb-md-170 {
    padding-bottom: 170px !important; }
  .pb-md-175 {
    padding-bottom: 175px !important; }
  .pb-md-180 {
    padding-bottom: 180px !important; }
  .pb-md-190 {
    padding-bottom: 190px !important; }
  .pb-md-200 {
    padding-bottom: 200px !important; }
  .pb-md-210 {
    padding-bottom: 210px !important; }
  .pb-md-220 {
    padding-bottom: 220px !important; }
  .pb-md-240 {
    padding-bottom: 240px !important; }
  .pb-md-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 992px) {
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-5px 8px {
    padding-bottom: 5px 8px !important; }
  .pb-lg-10 {
    padding-bottom: 10px !important; }
  .pb-lg-15 {
    padding-bottom: 15px !important; }
  .pb-lg-18 {
    padding-bottom: 18px !important; }
  .pb-lg-20 {
    padding-bottom: 20px !important; }
  .pb-lg-25 {
    padding-bottom: 25px !important; }
  .pb-lg-30 {
    padding-bottom: 30px !important; }
  .pb-lg-31 {
    padding-bottom: 31px !important; }
  .pb-lg-32 {
    padding-bottom: 32px !important; }
  .pb-lg-33 {
    padding-bottom: 33px !important; }
  .pb-lg-34 {
    padding-bottom: 34px !important; }
  .pb-lg-35 {
    padding-bottom: 35px !important; }
  .pb-lg-36 {
    padding-bottom: 36px !important; }
  .pb-lg-37 {
    padding-bottom: 37px !important; }
  .pb-lg-38 {
    padding-bottom: 38px !important; }
  .pb-lg-39 {
    padding-bottom: 39px !important; }
  .pb-lg-40 {
    padding-bottom: 40px !important; }
  .pb-lg-45 {
    padding-bottom: 45px !important; }
  .pb-lg-50 {
    padding-bottom: 50px !important; }
  .pb-lg-55 {
    padding-bottom: 55px !important; }
  .pb-lg-60 {
    padding-bottom: 60px !important; }
  .pb-lg-65 {
    padding-bottom: 65px !important; }
  .pb-lg-70 {
    padding-bottom: 70px !important; }
  .pb-lg-75 {
    padding-bottom: 75px !important; }
  .pb-lg-80 {
    padding-bottom: 80px !important; }
  .pb-lg-85 {
    padding-bottom: 85px !important; }
  .pb-lg-90 {
    padding-bottom: 90px !important; }
  .pb-lg-95 {
    padding-bottom: 95px !important; }
  .pb-lg-100 {
    padding-bottom: 100px !important; }
  .pb-lg-105 {
    padding-bottom: 105px !important; }
  .pb-lg-110 {
    padding-bottom: 110px !important; }
  .pb-lg-115 {
    padding-bottom: 115px !important; }
  .pb-lg-120 {
    padding-bottom: 120px !important; }
  .pb-lg-125 {
    padding-bottom: 125px !important; }
  .pb-lg-130 {
    padding-bottom: 130px !important; }
  .pb-lg-135 {
    padding-bottom: 135px !important; }
  .pb-lg-140 {
    padding-bottom: 140px !important; }
  .pb-lg-145 {
    padding-bottom: 145px !important; }
  .pb-lg-150 {
    padding-bottom: 150px !important; }
  .pb-lg-155 {
    padding-bottom: 155px !important; }
  .pb-lg-160 {
    padding-bottom: 160px !important; }
  .pb-lg-170 {
    padding-bottom: 170px !important; }
  .pb-lg-175 {
    padding-bottom: 175px !important; }
  .pb-lg-180 {
    padding-bottom: 180px !important; }
  .pb-lg-190 {
    padding-bottom: 190px !important; }
  .pb-lg-200 {
    padding-bottom: 200px !important; }
  .pb-lg-210 {
    padding-bottom: 210px !important; }
  .pb-lg-220 {
    padding-bottom: 220px !important; }
  .pb-lg-240 {
    padding-bottom: 240px !important; }
  .pb-lg-280 {
    padding-bottom: 280px !important; } }

@media (min-width: 1230px) {
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-5px 8px {
    padding-bottom: 5px 8px !important; }
  .pb-xl-10 {
    padding-bottom: 10px !important; }
  .pb-xl-15 {
    padding-bottom: 15px !important; }
  .pb-xl-18 {
    padding-bottom: 18px !important; }
  .pb-xl-20 {
    padding-bottom: 20px !important; }
  .pb-xl-25 {
    padding-bottom: 25px !important; }
  .pb-xl-30 {
    padding-bottom: 30px !important; }
  .pb-xl-31 {
    padding-bottom: 31px !important; }
  .pb-xl-32 {
    padding-bottom: 32px !important; }
  .pb-xl-33 {
    padding-bottom: 33px !important; }
  .pb-xl-34 {
    padding-bottom: 34px !important; }
  .pb-xl-35 {
    padding-bottom: 35px !important; }
  .pb-xl-36 {
    padding-bottom: 36px !important; }
  .pb-xl-37 {
    padding-bottom: 37px !important; }
  .pb-xl-38 {
    padding-bottom: 38px !important; }
  .pb-xl-39 {
    padding-bottom: 39px !important; }
  .pb-xl-40 {
    padding-bottom: 40px !important; }
  .pb-xl-45 {
    padding-bottom: 45px !important; }
  .pb-xl-50 {
    padding-bottom: 50px !important; }
  .pb-xl-55 {
    padding-bottom: 55px !important; }
  .pb-xl-60 {
    padding-bottom: 60px !important; }
  .pb-xl-65 {
    padding-bottom: 65px !important; }
  .pb-xl-70 {
    padding-bottom: 70px !important; }
  .pb-xl-75 {
    padding-bottom: 75px !important; }
  .pb-xl-80 {
    padding-bottom: 80px !important; }
  .pb-xl-85 {
    padding-bottom: 85px !important; }
  .pb-xl-90 {
    padding-bottom: 90px !important; }
  .pb-xl-95 {
    padding-bottom: 95px !important; }
  .pb-xl-100 {
    padding-bottom: 100px !important; }
  .pb-xl-105 {
    padding-bottom: 105px !important; }
  .pb-xl-110 {
    padding-bottom: 110px !important; }
  .pb-xl-115 {
    padding-bottom: 115px !important; }
  .pb-xl-120 {
    padding-bottom: 120px !important; }
  .pb-xl-125 {
    padding-bottom: 125px !important; }
  .pb-xl-130 {
    padding-bottom: 130px !important; }
  .pb-xl-135 {
    padding-bottom: 135px !important; }
  .pb-xl-140 {
    padding-bottom: 140px !important; }
  .pb-xl-145 {
    padding-bottom: 145px !important; }
  .pb-xl-150 {
    padding-bottom: 150px !important; }
  .pb-xl-155 {
    padding-bottom: 155px !important; }
  .pb-xl-160 {
    padding-bottom: 160px !important; }
  .pb-xl-170 {
    padding-bottom: 170px !important; }
  .pb-xl-175 {
    padding-bottom: 175px !important; }
  .pb-xl-180 {
    padding-bottom: 180px !important; }
  .pb-xl-190 {
    padding-bottom: 190px !important; }
  .pb-xl-200 {
    padding-bottom: 200px !important; }
  .pb-xl-210 {
    padding-bottom: 210px !important; }
  .pb-xl-220 {
    padding-bottom: 220px !important; }
  .pb-xl-240 {
    padding-bottom: 240px !important; }
  .pb-xl-280 {
    padding-bottom: 280px !important; } }

.pr-0 {
  padding-right: 0 !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-8 {
  padding-right: 8px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-18 {
  padding-right: 18px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-31 {
  padding-right: 31px !important; }

.pr-32 {
  padding-right: 32px !important; }

.pr-33 {
  padding-right: 33px !important; }

.pr-34 {
  padding-right: 34px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-36 {
  padding-right: 36px !important; }

.pr-37 {
  padding-right: 37px !important; }

.pr-38 {
  padding-right: 38px !important; }

.pr-39 {
  padding-right: 39px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-90 {
  padding-right: 90px !important; }

@media (min-width: 1px) {
  .pr-xs-0 {
    padding-right: 0 !important; }
  .pr-xs-5 {
    padding-right: 5px !important; }
  .pr-xs-8 {
    padding-right: 8px !important; }
  .pr-xs-10 {
    padding-right: 10px !important; }
  .pr-xs-15 {
    padding-right: 15px !important; }
  .pr-xs-18 {
    padding-right: 18px !important; }
  .pr-xs-20 {
    padding-right: 20px !important; }
  .pr-xs-25 {
    padding-right: 25px !important; }
  .pr-xs-30 {
    padding-right: 30px !important; }
  .pr-xs-31 {
    padding-right: 31px !important; }
  .pr-xs-32 {
    padding-right: 32px !important; }
  .pr-xs-33 {
    padding-right: 33px !important; }
  .pr-xs-34 {
    padding-right: 34px !important; }
  .pr-xs-35 {
    padding-right: 35px !important; }
  .pr-xs-36 {
    padding-right: 36px !important; }
  .pr-xs-37 {
    padding-right: 37px !important; }
  .pr-xs-38 {
    padding-right: 38px !important; }
  .pr-xs-39 {
    padding-right: 39px !important; }
  .pr-xs-40 {
    padding-right: 40px !important; }
  .pr-xs-45 {
    padding-right: 45px !important; }
  .pr-xs-50 {
    padding-right: 50px !important; }
  .pr-xs-55 {
    padding-right: 55px !important; }
  .pr-xs-60 {
    padding-right: 60px !important; }
  .pr-xs-65 {
    padding-right: 65px !important; }
  .pr-xs-70 {
    padding-right: 70px !important; }
  .pr-xs-90 {
    padding-right: 90px !important; } }

@media (min-width: 576px) {
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-5 {
    padding-right: 5px !important; }
  .pr-sm-8 {
    padding-right: 8px !important; }
  .pr-sm-10 {
    padding-right: 10px !important; }
  .pr-sm-15 {
    padding-right: 15px !important; }
  .pr-sm-18 {
    padding-right: 18px !important; }
  .pr-sm-20 {
    padding-right: 20px !important; }
  .pr-sm-25 {
    padding-right: 25px !important; }
  .pr-sm-30 {
    padding-right: 30px !important; }
  .pr-sm-31 {
    padding-right: 31px !important; }
  .pr-sm-32 {
    padding-right: 32px !important; }
  .pr-sm-33 {
    padding-right: 33px !important; }
  .pr-sm-34 {
    padding-right: 34px !important; }
  .pr-sm-35 {
    padding-right: 35px !important; }
  .pr-sm-36 {
    padding-right: 36px !important; }
  .pr-sm-37 {
    padding-right: 37px !important; }
  .pr-sm-38 {
    padding-right: 38px !important; }
  .pr-sm-39 {
    padding-right: 39px !important; }
  .pr-sm-40 {
    padding-right: 40px !important; }
  .pr-sm-45 {
    padding-right: 45px !important; }
  .pr-sm-50 {
    padding-right: 50px !important; }
  .pr-sm-55 {
    padding-right: 55px !important; }
  .pr-sm-60 {
    padding-right: 60px !important; }
  .pr-sm-65 {
    padding-right: 65px !important; }
  .pr-sm-70 {
    padding-right: 70px !important; }
  .pr-sm-90 {
    padding-right: 90px !important; } }

@media (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-5 {
    padding-right: 5px !important; }
  .pr-md-8 {
    padding-right: 8px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pr-md-18 {
    padding-right: 18px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pr-md-31 {
    padding-right: 31px !important; }
  .pr-md-32 {
    padding-right: 32px !important; }
  .pr-md-33 {
    padding-right: 33px !important; }
  .pr-md-34 {
    padding-right: 34px !important; }
  .pr-md-35 {
    padding-right: 35px !important; }
  .pr-md-36 {
    padding-right: 36px !important; }
  .pr-md-37 {
    padding-right: 37px !important; }
  .pr-md-38 {
    padding-right: 38px !important; }
  .pr-md-39 {
    padding-right: 39px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pr-md-45 {
    padding-right: 45px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pr-md-55 {
    padding-right: 55px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pr-md-65 {
    padding-right: 65px !important; }
  .pr-md-70 {
    padding-right: 70px !important; }
  .pr-md-90 {
    padding-right: 90px !important; } }

@media (min-width: 992px) {
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-5 {
    padding-right: 5px !important; }
  .pr-lg-8 {
    padding-right: 8px !important; }
  .pr-lg-10 {
    padding-right: 10px !important; }
  .pr-lg-15 {
    padding-right: 15px !important; }
  .pr-lg-18 {
    padding-right: 18px !important; }
  .pr-lg-20 {
    padding-right: 20px !important; }
  .pr-lg-25 {
    padding-right: 25px !important; }
  .pr-lg-30 {
    padding-right: 30px !important; }
  .pr-lg-31 {
    padding-right: 31px !important; }
  .pr-lg-32 {
    padding-right: 32px !important; }
  .pr-lg-33 {
    padding-right: 33px !important; }
  .pr-lg-34 {
    padding-right: 34px !important; }
  .pr-lg-35 {
    padding-right: 35px !important; }
  .pr-lg-36 {
    padding-right: 36px !important; }
  .pr-lg-37 {
    padding-right: 37px !important; }
  .pr-lg-38 {
    padding-right: 38px !important; }
  .pr-lg-39 {
    padding-right: 39px !important; }
  .pr-lg-40 {
    padding-right: 40px !important; }
  .pr-lg-45 {
    padding-right: 45px !important; }
  .pr-lg-50 {
    padding-right: 50px !important; }
  .pr-lg-55 {
    padding-right: 55px !important; }
  .pr-lg-60 {
    padding-right: 60px !important; }
  .pr-lg-65 {
    padding-right: 65px !important; }
  .pr-lg-70 {
    padding-right: 70px !important; }
  .pr-lg-90 {
    padding-right: 90px !important; } }

@media (min-width: 1230px) {
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-5 {
    padding-right: 5px !important; }
  .pr-xl-8 {
    padding-right: 8px !important; }
  .pr-xl-10 {
    padding-right: 10px !important; }
  .pr-xl-15 {
    padding-right: 15px !important; }
  .pr-xl-18 {
    padding-right: 18px !important; }
  .pr-xl-20 {
    padding-right: 20px !important; }
  .pr-xl-25 {
    padding-right: 25px !important; }
  .pr-xl-30 {
    padding-right: 30px !important; }
  .pr-xl-31 {
    padding-right: 31px !important; }
  .pr-xl-32 {
    padding-right: 32px !important; }
  .pr-xl-33 {
    padding-right: 33px !important; }
  .pr-xl-34 {
    padding-right: 34px !important; }
  .pr-xl-35 {
    padding-right: 35px !important; }
  .pr-xl-36 {
    padding-right: 36px !important; }
  .pr-xl-37 {
    padding-right: 37px !important; }
  .pr-xl-38 {
    padding-right: 38px !important; }
  .pr-xl-39 {
    padding-right: 39px !important; }
  .pr-xl-40 {
    padding-right: 40px !important; }
  .pr-xl-45 {
    padding-right: 45px !important; }
  .pr-xl-50 {
    padding-right: 50px !important; }
  .pr-xl-55 {
    padding-right: 55px !important; }
  .pr-xl-60 {
    padding-right: 60px !important; }
  .pr-xl-65 {
    padding-right: 65px !important; }
  .pr-xl-70 {
    padding-right: 70px !important; }
  .pr-xl-90 {
    padding-right: 90px !important; } }

.pl-0 {
  padding-left: 0 !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-8 {
  padding-left: 8px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-18 {
  padding-left: 18px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-31 {
  padding-left: 31px !important; }

.pl-32 {
  padding-left: 32px !important; }

.pl-33 {
  padding-left: 33px !important; }

.pl-34 {
  padding-left: 34px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-36 {
  padding-left: 36px !important; }

.pl-37 {
  padding-left: 37px !important; }

.pl-38 {
  padding-left: 38px !important; }

.pl-39 {
  padding-left: 39px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-55 {
  padding-left: 55px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-90 {
  padding-left: 90px !important; }

@media (min-width: 1px) {
  .pl-xs-0 {
    padding-left: 0 !important; }
  .pl-xs-5 {
    padding-left: 5px !important; }
  .pl-xs-8 {
    padding-left: 8px !important; }
  .pl-xs-10 {
    padding-left: 10px !important; }
  .pl-xs-15 {
    padding-left: 15px !important; }
  .pl-xs-18 {
    padding-left: 18px !important; }
  .pl-xs-20 {
    padding-left: 20px !important; }
  .pl-xs-25 {
    padding-left: 25px !important; }
  .pl-xs-30 {
    padding-left: 30px !important; }
  .pl-xs-31 {
    padding-left: 31px !important; }
  .pl-xs-32 {
    padding-left: 32px !important; }
  .pl-xs-33 {
    padding-left: 33px !important; }
  .pl-xs-34 {
    padding-left: 34px !important; }
  .pl-xs-35 {
    padding-left: 35px !important; }
  .pl-xs-36 {
    padding-left: 36px !important; }
  .pl-xs-37 {
    padding-left: 37px !important; }
  .pl-xs-38 {
    padding-left: 38px !important; }
  .pl-xs-39 {
    padding-left: 39px !important; }
  .pl-xs-40 {
    padding-left: 40px !important; }
  .pl-xs-45 {
    padding-left: 45px !important; }
  .pl-xs-50 {
    padding-left: 50px !important; }
  .pl-xs-55 {
    padding-left: 55px !important; }
  .pl-xs-60 {
    padding-left: 60px !important; }
  .pl-xs-65 {
    padding-left: 65px !important; }
  .pl-xs-70 {
    padding-left: 70px !important; }
  .pl-xs-90 {
    padding-left: 90px !important; } }

@media (min-width: 576px) {
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-5 {
    padding-left: 5px !important; }
  .pl-sm-8 {
    padding-left: 8px !important; }
  .pl-sm-10 {
    padding-left: 10px !important; }
  .pl-sm-15 {
    padding-left: 15px !important; }
  .pl-sm-18 {
    padding-left: 18px !important; }
  .pl-sm-20 {
    padding-left: 20px !important; }
  .pl-sm-25 {
    padding-left: 25px !important; }
  .pl-sm-30 {
    padding-left: 30px !important; }
  .pl-sm-31 {
    padding-left: 31px !important; }
  .pl-sm-32 {
    padding-left: 32px !important; }
  .pl-sm-33 {
    padding-left: 33px !important; }
  .pl-sm-34 {
    padding-left: 34px !important; }
  .pl-sm-35 {
    padding-left: 35px !important; }
  .pl-sm-36 {
    padding-left: 36px !important; }
  .pl-sm-37 {
    padding-left: 37px !important; }
  .pl-sm-38 {
    padding-left: 38px !important; }
  .pl-sm-39 {
    padding-left: 39px !important; }
  .pl-sm-40 {
    padding-left: 40px !important; }
  .pl-sm-45 {
    padding-left: 45px !important; }
  .pl-sm-50 {
    padding-left: 50px !important; }
  .pl-sm-55 {
    padding-left: 55px !important; }
  .pl-sm-60 {
    padding-left: 60px !important; }
  .pl-sm-65 {
    padding-left: 65px !important; }
  .pl-sm-70 {
    padding-left: 70px !important; }
  .pl-sm-90 {
    padding-left: 90px !important; } }

@media (min-width: 768px) {
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-5 {
    padding-left: 5px !important; }
  .pl-md-8 {
    padding-left: 8px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .pl-md-18 {
    padding-left: 18px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .pl-md-31 {
    padding-left: 31px !important; }
  .pl-md-32 {
    padding-left: 32px !important; }
  .pl-md-33 {
    padding-left: 33px !important; }
  .pl-md-34 {
    padding-left: 34px !important; }
  .pl-md-35 {
    padding-left: 35px !important; }
  .pl-md-36 {
    padding-left: 36px !important; }
  .pl-md-37 {
    padding-left: 37px !important; }
  .pl-md-38 {
    padding-left: 38px !important; }
  .pl-md-39 {
    padding-left: 39px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .pl-md-45 {
    padding-left: 45px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .pl-md-55 {
    padding-left: 55px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .pl-md-65 {
    padding-left: 65px !important; }
  .pl-md-70 {
    padding-left: 70px !important; }
  .pl-md-90 {
    padding-left: 90px !important; } }

@media (min-width: 992px) {
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-5 {
    padding-left: 5px !important; }
  .pl-lg-8 {
    padding-left: 8px !important; }
  .pl-lg-10 {
    padding-left: 10px !important; }
  .pl-lg-15 {
    padding-left: 15px !important; }
  .pl-lg-18 {
    padding-left: 18px !important; }
  .pl-lg-20 {
    padding-left: 20px !important; }
  .pl-lg-25 {
    padding-left: 25px !important; }
  .pl-lg-30 {
    padding-left: 30px !important; }
  .pl-lg-31 {
    padding-left: 31px !important; }
  .pl-lg-32 {
    padding-left: 32px !important; }
  .pl-lg-33 {
    padding-left: 33px !important; }
  .pl-lg-34 {
    padding-left: 34px !important; }
  .pl-lg-35 {
    padding-left: 35px !important; }
  .pl-lg-36 {
    padding-left: 36px !important; }
  .pl-lg-37 {
    padding-left: 37px !important; }
  .pl-lg-38 {
    padding-left: 38px !important; }
  .pl-lg-39 {
    padding-left: 39px !important; }
  .pl-lg-40 {
    padding-left: 40px !important; }
  .pl-lg-45 {
    padding-left: 45px !important; }
  .pl-lg-50 {
    padding-left: 50px !important; }
  .pl-lg-55 {
    padding-left: 55px !important; }
  .pl-lg-60 {
    padding-left: 60px !important; }
  .pl-lg-65 {
    padding-left: 65px !important; }
  .pl-lg-70 {
    padding-left: 70px !important; }
  .pl-lg-90 {
    padding-left: 90px !important; } }

@media (min-width: 1230px) {
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-5 {
    padding-left: 5px !important; }
  .pl-xl-8 {
    padding-left: 8px !important; }
  .pl-xl-10 {
    padding-left: 10px !important; }
  .pl-xl-15 {
    padding-left: 15px !important; }
  .pl-xl-18 {
    padding-left: 18px !important; }
  .pl-xl-20 {
    padding-left: 20px !important; }
  .pl-xl-25 {
    padding-left: 25px !important; }
  .pl-xl-30 {
    padding-left: 30px !important; }
  .pl-xl-31 {
    padding-left: 31px !important; }
  .pl-xl-32 {
    padding-left: 32px !important; }
  .pl-xl-33 {
    padding-left: 33px !important; }
  .pl-xl-34 {
    padding-left: 34px !important; }
  .pl-xl-35 {
    padding-left: 35px !important; }
  .pl-xl-36 {
    padding-left: 36px !important; }
  .pl-xl-37 {
    padding-left: 37px !important; }
  .pl-xl-38 {
    padding-left: 38px !important; }
  .pl-xl-39 {
    padding-left: 39px !important; }
  .pl-xl-40 {
    padding-left: 40px !important; }
  .pl-xl-45 {
    padding-left: 45px !important; }
  .pl-xl-50 {
    padding-left: 50px !important; }
  .pl-xl-55 {
    padding-left: 55px !important; }
  .pl-xl-60 {
    padding-left: 60px !important; }
  .pl-xl-65 {
    padding-left: 65px !important; }
  .pl-xl-70 {
    padding-left: 70px !important; }
  .pl-xl-90 {
    padding-left: 90px !important; } }

.mt--50 {
  margin-top: -50px !important; }

.mt--30 {
  margin-top: -30px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-8 {
  margin-top: 8px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-18 {
  margin-top: 18px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-31 {
  margin-top: 31px !important; }

.mt-32 {
  margin-top: 32px !important; }

.mt-33 {
  margin-top: 33px !important; }

.mt-34 {
  margin-top: 34px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-36 {
  margin-top: 36px !important; }

.mt-37 {
  margin-top: 37px !important; }

.mt-38 {
  margin-top: 38px !important; }

.mt-39 {
  margin-top: 39px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-105 {
  margin-top: 105px !important; }

.mt-110 {
  margin-top: 110px !important; }

.mt-115 {
  margin-top: 115px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mt-125 {
  margin-top: 125px !important; }

.mt-130 {
  margin-top: 130px !important; }

.mt-135 {
  margin-top: 135px !important; }

.mt-140 {
  margin-top: 140px !important; }

.mt-145 {
  margin-top: 145px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-155 {
  margin-top: 155px !important; }

.mt-160 {
  margin-top: 160px !important; }

.mt-170 {
  margin-top: 170px !important; }

.mt-175 {
  margin-top: 175px !important; }

.mt-180 {
  margin-top: 180px !important; }

.mt-190 {
  margin-top: 190px !important; }

.mt-200 {
  margin-top: 200px !important; }

.mt-210 {
  margin-top: 210px !important; }

.mt-250 {
  margin-top: 250px !important; }

.mt-280 {
  margin-top: 280px !important; }

@media (min-width: 1px) {
  .mt-xs--50 {
    margin-top: -50px !important; }
  .mt-xs--30 {
    margin-top: -30px !important; }
  .mt-xs-0 {
    margin-top: 0 !important; }
  .mt-xs-8 {
    margin-top: 8px !important; }
  .mt-xs-10 {
    margin-top: 10px !important; }
  .mt-xs-15 {
    margin-top: 15px !important; }
  .mt-xs-18 {
    margin-top: 18px !important; }
  .mt-xs-20 {
    margin-top: 20px !important; }
  .mt-xs-25 {
    margin-top: 25px !important; }
  .mt-xs-30 {
    margin-top: 30px !important; }
  .mt-xs-31 {
    margin-top: 31px !important; }
  .mt-xs-32 {
    margin-top: 32px !important; }
  .mt-xs-33 {
    margin-top: 33px !important; }
  .mt-xs-34 {
    margin-top: 34px !important; }
  .mt-xs-35 {
    margin-top: 35px !important; }
  .mt-xs-36 {
    margin-top: 36px !important; }
  .mt-xs-37 {
    margin-top: 37px !important; }
  .mt-xs-38 {
    margin-top: 38px !important; }
  .mt-xs-39 {
    margin-top: 39px !important; }
  .mt-xs-40 {
    margin-top: 40px !important; }
  .mt-xs-45 {
    margin-top: 45px !important; }
  .mt-xs-50 {
    margin-top: 50px !important; }
  .mt-xs-55 {
    margin-top: 55px !important; }
  .mt-xs-60 {
    margin-top: 60px !important; }
  .mt-xs-65 {
    margin-top: 65px !important; }
  .mt-xs-70 {
    margin-top: 70px !important; }
  .mt-xs-75 {
    margin-top: 75px !important; }
  .mt-xs-80 {
    margin-top: 80px !important; }
  .mt-xs-85 {
    margin-top: 85px !important; }
  .mt-xs-90 {
    margin-top: 90px !important; }
  .mt-xs-95 {
    margin-top: 95px !important; }
  .mt-xs-100 {
    margin-top: 100px !important; }
  .mt-xs-105 {
    margin-top: 105px !important; }
  .mt-xs-110 {
    margin-top: 110px !important; }
  .mt-xs-115 {
    margin-top: 115px !important; }
  .mt-xs-120 {
    margin-top: 120px !important; }
  .mt-xs-125 {
    margin-top: 125px !important; }
  .mt-xs-130 {
    margin-top: 130px !important; }
  .mt-xs-135 {
    margin-top: 135px !important; }
  .mt-xs-140 {
    margin-top: 140px !important; }
  .mt-xs-145 {
    margin-top: 145px !important; }
  .mt-xs-150 {
    margin-top: 150px !important; }
  .mt-xs-155 {
    margin-top: 155px !important; }
  .mt-xs-160 {
    margin-top: 160px !important; }
  .mt-xs-170 {
    margin-top: 170px !important; }
  .mt-xs-175 {
    margin-top: 175px !important; }
  .mt-xs-180 {
    margin-top: 180px !important; }
  .mt-xs-190 {
    margin-top: 190px !important; }
  .mt-xs-200 {
    margin-top: 200px !important; }
  .mt-xs-210 {
    margin-top: 210px !important; }
  .mt-xs-250 {
    margin-top: 250px !important; }
  .mt-xs-280 {
    margin-top: 280px !important; } }

@media (min-width: 576px) {
  .mt-sm--50 {
    margin-top: -50px !important; }
  .mt-sm--30 {
    margin-top: -30px !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-8 {
    margin-top: 8px !important; }
  .mt-sm-10 {
    margin-top: 10px !important; }
  .mt-sm-15 {
    margin-top: 15px !important; }
  .mt-sm-18 {
    margin-top: 18px !important; }
  .mt-sm-20 {
    margin-top: 20px !important; }
  .mt-sm-25 {
    margin-top: 25px !important; }
  .mt-sm-30 {
    margin-top: 30px !important; }
  .mt-sm-31 {
    margin-top: 31px !important; }
  .mt-sm-32 {
    margin-top: 32px !important; }
  .mt-sm-33 {
    margin-top: 33px !important; }
  .mt-sm-34 {
    margin-top: 34px !important; }
  .mt-sm-35 {
    margin-top: 35px !important; }
  .mt-sm-36 {
    margin-top: 36px !important; }
  .mt-sm-37 {
    margin-top: 37px !important; }
  .mt-sm-38 {
    margin-top: 38px !important; }
  .mt-sm-39 {
    margin-top: 39px !important; }
  .mt-sm-40 {
    margin-top: 40px !important; }
  .mt-sm-45 {
    margin-top: 45px !important; }
  .mt-sm-50 {
    margin-top: 50px !important; }
  .mt-sm-55 {
    margin-top: 55px !important; }
  .mt-sm-60 {
    margin-top: 60px !important; }
  .mt-sm-65 {
    margin-top: 65px !important; }
  .mt-sm-70 {
    margin-top: 70px !important; }
  .mt-sm-75 {
    margin-top: 75px !important; }
  .mt-sm-80 {
    margin-top: 80px !important; }
  .mt-sm-85 {
    margin-top: 85px !important; }
  .mt-sm-90 {
    margin-top: 90px !important; }
  .mt-sm-95 {
    margin-top: 95px !important; }
  .mt-sm-100 {
    margin-top: 100px !important; }
  .mt-sm-105 {
    margin-top: 105px !important; }
  .mt-sm-110 {
    margin-top: 110px !important; }
  .mt-sm-115 {
    margin-top: 115px !important; }
  .mt-sm-120 {
    margin-top: 120px !important; }
  .mt-sm-125 {
    margin-top: 125px !important; }
  .mt-sm-130 {
    margin-top: 130px !important; }
  .mt-sm-135 {
    margin-top: 135px !important; }
  .mt-sm-140 {
    margin-top: 140px !important; }
  .mt-sm-145 {
    margin-top: 145px !important; }
  .mt-sm-150 {
    margin-top: 150px !important; }
  .mt-sm-155 {
    margin-top: 155px !important; }
  .mt-sm-160 {
    margin-top: 160px !important; }
  .mt-sm-170 {
    margin-top: 170px !important; }
  .mt-sm-175 {
    margin-top: 175px !important; }
  .mt-sm-180 {
    margin-top: 180px !important; }
  .mt-sm-190 {
    margin-top: 190px !important; }
  .mt-sm-200 {
    margin-top: 200px !important; }
  .mt-sm-210 {
    margin-top: 210px !important; }
  .mt-sm-250 {
    margin-top: 250px !important; }
  .mt-sm-280 {
    margin-top: 280px !important; } }

@media (min-width: 768px) {
  .mt-md--50 {
    margin-top: -50px !important; }
  .mt-md--30 {
    margin-top: -30px !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-8 {
    margin-top: 8px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mt-md-18 {
    margin-top: 18px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mt-md-31 {
    margin-top: 31px !important; }
  .mt-md-32 {
    margin-top: 32px !important; }
  .mt-md-33 {
    margin-top: 33px !important; }
  .mt-md-34 {
    margin-top: 34px !important; }
  .mt-md-35 {
    margin-top: 35px !important; }
  .mt-md-36 {
    margin-top: 36px !important; }
  .mt-md-37 {
    margin-top: 37px !important; }
  .mt-md-38 {
    margin-top: 38px !important; }
  .mt-md-39 {
    margin-top: 39px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mt-md-45 {
    margin-top: 45px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mt-md-55 {
    margin-top: 55px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mt-md-65 {
    margin-top: 65px !important; }
  .mt-md-70 {
    margin-top: 70px !important; }
  .mt-md-75 {
    margin-top: 75px !important; }
  .mt-md-80 {
    margin-top: 80px !important; }
  .mt-md-85 {
    margin-top: 85px !important; }
  .mt-md-90 {
    margin-top: 90px !important; }
  .mt-md-95 {
    margin-top: 95px !important; }
  .mt-md-100 {
    margin-top: 100px !important; }
  .mt-md-105 {
    margin-top: 105px !important; }
  .mt-md-110 {
    margin-top: 110px !important; }
  .mt-md-115 {
    margin-top: 115px !important; }
  .mt-md-120 {
    margin-top: 120px !important; }
  .mt-md-125 {
    margin-top: 125px !important; }
  .mt-md-130 {
    margin-top: 130px !important; }
  .mt-md-135 {
    margin-top: 135px !important; }
  .mt-md-140 {
    margin-top: 140px !important; }
  .mt-md-145 {
    margin-top: 145px !important; }
  .mt-md-150 {
    margin-top: 150px !important; }
  .mt-md-155 {
    margin-top: 155px !important; }
  .mt-md-160 {
    margin-top: 160px !important; }
  .mt-md-170 {
    margin-top: 170px !important; }
  .mt-md-175 {
    margin-top: 175px !important; }
  .mt-md-180 {
    margin-top: 180px !important; }
  .mt-md-190 {
    margin-top: 190px !important; }
  .mt-md-200 {
    margin-top: 200px !important; }
  .mt-md-210 {
    margin-top: 210px !important; }
  .mt-md-250 {
    margin-top: 250px !important; }
  .mt-md-280 {
    margin-top: 280px !important; } }

@media (min-width: 992px) {
  .mt-lg--50 {
    margin-top: -50px !important; }
  .mt-lg--30 {
    margin-top: -30px !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-8 {
    margin-top: 8px !important; }
  .mt-lg-10 {
    margin-top: 10px !important; }
  .mt-lg-15 {
    margin-top: 15px !important; }
  .mt-lg-18 {
    margin-top: 18px !important; }
  .mt-lg-20 {
    margin-top: 20px !important; }
  .mt-lg-25 {
    margin-top: 25px !important; }
  .mt-lg-30 {
    margin-top: 30px !important; }
  .mt-lg-31 {
    margin-top: 31px !important; }
  .mt-lg-32 {
    margin-top: 32px !important; }
  .mt-lg-33 {
    margin-top: 33px !important; }
  .mt-lg-34 {
    margin-top: 34px !important; }
  .mt-lg-35 {
    margin-top: 35px !important; }
  .mt-lg-36 {
    margin-top: 36px !important; }
  .mt-lg-37 {
    margin-top: 37px !important; }
  .mt-lg-38 {
    margin-top: 38px !important; }
  .mt-lg-39 {
    margin-top: 39px !important; }
  .mt-lg-40 {
    margin-top: 40px !important; }
  .mt-lg-45 {
    margin-top: 45px !important; }
  .mt-lg-50 {
    margin-top: 50px !important; }
  .mt-lg-55 {
    margin-top: 55px !important; }
  .mt-lg-60 {
    margin-top: 60px !important; }
  .mt-lg-65 {
    margin-top: 65px !important; }
  .mt-lg-70 {
    margin-top: 70px !important; }
  .mt-lg-75 {
    margin-top: 75px !important; }
  .mt-lg-80 {
    margin-top: 80px !important; }
  .mt-lg-85 {
    margin-top: 85px !important; }
  .mt-lg-90 {
    margin-top: 90px !important; }
  .mt-lg-95 {
    margin-top: 95px !important; }
  .mt-lg-100 {
    margin-top: 100px !important; }
  .mt-lg-105 {
    margin-top: 105px !important; }
  .mt-lg-110 {
    margin-top: 110px !important; }
  .mt-lg-115 {
    margin-top: 115px !important; }
  .mt-lg-120 {
    margin-top: 120px !important; }
  .mt-lg-125 {
    margin-top: 125px !important; }
  .mt-lg-130 {
    margin-top: 130px !important; }
  .mt-lg-135 {
    margin-top: 135px !important; }
  .mt-lg-140 {
    margin-top: 140px !important; }
  .mt-lg-145 {
    margin-top: 145px !important; }
  .mt-lg-150 {
    margin-top: 150px !important; }
  .mt-lg-155 {
    margin-top: 155px !important; }
  .mt-lg-160 {
    margin-top: 160px !important; }
  .mt-lg-170 {
    margin-top: 170px !important; }
  .mt-lg-175 {
    margin-top: 175px !important; }
  .mt-lg-180 {
    margin-top: 180px !important; }
  .mt-lg-190 {
    margin-top: 190px !important; }
  .mt-lg-200 {
    margin-top: 200px !important; }
  .mt-lg-210 {
    margin-top: 210px !important; }
  .mt-lg-250 {
    margin-top: 250px !important; }
  .mt-lg-280 {
    margin-top: 280px !important; } }

@media (min-width: 1230px) {
  .mt-xl--50 {
    margin-top: -50px !important; }
  .mt-xl--30 {
    margin-top: -30px !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-8 {
    margin-top: 8px !important; }
  .mt-xl-10 {
    margin-top: 10px !important; }
  .mt-xl-15 {
    margin-top: 15px !important; }
  .mt-xl-18 {
    margin-top: 18px !important; }
  .mt-xl-20 {
    margin-top: 20px !important; }
  .mt-xl-25 {
    margin-top: 25px !important; }
  .mt-xl-30 {
    margin-top: 30px !important; }
  .mt-xl-31 {
    margin-top: 31px !important; }
  .mt-xl-32 {
    margin-top: 32px !important; }
  .mt-xl-33 {
    margin-top: 33px !important; }
  .mt-xl-34 {
    margin-top: 34px !important; }
  .mt-xl-35 {
    margin-top: 35px !important; }
  .mt-xl-36 {
    margin-top: 36px !important; }
  .mt-xl-37 {
    margin-top: 37px !important; }
  .mt-xl-38 {
    margin-top: 38px !important; }
  .mt-xl-39 {
    margin-top: 39px !important; }
  .mt-xl-40 {
    margin-top: 40px !important; }
  .mt-xl-45 {
    margin-top: 45px !important; }
  .mt-xl-50 {
    margin-top: 50px !important; }
  .mt-xl-55 {
    margin-top: 55px !important; }
  .mt-xl-60 {
    margin-top: 60px !important; }
  .mt-xl-65 {
    margin-top: 65px !important; }
  .mt-xl-70 {
    margin-top: 70px !important; }
  .mt-xl-75 {
    margin-top: 75px !important; }
  .mt-xl-80 {
    margin-top: 80px !important; }
  .mt-xl-85 {
    margin-top: 85px !important; }
  .mt-xl-90 {
    margin-top: 90px !important; }
  .mt-xl-95 {
    margin-top: 95px !important; }
  .mt-xl-100 {
    margin-top: 100px !important; }
  .mt-xl-105 {
    margin-top: 105px !important; }
  .mt-xl-110 {
    margin-top: 110px !important; }
  .mt-xl-115 {
    margin-top: 115px !important; }
  .mt-xl-120 {
    margin-top: 120px !important; }
  .mt-xl-125 {
    margin-top: 125px !important; }
  .mt-xl-130 {
    margin-top: 130px !important; }
  .mt-xl-135 {
    margin-top: 135px !important; }
  .mt-xl-140 {
    margin-top: 140px !important; }
  .mt-xl-145 {
    margin-top: 145px !important; }
  .mt-xl-150 {
    margin-top: 150px !important; }
  .mt-xl-155 {
    margin-top: 155px !important; }
  .mt-xl-160 {
    margin-top: 160px !important; }
  .mt-xl-170 {
    margin-top: 170px !important; }
  .mt-xl-175 {
    margin-top: 175px !important; }
  .mt-xl-180 {
    margin-top: 180px !important; }
  .mt-xl-190 {
    margin-top: 190px !important; }
  .mt-xl-200 {
    margin-top: 200px !important; }
  .mt-xl-210 {
    margin-top: 210px !important; }
  .mt-xl-250 {
    margin-top: 250px !important; }
  .mt-xl-280 {
    margin-top: 280px !important; } }

.mb--50 {
  margin-bottom: -50px !important; }

.mb--30 {
  margin-bottom: -30px !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-18 {
  margin-bottom: 18px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-31 {
  margin-bottom: 31px !important; }

.mb-32 {
  margin-bottom: 32px !important; }

.mb-33 {
  margin-bottom: 33px !important; }

.mb-34 {
  margin-bottom: 34px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-36 {
  margin-bottom: 36px !important; }

.mb-37 {
  margin-bottom: 37px !important; }

.mb-38 {
  margin-bottom: 38px !important; }

.mb-39 {
  margin-bottom: 39px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-105 {
  margin-bottom: 105px !important; }

.mb-110 {
  margin-bottom: 110px !important; }

.mb-115 {
  margin-bottom: 115px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-125 {
  margin-bottom: 125px !important; }

.mb-130 {
  margin-bottom: 130px !important; }

.mb-135 {
  margin-bottom: 135px !important; }

.mb-140 {
  margin-bottom: 140px !important; }

.mb-145 {
  margin-bottom: 145px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-155 {
  margin-bottom: 155px !important; }

.mb-160 {
  margin-bottom: 160px !important; }

.mb-170 {
  margin-bottom: 170px !important; }

.mb-175 {
  margin-bottom: 175px !important; }

.mb-180 {
  margin-bottom: 180px !important; }

.mb-190 {
  margin-bottom: 190px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

.mb-210 {
  margin-bottom: 210px !important; }

.mb-250 {
  margin-bottom: 250px !important; }

.mb-280 {
  margin-bottom: 280px !important; }

@media (min-width: 1px) {
  .mb-xs--50 {
    margin-bottom: -50px !important; }
  .mb-xs--30 {
    margin-bottom: -30px !important; }
  .mb-xs-0 {
    margin-bottom: 0 !important; }
  .mb-xs-8 {
    margin-bottom: 8px !important; }
  .mb-xs-10 {
    margin-bottom: 10px !important; }
  .mb-xs-15 {
    margin-bottom: 15px !important; }
  .mb-xs-18 {
    margin-bottom: 18px !important; }
  .mb-xs-20 {
    margin-bottom: 20px !important; }
  .mb-xs-25 {
    margin-bottom: 25px !important; }
  .mb-xs-30 {
    margin-bottom: 30px !important; }
  .mb-xs-31 {
    margin-bottom: 31px !important; }
  .mb-xs-32 {
    margin-bottom: 32px !important; }
  .mb-xs-33 {
    margin-bottom: 33px !important; }
  .mb-xs-34 {
    margin-bottom: 34px !important; }
  .mb-xs-35 {
    margin-bottom: 35px !important; }
  .mb-xs-36 {
    margin-bottom: 36px !important; }
  .mb-xs-37 {
    margin-bottom: 37px !important; }
  .mb-xs-38 {
    margin-bottom: 38px !important; }
  .mb-xs-39 {
    margin-bottom: 39px !important; }
  .mb-xs-40 {
    margin-bottom: 40px !important; }
  .mb-xs-45 {
    margin-bottom: 45px !important; }
  .mb-xs-50 {
    margin-bottom: 50px !important; }
  .mb-xs-55 {
    margin-bottom: 55px !important; }
  .mb-xs-60 {
    margin-bottom: 60px !important; }
  .mb-xs-65 {
    margin-bottom: 65px !important; }
  .mb-xs-70 {
    margin-bottom: 70px !important; }
  .mb-xs-75 {
    margin-bottom: 75px !important; }
  .mb-xs-80 {
    margin-bottom: 80px !important; }
  .mb-xs-85 {
    margin-bottom: 85px !important; }
  .mb-xs-90 {
    margin-bottom: 90px !important; }
  .mb-xs-95 {
    margin-bottom: 95px !important; }
  .mb-xs-100 {
    margin-bottom: 100px !important; }
  .mb-xs-105 {
    margin-bottom: 105px !important; }
  .mb-xs-110 {
    margin-bottom: 110px !important; }
  .mb-xs-115 {
    margin-bottom: 115px !important; }
  .mb-xs-120 {
    margin-bottom: 120px !important; }
  .mb-xs-125 {
    margin-bottom: 125px !important; }
  .mb-xs-130 {
    margin-bottom: 130px !important; }
  .mb-xs-135 {
    margin-bottom: 135px !important; }
  .mb-xs-140 {
    margin-bottom: 140px !important; }
  .mb-xs-145 {
    margin-bottom: 145px !important; }
  .mb-xs-150 {
    margin-bottom: 150px !important; }
  .mb-xs-155 {
    margin-bottom: 155px !important; }
  .mb-xs-160 {
    margin-bottom: 160px !important; }
  .mb-xs-170 {
    margin-bottom: 170px !important; }
  .mb-xs-175 {
    margin-bottom: 175px !important; }
  .mb-xs-180 {
    margin-bottom: 180px !important; }
  .mb-xs-190 {
    margin-bottom: 190px !important; }
  .mb-xs-200 {
    margin-bottom: 200px !important; }
  .mb-xs-210 {
    margin-bottom: 210px !important; }
  .mb-xs-250 {
    margin-bottom: 250px !important; }
  .mb-xs-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 576px) {
  .mb-sm--50 {
    margin-bottom: -50px !important; }
  .mb-sm--30 {
    margin-bottom: -30px !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-8 {
    margin-bottom: 8px !important; }
  .mb-sm-10 {
    margin-bottom: 10px !important; }
  .mb-sm-15 {
    margin-bottom: 15px !important; }
  .mb-sm-18 {
    margin-bottom: 18px !important; }
  .mb-sm-20 {
    margin-bottom: 20px !important; }
  .mb-sm-25 {
    margin-bottom: 25px !important; }
  .mb-sm-30 {
    margin-bottom: 30px !important; }
  .mb-sm-31 {
    margin-bottom: 31px !important; }
  .mb-sm-32 {
    margin-bottom: 32px !important; }
  .mb-sm-33 {
    margin-bottom: 33px !important; }
  .mb-sm-34 {
    margin-bottom: 34px !important; }
  .mb-sm-35 {
    margin-bottom: 35px !important; }
  .mb-sm-36 {
    margin-bottom: 36px !important; }
  .mb-sm-37 {
    margin-bottom: 37px !important; }
  .mb-sm-38 {
    margin-bottom: 38px !important; }
  .mb-sm-39 {
    margin-bottom: 39px !important; }
  .mb-sm-40 {
    margin-bottom: 40px !important; }
  .mb-sm-45 {
    margin-bottom: 45px !important; }
  .mb-sm-50 {
    margin-bottom: 50px !important; }
  .mb-sm-55 {
    margin-bottom: 55px !important; }
  .mb-sm-60 {
    margin-bottom: 60px !important; }
  .mb-sm-65 {
    margin-bottom: 65px !important; }
  .mb-sm-70 {
    margin-bottom: 70px !important; }
  .mb-sm-75 {
    margin-bottom: 75px !important; }
  .mb-sm-80 {
    margin-bottom: 80px !important; }
  .mb-sm-85 {
    margin-bottom: 85px !important; }
  .mb-sm-90 {
    margin-bottom: 90px !important; }
  .mb-sm-95 {
    margin-bottom: 95px !important; }
  .mb-sm-100 {
    margin-bottom: 100px !important; }
  .mb-sm-105 {
    margin-bottom: 105px !important; }
  .mb-sm-110 {
    margin-bottom: 110px !important; }
  .mb-sm-115 {
    margin-bottom: 115px !important; }
  .mb-sm-120 {
    margin-bottom: 120px !important; }
  .mb-sm-125 {
    margin-bottom: 125px !important; }
  .mb-sm-130 {
    margin-bottom: 130px !important; }
  .mb-sm-135 {
    margin-bottom: 135px !important; }
  .mb-sm-140 {
    margin-bottom: 140px !important; }
  .mb-sm-145 {
    margin-bottom: 145px !important; }
  .mb-sm-150 {
    margin-bottom: 150px !important; }
  .mb-sm-155 {
    margin-bottom: 155px !important; }
  .mb-sm-160 {
    margin-bottom: 160px !important; }
  .mb-sm-170 {
    margin-bottom: 170px !important; }
  .mb-sm-175 {
    margin-bottom: 175px !important; }
  .mb-sm-180 {
    margin-bottom: 180px !important; }
  .mb-sm-190 {
    margin-bottom: 190px !important; }
  .mb-sm-200 {
    margin-bottom: 200px !important; }
  .mb-sm-210 {
    margin-bottom: 210px !important; }
  .mb-sm-250 {
    margin-bottom: 250px !important; }
  .mb-sm-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 768px) {
  .mb-md--50 {
    margin-bottom: -50px !important; }
  .mb-md--30 {
    margin-bottom: -30px !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-8 {
    margin-bottom: 8px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .mb-md-18 {
    margin-bottom: 18px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .mb-md-31 {
    margin-bottom: 31px !important; }
  .mb-md-32 {
    margin-bottom: 32px !important; }
  .mb-md-33 {
    margin-bottom: 33px !important; }
  .mb-md-34 {
    margin-bottom: 34px !important; }
  .mb-md-35 {
    margin-bottom: 35px !important; }
  .mb-md-36 {
    margin-bottom: 36px !important; }
  .mb-md-37 {
    margin-bottom: 37px !important; }
  .mb-md-38 {
    margin-bottom: 38px !important; }
  .mb-md-39 {
    margin-bottom: 39px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .mb-md-45 {
    margin-bottom: 45px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .mb-md-55 {
    margin-bottom: 55px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .mb-md-65 {
    margin-bottom: 65px !important; }
  .mb-md-70 {
    margin-bottom: 70px !important; }
  .mb-md-75 {
    margin-bottom: 75px !important; }
  .mb-md-80 {
    margin-bottom: 80px !important; }
  .mb-md-85 {
    margin-bottom: 85px !important; }
  .mb-md-90 {
    margin-bottom: 90px !important; }
  .mb-md-95 {
    margin-bottom: 95px !important; }
  .mb-md-100 {
    margin-bottom: 100px !important; }
  .mb-md-105 {
    margin-bottom: 105px !important; }
  .mb-md-110 {
    margin-bottom: 110px !important; }
  .mb-md-115 {
    margin-bottom: 115px !important; }
  .mb-md-120 {
    margin-bottom: 120px !important; }
  .mb-md-125 {
    margin-bottom: 125px !important; }
  .mb-md-130 {
    margin-bottom: 130px !important; }
  .mb-md-135 {
    margin-bottom: 135px !important; }
  .mb-md-140 {
    margin-bottom: 140px !important; }
  .mb-md-145 {
    margin-bottom: 145px !important; }
  .mb-md-150 {
    margin-bottom: 150px !important; }
  .mb-md-155 {
    margin-bottom: 155px !important; }
  .mb-md-160 {
    margin-bottom: 160px !important; }
  .mb-md-170 {
    margin-bottom: 170px !important; }
  .mb-md-175 {
    margin-bottom: 175px !important; }
  .mb-md-180 {
    margin-bottom: 180px !important; }
  .mb-md-190 {
    margin-bottom: 190px !important; }
  .mb-md-200 {
    margin-bottom: 200px !important; }
  .mb-md-210 {
    margin-bottom: 210px !important; }
  .mb-md-250 {
    margin-bottom: 250px !important; }
  .mb-md-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 992px) {
  .mb-lg--50 {
    margin-bottom: -50px !important; }
  .mb-lg--30 {
    margin-bottom: -30px !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-8 {
    margin-bottom: 8px !important; }
  .mb-lg-10 {
    margin-bottom: 10px !important; }
  .mb-lg-15 {
    margin-bottom: 15px !important; }
  .mb-lg-18 {
    margin-bottom: 18px !important; }
  .mb-lg-20 {
    margin-bottom: 20px !important; }
  .mb-lg-25 {
    margin-bottom: 25px !important; }
  .mb-lg-30 {
    margin-bottom: 30px !important; }
  .mb-lg-31 {
    margin-bottom: 31px !important; }
  .mb-lg-32 {
    margin-bottom: 32px !important; }
  .mb-lg-33 {
    margin-bottom: 33px !important; }
  .mb-lg-34 {
    margin-bottom: 34px !important; }
  .mb-lg-35 {
    margin-bottom: 35px !important; }
  .mb-lg-36 {
    margin-bottom: 36px !important; }
  .mb-lg-37 {
    margin-bottom: 37px !important; }
  .mb-lg-38 {
    margin-bottom: 38px !important; }
  .mb-lg-39 {
    margin-bottom: 39px !important; }
  .mb-lg-40 {
    margin-bottom: 40px !important; }
  .mb-lg-45 {
    margin-bottom: 45px !important; }
  .mb-lg-50 {
    margin-bottom: 50px !important; }
  .mb-lg-55 {
    margin-bottom: 55px !important; }
  .mb-lg-60 {
    margin-bottom: 60px !important; }
  .mb-lg-65 {
    margin-bottom: 65px !important; }
  .mb-lg-70 {
    margin-bottom: 70px !important; }
  .mb-lg-75 {
    margin-bottom: 75px !important; }
  .mb-lg-80 {
    margin-bottom: 80px !important; }
  .mb-lg-85 {
    margin-bottom: 85px !important; }
  .mb-lg-90 {
    margin-bottom: 90px !important; }
  .mb-lg-95 {
    margin-bottom: 95px !important; }
  .mb-lg-100 {
    margin-bottom: 100px !important; }
  .mb-lg-105 {
    margin-bottom: 105px !important; }
  .mb-lg-110 {
    margin-bottom: 110px !important; }
  .mb-lg-115 {
    margin-bottom: 115px !important; }
  .mb-lg-120 {
    margin-bottom: 120px !important; }
  .mb-lg-125 {
    margin-bottom: 125px !important; }
  .mb-lg-130 {
    margin-bottom: 130px !important; }
  .mb-lg-135 {
    margin-bottom: 135px !important; }
  .mb-lg-140 {
    margin-bottom: 140px !important; }
  .mb-lg-145 {
    margin-bottom: 145px !important; }
  .mb-lg-150 {
    margin-bottom: 150px !important; }
  .mb-lg-155 {
    margin-bottom: 155px !important; }
  .mb-lg-160 {
    margin-bottom: 160px !important; }
  .mb-lg-170 {
    margin-bottom: 170px !important; }
  .mb-lg-175 {
    margin-bottom: 175px !important; }
  .mb-lg-180 {
    margin-bottom: 180px !important; }
  .mb-lg-190 {
    margin-bottom: 190px !important; }
  .mb-lg-200 {
    margin-bottom: 200px !important; }
  .mb-lg-210 {
    margin-bottom: 210px !important; }
  .mb-lg-250 {
    margin-bottom: 250px !important; }
  .mb-lg-280 {
    margin-bottom: 280px !important; } }

@media (min-width: 1230px) {
  .mb-xl--50 {
    margin-bottom: -50px !important; }
  .mb-xl--30 {
    margin-bottom: -30px !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-8 {
    margin-bottom: 8px !important; }
  .mb-xl-10 {
    margin-bottom: 10px !important; }
  .mb-xl-15 {
    margin-bottom: 15px !important; }
  .mb-xl-18 {
    margin-bottom: 18px !important; }
  .mb-xl-20 {
    margin-bottom: 20px !important; }
  .mb-xl-25 {
    margin-bottom: 25px !important; }
  .mb-xl-30 {
    margin-bottom: 30px !important; }
  .mb-xl-31 {
    margin-bottom: 31px !important; }
  .mb-xl-32 {
    margin-bottom: 32px !important; }
  .mb-xl-33 {
    margin-bottom: 33px !important; }
  .mb-xl-34 {
    margin-bottom: 34px !important; }
  .mb-xl-35 {
    margin-bottom: 35px !important; }
  .mb-xl-36 {
    margin-bottom: 36px !important; }
  .mb-xl-37 {
    margin-bottom: 37px !important; }
  .mb-xl-38 {
    margin-bottom: 38px !important; }
  .mb-xl-39 {
    margin-bottom: 39px !important; }
  .mb-xl-40 {
    margin-bottom: 40px !important; }
  .mb-xl-45 {
    margin-bottom: 45px !important; }
  .mb-xl-50 {
    margin-bottom: 50px !important; }
  .mb-xl-55 {
    margin-bottom: 55px !important; }
  .mb-xl-60 {
    margin-bottom: 60px !important; }
  .mb-xl-65 {
    margin-bottom: 65px !important; }
  .mb-xl-70 {
    margin-bottom: 70px !important; }
  .mb-xl-75 {
    margin-bottom: 75px !important; }
  .mb-xl-80 {
    margin-bottom: 80px !important; }
  .mb-xl-85 {
    margin-bottom: 85px !important; }
  .mb-xl-90 {
    margin-bottom: 90px !important; }
  .mb-xl-95 {
    margin-bottom: 95px !important; }
  .mb-xl-100 {
    margin-bottom: 100px !important; }
  .mb-xl-105 {
    margin-bottom: 105px !important; }
  .mb-xl-110 {
    margin-bottom: 110px !important; }
  .mb-xl-115 {
    margin-bottom: 115px !important; }
  .mb-xl-120 {
    margin-bottom: 120px !important; }
  .mb-xl-125 {
    margin-bottom: 125px !important; }
  .mb-xl-130 {
    margin-bottom: 130px !important; }
  .mb-xl-135 {
    margin-bottom: 135px !important; }
  .mb-xl-140 {
    margin-bottom: 140px !important; }
  .mb-xl-145 {
    margin-bottom: 145px !important; }
  .mb-xl-150 {
    margin-bottom: 150px !important; }
  .mb-xl-155 {
    margin-bottom: 155px !important; }
  .mb-xl-160 {
    margin-bottom: 160px !important; }
  .mb-xl-170 {
    margin-bottom: 170px !important; }
  .mb-xl-175 {
    margin-bottom: 175px !important; }
  .mb-xl-180 {
    margin-bottom: 180px !important; }
  .mb-xl-190 {
    margin-bottom: 190px !important; }
  .mb-xl-200 {
    margin-bottom: 200px !important; }
  .mb-xl-210 {
    margin-bottom: 210px !important; }
  .mb-xl-250 {
    margin-bottom: 250px !important; }
  .mb-xl-280 {
    margin-bottom: 280px !important; } }

/*===> Begin base style <===*/
body {
  font-family: "Roboto", sans-serif;
  line-height: 1.5em;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased; }
  body.active {
    overflow: hidden; }
    body.active::after {
      content: '';
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .3; }
  body ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #777777;
    opacity: 1;
    /* Firefox */ }
  body :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #777777; }
  body ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #777777; }
  body * {
    outline: none !important; }
  body p {
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.5em;
    color: #777777; }
    @media (max-width: 991px) {
      body p {
        font-size: 14px; } }
  body h1, body .h1, body h2, body h3, body h4, body h5, body h6 {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: "Poppins", sans-serif;
    line-height: 1.1em;
    color: #3B4757; }
  body h1, body .h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 54px; }
    @media (max-width: 767px) {
      body h1, body .h1 {
        font-size: 40px; } }
    @media (max-width: 480px) {
      body h1, body .h1 {
        font-size: 34px; } }
  body h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 1.3em; }
    @media (max-width: 991px) {
      body h2 {
        font-size: 34px; } }
    @media (max-width: 480px) {
      body h2 {
        font-size: 26px; } }
  body h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.3em; }
    @media (max-width: 767px) {
      body h3 {
        font-size: 28px; } }
    @media (max-width: 480px) {
      body h3 {
        font-size: 24px; } }
  body h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 1.3em; }
    @media (max-width: 767px) {
      body h4 {
        font-size: 18px; } }
  body h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3em; }
    @media (max-width: 767px) {
      body h5 {
        font-size: 16px; } }
  body h6 {
    font-family: "Georgia", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 22px;
    line-height: normal;
    line-height: 1.3em; }
  body img {
    max-width: 100%;
    display: inline-block; }
  body a {
    display: inline-block;
    text-decoration: none;
    outline: none !important;
    transition: all .3s; }
    body a::-moz-focus-inner {
      border: 0 !important; }
    body a:hover {
      text-decoration: none;
      color: #46AADC; }
  body blockquote {
    position: relative;
    margin-bottom: 25px;
    padding: 38px 30px 38px 100px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 12px; }
    body blockquote::after {
      content: '“';
      position: absolute;
      top: 25px;
      left: 80px;
      transform: translateX(-100%);
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 144px;
      line-height: 144px;
      color: #46AADC; }
    body blockquote.bloquote_bg {
      background: #F0F8FA; }
    body blockquote *:last-child {
      margin-bottom: 0; }
    body blockquote p {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 1.5em;
      color: #3B4757; }
    @media (max-width: 991px) {
      body blockquote p {
        font-size: 14px; } }
  body ul {
    margin-bottom: 0;
    padding: 0; }
    body ul.flex-style {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -15px; }
      body ul.flex-style li {
        padding-left: 40px;
        width: 50%; }
        body ul.flex-style li::after {
          left: 20px; }
    body ul li {
      position: relative;
      margin-bottom: 15px;
      padding-left: 20px;
      list-style: none; }
      body ul li:last-child {
        margin-bottom: 0; }
      body ul li::after {
        content: '\f105';
        position: absolute;
        top: 0.5em;
        left: 0;
        line-height: 1em;
        font-family: "Font Awesome 5 Free";
        font-size: 12px;
        font-weight: 900;
        color: #46AADC; }
      body ul li p {
        margin-bottom: 0; }
      body ul li a {
        margin-bottom: 0;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 1.6em;
        color: #777777; }
    @media (max-width: 991px) {
      body ul a {
        font-size: 14px; } }
  body ol {
    margin-bottom: 0;
    padding: 0;
    counter-reset: myCounter; }
    body ol li {
      list-style-position: inside;
      list-style: none; }
      body ol li::before {
        content: counter(myCounter, decimal-leading-zero) ".";
        counter-increment: myCounter;
        display: inline-block;
        margin: 5px 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        color: #46AADC; }
  body label {
    position: relative; }
  body .font-family_roboto {
    font-family: "Roboto", sans-serif; }
  body .font-family_poppins {
    font-family: "Poppins", sans-serif; }
  body .font-family_radley {
    font-family: "Georgia", sans-serif; }
  body .main-color-font {
    color: #46AADC; }
  body .component_color_1 {
    color: #FFAF60; }
  body .component_color_2 {
    color: #76BEBA; }
  body .component_color_3 {
    color: #7192BD; }
  body svg.main-color-font path {
    color: #46AADC; }
  body svg.component_color_1 path {
    color: #FFAF60; }
  body svg.component_color_2 path {
    color: #76BEBA; }
  body svg.component_color_3 path {
    color: #7192BD; }
  body .svg-block {
    max-width: 500px; }
  body .main-color-font {
    color: #46AADC; }
  body .dib {
    display: inline-block; }
  body .gmnoprint {
    display: none; }
  body .gm-control-active {
    display: none; }
  @media (max-width: 767px) {
    body blockquote {
      padding: 30px 20px 30px 50px; }
      body blockquote::after {
        top: 22px;
        left: 40px;
        font-size: 80px;
        line-height: 80px; } }

body {
  max-width: 100vw;
  overflow-x: hidden; }

@media (min-width: 576px) {
  .container {
    max-width: 738px; } }

@media (min-width: 768px) {
  .container {
    max-width: 776px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

/*===> End base style <===*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#6c757d;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2; }

.h1, h1 {
  font-size: 2.5rem; }

.h2, h2 {
  font-size: 2rem; }

.h3, h3 {
  font-size: 1.75rem; }

.h4, h4 {
  font-size: 1.5rem; }

.h5, h5 {
  font-size: 1.25rem; }

.h6, h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.small, small {
  font-size: 80%;
  font-weight: 400; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table-sm td, .table-sm th {
  padding: .3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6; }

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px; }

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff; }

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db; }

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb; }

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb; }

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba; }

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb; }

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe; }

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca; }

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #454d55; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

select.form-control[multiple], select.form-control[size] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: .25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: .25rem; }

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.custom-select.is-valid, .was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem); }

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip {
  display: block; }

.form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745; }

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem; }

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(.375em + .1875rem);
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc((1em + .75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem); }

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block; }

.form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545; }

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .custom-select, .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529;
  text-decoration: none; }

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled {
  opacity: .65; }

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline; }

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
  box-shadow: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none; }

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: .5rem; }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.fade {
  transition: opacity .15s linear; }

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropdown, .dropleft, .dropright, .dropup {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529; }

.btn-group, .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto; }

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1; }

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0; }

.dropleft .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px; }

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3; }

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4; }

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex; }

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2; }

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3; }

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0; }

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + .5rem + 2px); }

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px; }

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem; }

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none; } }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem); }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  display: none; }

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + .4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-range:focus {
  outline: 0; }

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer {
  border: 0; }

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none; } }

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none; } }

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none; }

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none; } }

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem; }

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default; }

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-moz-range-track {
  cursor: default; }

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd; }

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: none; } }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem; }

.nav-link:focus, .nav-link:hover {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: .25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem; }

.navbar > .container, .navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem; }

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: .75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.accordion > .card {
  overflow: hidden; }

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion > .card .card-header {
  margin-bottom: -1px; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none; } }

a.badge:focus, a.badge:hover {
  text-decoration: none; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc; }

a.badge-primary.focus, a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62; }

a.badge-secondary.focus, a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }

a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34; }

a.badge-success.focus, a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b; }

a.badge-info.focus, a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00; }

a.badge-warning.focus, a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130; }

a.badge-danger.focus, a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5; }

a.badge-light.focus, a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124; }

a.badge-dark.focus, a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none; } }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row; }

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0; }

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0; }

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row; }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row; }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row; }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row; }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.close:hover {
  color: #000;
  text-decoration: none; }

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: .25rem; }

.toast:not(:last-child) {
  margin-bottom: .75rem; }

.toast.showing {
  opacity: 1; }

.toast.show {
  display: block;
  opacity: 1; }

.toast.hide {
  display: none; }

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: .75rem; }

.modal-open {
  overflow: hidden; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none; }

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden; }

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.modal-dialog-scrollable .modal-body {
  overflow-y: auto; }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: ""; }

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%; }

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none; }

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.modal-footer > :not(:first-child) {
  margin-left: .25rem; }

.modal-footer > :not(:last-child) {
  margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0; }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem; }

.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: .5rem; }

.bs-popover-auto[x-placement^=top] > .arrow, .bs-popover-top > .arrow {
  bottom: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=top] > .arrow::before, .bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=top] > .arrow::after, .bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff; }

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: .5rem; }

.bs-popover-auto[x-placement^=right] > .arrow, .bs-popover-right > .arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=right] > .arrow::before, .bs-popover-right > .arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=right] > .arrow::after, .bs-popover-right > .arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff; }

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem; }

.bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-bottom > .arrow {
  top: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=bottom] > .arrow::before, .bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=bottom] > .arrow::after, .bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: .5rem; }

.bs-popover-auto[x-placement^=left] > .arrow, .bs-popover-left > .arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=left] > .arrow::before, .bs-popover-left > .arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=left] > .arrow::after, .bs-popover-left > .arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: .5rem .75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out; }

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block; }

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none; }

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s .6s opacity; }

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none; } }

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none; } }

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease; }

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3, .my-n3 {
  margin-top: -1rem !important; }

.mr-n3, .mx-n3 {
  margin-right: -1rem !important; }

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5, .my-n5 {
  margin-top: -3rem !important; }

.mr-n5, .mx-n5 {
  margin-right: -3rem !important; }

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto, .my-auto {
  margin-top: auto !important; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.mb-auto, .my-auto {
  margin-bottom: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  blockquote, pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td, .table th {
    background-color: #fff !important; }
  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*# sourceMappingURL=bootstrap.min.css.map */
/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.47
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none; }

.bootstrap-datetimepicker-widget.dropdown-menu {
  display: block;
  margin: 2px 0;
  padding: 4px;
  width: 19em; }

@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em; } }

.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute; }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px; }

.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px; }

.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px; }

.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px; }

.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0; }

.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0; }

.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none; }

.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0; }

.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours"; }

.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes"; }

.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM"; }

.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker"; }

.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today"; }

.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center; }

.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens"; }

.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit; }

.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%; }

.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0; }

.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 4px; }

.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px; }

.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px; }

.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month"; }

.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month"; }

.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer; }

.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #eeeeee; }

.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px; }

.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #777777; }

.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px; }

.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: #eeeeee;
  cursor: pointer; }

.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #777777; }

.bootstrap-datetimepicker-widget table td.today {
  position: relative; }

.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #337ab7;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff; }

.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 4px; }

.bootstrap-datetimepicker-widget table td span:hover {
  background: #eeeeee; }

.bootstrap-datetimepicker-widget table td span.active {
  background-color: #337ab7;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.bootstrap-datetimepicker-widget table td span.old {
  color: #777777; }

.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #777777;
  cursor: not-allowed; }

.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px; }

.bootstrap-datetimepicker-widget.wider {
  width: 21em; }

.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important; }

.input-group.date .input-group-addon {
  cursor: pointer; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.ekko-lightbox {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-right: 0 !important; }

.ekko-lightbox-container {
  position: relative; }

.ekko-lightbox-container > div.ekko-lightbox-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%; }

.ekko-lightbox iframe {
  width: 100%;
  height: 100%; }

.ekko-lightbox-nav-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex; }

.ekko-lightbox-nav-overlay a {
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  transition: opacity .5s;
  color: #fff;
  font-size: 30px;
  z-index: 1; }

.ekko-lightbox-nav-overlay a > * {
  -ms-flex-positive: 1;
  flex-grow: 1; }

.ekko-lightbox-nav-overlay a > :focus {
  outline: none; }

.ekko-lightbox-nav-overlay a span {
  padding: 0 30px; }

.ekko-lightbox-nav-overlay a:last-child span {
  text-align: right; }

.ekko-lightbox-nav-overlay a:hover {
  text-decoration: none; }

.ekko-lightbox-nav-overlay a:focus {
  outline: none; }

.ekko-lightbox-nav-overlay a.disabled {
  cursor: default;
  visibility: hidden; }

.ekko-lightbox a:hover {
  opacity: 1;
  text-decoration: none; }

.ekko-lightbox .modal-dialog {
  display: none; }

.ekko-lightbox .modal-footer {
  text-align: left; }

.ekko-lightbox-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center; }

.ekko-lightbox-loader > div {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center; }

.ekko-lightbox-loader > div > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: a 2s infinite ease-in-out; }

.ekko-lightbox-loader > div > div:last-child {
  animation-delay: -1s; }

.modal-dialog .ekko-lightbox-loader > div > div {
  background-color: #333; }

@keyframes a {
  0%, to {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

/*===> Begin preloader <===*/
:root {
  --duration: 1.5s;
  --container-size: 250px;
  --box-size: 33px;
  --box-border-radius: 15%; }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center; }
  .preloader .preloader-container {
    width: var(--container-size);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; }
  .preloader .preloader-item {
    width: var(--box-size);
    height: var(--box-size);
    position: relative;
    display: block;
    transform-origin: -50% center;
    border-radius: var(--box-border-radius); }
    .preloader .preloader-item:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: lightblue;
      border-radius: var(--box-border-radius);
      box-shadow: 0px 0px 10px 0px rgba(28, 159, 255, 0.4); }
    .preloader .preloader-item:nth-child(1) {
      animation: slide var(--duration) ease-in-out infinite alternate; }
      .preloader .preloader-item:nth-child(1):after {
        animation: color-change var(--duration) ease-in-out infinite alternate; }
    .preloader .preloader-item:nth-child(2) {
      animation: flip-1 var(--duration) ease-in-out infinite alternate; }
      .preloader .preloader-item:nth-child(2):after {
        animation: squidge-1 var(--duration) ease-in-out infinite alternate; }
    .preloader .preloader-item:nth-child(3) {
      animation: flip-2 var(--duration) ease-in-out infinite alternate; }
      .preloader .preloader-item:nth-child(3):after {
        animation: squidge-2 var(--duration) ease-in-out infinite alternate; }
    .preloader .preloader-item:nth-child(4) {
      animation: flip-3 var(--duration) ease-in-out infinite alternate; }
      .preloader .preloader-item:nth-child(4):after {
        animation: squidge-3 var(--duration) ease-in-out infinite alternate; }
    .preloader .preloader-item:nth-child(5) {
      animation: flip-4 var(--duration) ease-in-out infinite alternate; }
      .preloader .preloader-item:nth-child(5):after {
        animation: squidge-4 var(--duration) ease-in-out infinite alternate; }
    .preloader .preloader-item:nth-child(2):after {
      background-color: #1C9FFF; }
    .preloader .preloader-item:nth-child(3):after {
      background-color: #1FB1FD; }
    .preloader .preloader-item:nth-child(4):after {
      background-color: #22C7FB; }
    .preloader .preloader-item:nth-child(5):after {
      background-color: #23D3FB; }

@keyframes slide {
  0% {
    background-color: #1795FF;
    transform: translatex(0vw); }
  100% {
    background-color: #23D3FB;
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25))); } }

@keyframes color-change {
  0% {
    background-color: #1795FF; }
  100% {
    background-color: #23D3FB; } }

@keyframes flip-1 {
  0%, 15% {
    transform: rotate(0); }
  35%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  25%, 20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  55%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-2 {
  0%, 30% {
    transform: rotate(0); }
  50%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-2 {
  20% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  30% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  40%, 35% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  70%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  55% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-3 {
  0%, 45% {
    transform: rotate(0); }
  65%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-3 {
  35% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  45% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  55%, 50% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  85%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  70% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-4 {
  0%, 60% {
    transform: rotate(0); }
  80%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-4 {
  50% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  60% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  70%, 65% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  100%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  85% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

/*===> Begin preloader <===*/
/*===> Begin section header <===*/
.section-header {
  position: relative;
  z-index: 10;
  max-width: 730px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  text-align: center; }
  .section-header.on-scroll.show-scroll .h-sub, .section-header.on-scroll.show-scroll .h-desc, .section-header.on-scroll.show-scroll h2, .section-header.on-scroll.show-scroll p {
    opacity: 1;
    transform: translateY(0); }
  .section-header.on-scroll .h-sub, .section-header.on-scroll .h-desc, .section-header.on-scroll h2, .section-header.on-scroll p {
    opacity: 0;
    transform: translateY(50px);
    transition: all .3s; }
  .section-header.on-scroll .h-sub {
    transition-delay: 0s; }
  .section-header.on-scroll h2 {
    transition-delay: .25s; }
  .section-header.on-scroll p {
    transition-delay: .5s; }
  .section-header .h-sub {
    margin-bottom: 8px;
    font-family: "Georgia", sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 21px;
    line-height: 1.28em;
    color: #46AADD; }
  .section-header .h-desc {
    margin-bottom: 37px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: #777777; }
  .section-header h2 {
    margin-bottom: 31px;
    font-style: normal;
    font-weight: 500; }
  @media (max-width: 991px) {
    .section-header {
      margin-bottom: 35px; }
      .section-header .h-sub {
        font-size: 18px; }
      .section-header .h-desc {
        margin-bottom: 30px; }
      .section-header h2 {
        margin-bottom: 25px; } }
  @media (max-width: 480px) {
    .section-header .h-sub {
      font-size: 16px; } }

/*===> End section header <===*/
/*===> Begin square icon <===*/
.square-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 152px;
  min-height: 137px;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 10px 22px rgba(42, 158, 121, 0.14);
  border-radius: 13px;
  transition: all .3s; }
  .square-icon:hover {
    box-shadow: none; }
  .square-icon img {
    margin-bottom: 15px; }
  .square-icon p {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #3B4757; }
  @media (max-width: 1199px) {
    .square-icon {
      width: 107px;
      min-height: 96px; }
      .square-icon img {
        height: 40px;
        margin-bottom: 5px; }
      .square-icon p {
        font-size: 14px; } }
  @media (max-width: 991px) {
    .square-icon {
      width: 98px;
      min-height: 86px; } }
  @media (max-width: 480px) {
    .square-icon {
      width: 86px;
      min-height: 80px;
      padding-top: 5px;
      padding-bottom: 5px; }
      .square-icon img {
        height: 30px; } }
  @media (max-width: 350px) {
    .square-icon {
      width: 80px;
      min-height: 80px; }
      .square-icon img {
        height: 27px; } }

/*===> End square icon <===*/
/*===> Begin round icon <===*/
.round-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: #EAF5F7;
  border: 8px solid #FFFFFF;
  border-radius: 50%;
  transition: all .3s; }
  .round-icon:hover {
    box-shadow: 0px 10px 22px rgba(42, 158, 121, 0.14); }
  .round-icon img {
    margin-bottom: 10px; }
  .round-icon p {
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #3B4757; }

/*===> End round icon <===*/
/*===> Begin icons list <===*/
.icons-list {
  display: flex;
  flex-wrap: wrap; }
  .icons-list.bm-0 {
    margin-bottom: -25px; }
  .icons-list .icon {
    margin-bottom: 25px; }
  .icons-list .square-icon {
    margin-right: 15px; }
    .icons-list .square-icon:last-child {
      margin-right: 0; }
  .icons-list .round-icon {
    margin-right: 6px; }
    .icons-list .round-icon:last-child {
      margin-right: 0; }

/*===> End icons list <===*/
/*===> Begin first letter <===*/
.f-letter {
  display: flex;
  align-items: flex-start; }
  .f-letter.float {
    display: block; }
    .f-letter.float .f-letter-wrap {
      float: left; }
  .f-letter.fill .f-letter-wrap {
    padding: 5px;
    background: #46AADD;
    font-size: 42px;
    color: #fff; }
  .f-letter .f-letter-wrap {
    margin-right: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 66px;
    line-height: 1em;
    color: #46AADD; }

/*===> End first letter <===*/
/*===> Begin table <===*/
table {
  display: block;
  margin-bottom: 50px; }
  table.adaptive {
    min-width: 650px; }
  table.pricing-table thead td, table.pricing-table tbody td, table.pricing-table tfoot td {
    width: auto; }
  table.pricing-table thead td:nth-child(1), table.pricing-table tbody td:nth-child(1), table.pricing-table tfoot td:nth-child(1) {
    width: 40%; }
  table thead, table tbody, table tfoot {
    display: block;
    width: 100%; }
  table thead tr {
    background: #EAF5F8; }
    table thead tr td, table thead tr th {
      width: 100%;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #3B4757; }
  table tr {
    display: flex;
    padding: 15px 20px;
    border-radius: 12px;
    margin-bottom: 5px;
    background: #F8FBFB; }
    table tr td {
      width: 100%;
      flex-grow: 1;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      text-align: center;
      color: #777777; }
      table tr td:first-child {
        text-align: left; }
      table tr td p {
        margin-bottom: 0; }
  @media (max-width: 991px) {
    table thead tr td, table thead tr th {
      display: flex;
      align-items: center;
      font-size: 14px; }
    table tr td {
      font-size: 14px; } }

.table-wrap {
  overflow: auto; }

.pricing-table thead tr td, .pricing-table thead tr th {
  text-align: center; }
  .pricing-table thead tr td:not(:first-child), .pricing-table thead tr th:not(:first-child) {
    justify-content: center; }

/*===> End table <===*/
/*===> Begin signup form <===*/
.signup-form {
  display: flex;
  box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
  border-radius: 8px;
  overflow: hidden;
  max-width: 280px; }
  .signup-form input {
    margin-bottom: 0;
    padding: 16px 17px;
    border: none;
    background: #fff; }
  .signup-form button, .signup-form .button {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .signup-form button svg, .signup-form .button svg {
      margin-left: 0; }

/*===> End signup form <===*/
/*===> Begin button <===*/
button,
.button {
  padding: 17px 20px;
  background: #46AADC;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
  text-align: center;
  line-height: 1;
  transition: all .3s;
  color: #fff; }
  button.color-2,
  .button.color-2 {
    background: #FFAF60; }
    button.color-2:hover,
    .button.color-2:hover {
      background: #fff;
      color: #FFAF60 !important; }
  button.color-3,
  .button.color-3 {
    background: #76BEBA; }
    button.color-3:hover,
    .button.color-3:hover {
      background: #fff;
      color: #76BEBA !important; }
  button:hover,
  .button:hover {
    background: #fff;
    color: #46AADC !important; }
    button:hover.read-more svg path,
    .button:hover.read-more svg path {
      fill: #46AADC; }
    button:hover svg path,
    .button:hover svg path {
      fill: #46AADC; }
  button.read-more,
  .button.read-more {
    color: #fff; }
    button.read-more svg,
    .button.read-more svg {
      margin-left: 10px; }
      button.read-more svg path,
      .button.read-more svg path {
        fill: #fff; }
  button svg,
  .button svg {
    margin-left: 10px; }
    button svg path,
    .button svg path {
      fill: #fff; }
  button img,
  .button img {
    min-width: 9px; }

/*===> End button <===*/
/*===> Begin book form <===*/
.book-form input {
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px; }

.book-form .check-list-wrap {
  padding-top: 15px;
  display: flex; }
  .book-form .check-list-wrap .check-list-header {
    margin-right: 20px; }
  .book-form .check-list-wrap .check-list {
    display: flex;
    flex-wrap: wrap; }
    .book-form .check-list-wrap .check-list label {
      width: 50%; }

.book-form .time-picker {
  margin-bottom: 5px; }

@media (max-width: 480px) {
  .book-form .check-list-wrap {
    flex-direction: column; } }

/*===> End book form <===*/
/*===> Begin checkbox <===*/
input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  margin-bottom: 0; }
  input[type='checkbox']:checked + span:after {
    content: '\f00c';
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 9px;
    border-color: #46AADC;
    color: #46AADC; }
  input[type='checkbox'] + span {
    position: relative;
    padding-left: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #777777;
    text-transform: capitalize;
    cursor: pointer;
    transition: color .3s; }
    input[type='checkbox'] + span:hover {
      color: #46AADD; }
    input[type='checkbox'] + span:after {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 12px;
      height: 12px;
      border: 1px solid #CEDDE0; }

/*===> End checkbox <===*/
/*===> Begin time picker <===*/
.time-picker {
  position: relative; }
  .time-picker img {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%); }
  .time-picker input {
    margin-bottom: 0;
    padding-right: 25px; }

/*===> End time picker <===*/
/*===> Begin datetimepicker <===*/
.bootstrap-datetimepicker-widget[class^="far"], .bootstrap-datetimepicker-widget[class*="far"], .bootstrap-datetimepicker-widget[class^="fas"], .bootstrap-datetimepicker-widget[class*="fas"], .datepicker[class^="far"], .datepicker[class*="far"], .datepicker[class^="fas"], .datepicker[class*="fas"] {
  font-family: "Font Awesome 5 Free" !important;
  padding-left: 0 !important;
  font-weight: 900; }

.bootstrap-datetimepicker-widget .list-unstyled, .datepicker .list-unstyled {
  margin-bottom: 0; }
  .bootstrap-datetimepicker-widget .list-unstyled li, .datepicker .list-unstyled li {
    padding-left: 0; }
    .bootstrap-datetimepicker-widget .list-unstyled li::after, .datepicker .list-unstyled li::after {
      display: none; }
    .bootstrap-datetimepicker-widget .list-unstyled li:nth-child(1), .datepicker .list-unstyled li:nth-child(1) {
      margin-bottom: 0; }

.bootstrap-datetimepicker-widget thead tr, .datepicker thead tr {
  display: flex;
  border-radius: 3px; }
  .bootstrap-datetimepicker-widget thead tr th, .datepicker thead tr th {
    flex-grow: 1; }

.bootstrap-datetimepicker-widget table tr, .datepicker table tr {
  padding-top: 5px;
  padding-bottom: 5px; }
  .bootstrap-datetimepicker-widget table tr:nth-child(2), .datepicker table tr:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
    background: #fff; }
    .bootstrap-datetimepicker-widget table tr:nth-child(2) th, .datepicker table tr:nth-child(2) th {
      margin: 2px;
      background: #EAF5F8;
      text-align: center; }

.bootstrap-datetimepicker-widget table td, .datepicker table td {
  height: auto; }

.bootstrap-datetimepicker-widget table tbody tr,
.bootstrap-datetimepicker-widget .datepicker-months tbody tr,
.bootstrap-datetimepicker-widget .datepicker-years tbody tr,
.bootstrap-datetimepicker-widget .datepicker-decades tbody tr, .datepicker table tbody tr,
.datepicker .datepicker-months tbody tr,
.datepicker .datepicker-years tbody tr,
.datepicker .datepicker-decades tbody tr {
  background: #fff;
  padding-left: 0;
  padding-right: 0; }
  .bootstrap-datetimepicker-widget table tbody tr span, .bootstrap-datetimepicker-widget table tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td, .datepicker table tbody tr span, .datepicker table tbody tr td,
  .datepicker .datepicker-months tbody tr span,
  .datepicker .datepicker-months tbody tr td,
  .datepicker .datepicker-years tbody tr span,
  .datepicker .datepicker-years tbody tr td,
  .datepicker .datepicker-decades tbody tr span,
  .datepicker .datepicker-decades tbody tr td {
    text-align: center; }
    .bootstrap-datetimepicker-widget table tbody tr span.active, .bootstrap-datetimepicker-widget table tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-months tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-months tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-years tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-years tbody tr td.active,
    .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span.active,
    .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td.active, .datepicker table tbody tr span.active, .datepicker table tbody tr td.active,
    .datepicker .datepicker-months tbody tr span.active,
    .datepicker .datepicker-months tbody tr td.active,
    .datepicker .datepicker-years tbody tr span.active,
    .datepicker .datepicker-years tbody tr td.active,
    .datepicker .datepicker-decades tbody tr span.active,
    .datepicker .datepicker-decades tbody tr td.active {
      background: #46AADC; }
  .bootstrap-datetimepicker-widget table tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr td,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr td, .datepicker table tbody tr td,
  .datepicker .datepicker-months tbody tr td,
  .datepicker .datepicker-years tbody tr td,
  .datepicker .datepicker-decades tbody tr td {
    text-align: left; }
  .bootstrap-datetimepicker-widget table tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-months tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-years tbody tr span,
  .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span, .datepicker table tbody tr span,
  .datepicker .datepicker-months tbody tr span,
  .datepicker .datepicker-years tbody tr span,
  .datepicker .datepicker-decades tbody tr span {
    background: #EAF5F8; }
    .bootstrap-datetimepicker-widget table tbody tr span:not([class]),
    .bootstrap-datetimepicker-widget .datepicker-months tbody tr span:not([class]),
    .bootstrap-datetimepicker-widget .datepicker-years tbody tr span:not([class]),
    .bootstrap-datetimepicker-widget .datepicker-decades tbody tr span:not([class]), .datepicker table tbody tr span:not([class]),
    .datepicker .datepicker-months tbody tr span:not([class]),
    .datepicker .datepicker-years tbody tr span:not([class]),
    .datepicker .datepicker-decades tbody tr span:not([class]) {
      display: none; }

.bootstrap-datetimepicker-widget .datepicker-days tbody tr, .datepicker .datepicker-days tbody tr {
  margin-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 2px;
  padding-right: 0;
  background: #fff; }
  .bootstrap-datetimepicker-widget .datepicker-days tbody tr td, .datepicker .datepicker-days tbody tr td {
    margin: 2px;
    background: #EAF5F8;
    text-align: center; }
    .bootstrap-datetimepicker-widget .datepicker-days tbody tr td.active, .datepicker .datepicker-days tbody tr td.active {
      background: #46AADC; }

@media (max-width: 480px) {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 275px; } }

/*===> End datetimepicker <===*/
/*===> Begin slick-dots <===*/
.slick-dots {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px; }
  .slick-dots li {
    width: auto !important;
    margin-bottom: 0 !important;
    margin-right: 25px;
    padding: 0 !important; }
    .slick-dots li.last-child {
      margin-right: 0; }
    .slick-dots li.slick-active button {
      width: 16px;
      height: 16px; }
      .slick-dots li.slick-active button:after {
        width: 16px;
        height: 16px;
        border: 3px solid #46AADC;
        background: #fff; }
    .slick-dots li::after {
      display: none; }
    .slick-dots li button {
      position: relative;
      display: block;
      width: 16px;
      height: 16px;
      padding: 0;
      font-size: 0;
      line-height: 0;
      background-color: transparent;
      box-shadow: none; }
      .slick-dots li button:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background: #46AADC;
        border-radius: 50%;
        transition: all .3s; }
  @media (max-width: 991px) {
    .slick-dots {
      padding-top: 15px; } }

.white-dots .slick-dots li.slick-active button:after {
  border: 3px solid #fff;
  background-color: transparent; }

.white-dots .slick-dots li button:after {
  background: #fff; }

/*===> Ens slick-dots <===*/
/*===> Begin read-more <===*/
.read-more {
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #46AADC; }
  .read-more:hover {
    color: #46AADD;
    text-decoration: underline; }
  .read-more svg {
    margin-left: 10px;
    position: relative;
    top: -1px; }
    .read-more svg path {
      fill: #46AADC; }
  .read-more.reverce svg {
    margin-left: 0;
    margin-right: 10px; }

/*===> End read-more <===*/
/*===> Begin svg-block <===*/
.svg-block.image-left image {
  transform: translateX(-19%); }

.svg-block.video-block {
  position: relative; }
  .svg-block.video-block p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .svg-block.video-block img {
    border-radius: 12px; }

.svg-block svg {
  width: 100%; }
  .svg-block svg mask {
    mask-type: alpha; }

@media (max-width: 767px) {
  .svg-block {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 480px) {
  .svg-block {
    max-width: 300px; } }

/*===> End svg-block <===*/
/*===> Begin video-btn <===*/
.video-btn {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #46AADC; }
  .video-btn:hover {
    text-decoration: underline;
    color: #46AADD; }
  .video-btn .play-ico {
    margin-right: 13px; }
    .video-btn .play-ico span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #46AADC; }
    .video-btn .play-ico.x2 img {
      height: 16px; }
    .video-btn .play-ico.animate {
      position: relative;
      z-index: 10; }
      .video-btn .play-ico.animate::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        z-index: 1;
        height: 80%;
        border-radius: 50%;
        background: #46AADC;
        opacity: 1;
        transform: translate(-50%, -50%);
        z-index: -1;
        transition: all .3s;
        animation: pulseElem 1s infinite linear; }
      .video-btn .play-ico.animate::before {
        width: 120%;
        height: 120%; }
      .video-btn .play-ico.animate span {
        position: relative;
        z-index: 10; }
      .video-btn .play-ico.animate .play-ico {
        position: relative;
        z-index: 10;
        margin: 0; }
    .video-btn .play-ico img {
      height: 16px;
      margin-right: -4px;
      filter: brightness(0) invert(1) !important; }
  @media (max-width: 480px) {
    .video-btn .play-ico.animate {
      position: relative;
      z-index: 10; }
      .video-btn .play-ico.animate::before {
        display: none; } }

@-webkit-keyframes pulseElem {
  0% {
    width: 80%;
    height: 80%;
    opacity: 1; }
  100% {
    width: 150%;
    height: 150%;
    opacity: 0; } }

@-o-keyframes pulseElem {
  0% {
    width: 80%;
    height: 80%; }
  50% {
    width: 120%;
    height: 120%; }
  100% {
    width: 80%;
    height: 80%; } }

@-moz-keyframes pulseElem {
  0% {
    width: 80%;
    height: 80%; }
  50% {
    width: 120%;
    height: 120%; }
  100% {
    width: 80%;
    height: 80%; } }

@keyframes pulseElem {
  0% {
    width: 80%;
    height: 80%;
    opacity: 1; }
  100% {
    width: 150%;
    height: 150%;
    opacity: 0; } }

/*===> End video-btn <===*/
/*===> Begin centre-tab-list <===*/
.centre-tab-list,
.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px; }
  .centre-tab-list li,
  .pagination li {
    margin-bottom: 0;
    margin-right: 60px;
    padding-left: 0; }
    .centre-tab-list li:first-child::after,
    .pagination li:first-child::after {
      display: none; }
    .centre-tab-list li:last-child,
    .pagination li:last-child {
      margin-right: 0; }
    .centre-tab-list li::after,
    .pagination li::after {
      content: '';
      position: absolute;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 15px;
      background: #555555; }
    .centre-tab-list li a, .centre-tab-list li p,
    .pagination li a,
    .pagination li p {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #3B4757; }
      .centre-tab-list li a.active, .centre-tab-list li p.active,
      .pagination li a.active,
      .pagination li p.active {
        color: #46AADC; }
      .centre-tab-list li a:hover, .centre-tab-list li p:hover,
      .pagination li a:hover,
      .pagination li p:hover {
        color: #46AADD; }
  @media (max-width: 1199px) {
    .centre-tab-list li,
    .pagination li {
      margin-right: 40px; }
      .centre-tab-list li::after,
      .pagination li::after {
        left: -20px; } }
  @media (max-width: 991px) {
    .centre-tab-list,
    .pagination {
      margin-bottom: 30px; }
      .centre-tab-list li,
      .pagination li {
        margin-right: 26px; }
        .centre-tab-list li::after,
        .pagination li::after {
          left: -13px; }
        .centre-tab-list li a, .centre-tab-list li p,
        .pagination li a,
        .pagination li p {
          font-size: 16px; } }
  @media (max-width: 767px) {
    .centre-tab-list,
    .pagination {
      flex-direction: column; }
      .centre-tab-list li,
      .pagination li {
        margin-right: 0px;
        margin-bottom: 15px; }
        .centre-tab-list li:last-child,
        .pagination li:last-child {
          margin-bottom: 0; }
        .centre-tab-list li::after,
        .pagination li::after {
          display: none; }
        .centre-tab-list li a, .centre-tab-list li p,
        .pagination li a,
        .pagination li p {
          text-align: center; } }

/*===> End centre-tab-list <===*/
/*===> Begin form controls <===*/
input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CEDDE0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #777777; }
  @media (max-width: 991px) {
    input {
      font-size: 14px; } }

.input-area {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .input-area .input-wrap {
    width: 50%;
    padding: 0 15px; }
  @media (max-width: 480px) {
    .input-area .input-wrap {
      width: 100%; } }

textarea {
  display: block;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
  padding: 20px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #CEDDE0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  color: #777777;
  resize: none; }
  @media (max-width: 991px) {
    textarea {
      font-size: 14px; } }

.successform {
  display: none; }
  .successform p {
    font-weight: 500;
    color: #46AADC; }

.errorform {
  display: none; }
  .errorform p {
    font-weight: 500;
    color: #c0392b; }

label.error {
  display: block;
  font-size: 12px;
  color: #c0392b; }

/*===> End form controls <===*/
/*===> Begin pagination <===*/
.pagination {
  display: flex;
  width: 100%; }
  .pagination li:last-child {
    margin-right: 0; }
  @media (max-width: 767px) {
    .pagination {
      flex-direction: row; }
      .pagination li {
        margin-right: 40px;
        margin-bottom: 0px; }
        .pagination li::after {
          display: block; } }

/*===> End pagination <===*/
/*===> Begin search-form <===*/
.search-form {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  background: #FAFCFC;
  border: 1.5px solid #DEECEF;
  box-sizing: border-box;
  border-radius: 8px; }
  .search-form input {
    margin: 0;
    padding: 16px 0;
    border: none; }
  .search-form button {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    color: #3B4757; }
    .search-form button:hover {
      color: #46AADC !important; }

/*===> End search-form <===*/
.r-cloud {
  position: relative; }
  .r-cloud::after {
    content: '';
    position: absolute;
    top: 0;
    right: 15%;
    width: 327px;
    height: 141px;
    background: url(../img/cloud.svg);
    background-size: auto 100%;
    background-repeat: no-repeat; }
  .r-cloud .container {
    position: relative;
    z-index: 1; }
  @media (max-width: 991px) {
    .r-cloud::after {
      display: none; } }

/*===> Begin slick arrow <===*/
.slick-arrow {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 76px;
  padding: 0;
  background: #fff;
  transform: translateY(-50%);
  z-index: 10; }
  .slick-arrow:hover {
    background: #46AADC; }
    .slick-arrow:hover i {
      color: #fff; }
  .slick-arrow i {
    font-size: 22px;
    color: #46AADC; }
  .slick-arrow.slick-prev {
    left: 0;
    border-radius: 0 12px 12px 0; }
  .slick-arrow.slick-next {
    right: 0;
    border-radius: 12px -0px 0 12px; }
  @media (max-width: 480px) {
    .slick-arrow {
      width: 30px;
      height: 50px; } }

/*===> End slick arrow <===*/
/*===> Begin on-scroll animate <===*/
.on-scroll.show-scroll.fade-left {
  transform: translateX(0) scale(1);
  opacity: 1; }

.on-scroll.show-scroll.fade-right {
  transform: translateX(0) scale(1);
  opacity: 1; }

.on-scroll.show-scroll.fade-up {
  transform: translateY(0) scale(1);
  opacity: 1; }

.on-scroll.fade-left {
  transform: translateX(-150px) scale(0.8);
  transition-timing-function: ease-out;
  opacity: 0;
  transition: all .6s; }

.on-scroll.fade-right {
  transform: translateX(150px) scale(0.8);
  transition-timing-function: ease-out;
  opacity: 0;
  transition: all .6s; }

.on-scroll.fade-up {
  transform: translateY(50px);
  transition-timing-function: ease-out;
  opacity: 0;
  transition: all .4s; }

/*===> End on-scroll animate <===*/
/*===> Begin up-btn <===*/
.up-btn {
  position: fixed;
  bottom: -100px;
  right: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: #46AADC;
  box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
  cursor: pointer;
  opacity: 0;
  transition: all .3s; }
  .up-btn:hover {
    background: #fff; }
    .up-btn:hover svg path {
      fill: #46AADC; }
  .up-btn.show-up {
    bottom: 50px;
    opacity: 1; }
  .up-btn svg path {
    fill: #fff; }

/*===> End up-btn <===*/
/*===> Begin page name <===*/
.page-name {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  min-height: 355px;
  padding: 40px 0;
  background-size: cover;
  background-position: center center; }
  .page-name::after {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 10%; }
  .page-name .container {
    z-index: 10; }
    .page-name .container * {
      margin-bottom: 0; }
  .page-name .page-name-img {
    position: absolute;
    z-index: -1;
    top: -15px;
    left: 22%; }
  .page-name .layer-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    overflow: hidden; }
  .page-name svg {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    min-width: 1920px;
    transform: translate(-50%, -50%); }
  @media (max-width: 991px) {
    .page-name {
      min-height: 300px; } }
  @media (max-width: 767px) {
    .page-name {
      min-height: 260px; } }
  @media (max-width: 480px) {
    .page-name {
      min-height: 200px; } }

/*===> End page name <===*/
/*===> Begin header <===*/
.header {
  position: relative;
  z-index: 100;
  padding: 30px 0;
  transform: translate(0, 0);
  transition: transform .3s; }
  .header.is-scroll {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    padding: 5px 0;
    background: #fff;
    box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .header.is-scroll .top-line::before, .header.is-scroll .top-line::after {
      display: none; }
    .header.is-scroll .top-img {
      bottom: 80%; }
  .header .top-line {
    position: relative; }
    .header .top-line::before {
      position: absolute;
      left: 5%;
      top: 130%;
      width: 307px;
      height: 132px; }
    .header .top-line::after {
      position: absolute;
      bottom: 80%;
      right: -5%;
      width: 307px;
      height: 132px; }
  .header .top-img {
    position: absolute;
    bottom: 55%;
    right: 2%;
    width: 307px;
    height: 132px; }
  @media (max-width: 1440px) {
    .header .top-line::after {
      right: 0%; } }
  @media (max-width: 991px) {
    .header {
      padding: 15px 0; }
      .header .top-line::before, .header .top-line::after {
        display: none; }
      .header .top-img {
        display: none; } }

/*===> End header <===*/
/*===> Begin main nav <===*/
.main-nav .close-nav {
  display: none; }
  .main-nav .close-nav svg {
    position: relative;
    width: 30px; }
    .main-nav .close-nav svg path {
      stroke: black;
      stroke-width: 2px; }

@media (max-width: 1199px) {
  .main-nav {
    width: 100%; } }

@media (max-width: 1199px) {
  .main-nav {
    position: fixed;
    top: 0;
    right: -320px;
    z-index: 1000;
    height: 100%;
    width: 320px;
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    padding: 10px 20px;
    background: #fff;
    overflow: hidden;
    box-shadow: -1px 0 5px 0 rgba(50, 50, 50, 0.3);
    transition: all .3s; }
    .main-nav.active {
      right: 0; }
    .main-nav .close-nav {
      display: block;
      margin-bottom: 10px; } }

/*===> End main nav <===*/
/*===> Begin top line <===*/
.top-line {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .top-line {
      flex-wrap: wrap; }
      .top-line .logo {
        order: 0; }
      .top-line .main-nav {
        order: 2; }
      .top-line .header-controls {
        order: 1; } }

/*===> End top line <===*/
/*===> Begin main nav list <===*/
.main-nav-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px; }
  .main-nav-list li {
    position: relative;
    margin-right: 35px;
    margin-bottom: 0;
    padding-left: 0; }
    .main-nav-list li:last-child {
      margin-right: 0; }
    .main-nav-list li::after {
      display: none; }
    .main-nav-list li:nth-child(n+4) .inner-dropdown-menu {
      left: auto;
      right: 100%; }
    .main-nav-list li:hover .tt-dropdown-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0); }
    .main-nav-list li a {
      padding: 15px 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: normal;
      color: #3B4757;
      transition: all .3s; }
      .main-nav-list li a:hover, .main-nav-list li a.active-link {
        color: #46AADD; }
      .main-nav-list li a i {
        display: none; }
    .main-nav-list li .tt-dropdown-menu {
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      width: 220px;
      padding-top: 20px;
      padding-bottom: 10px;
      border-top: 3px solid #46AADD;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
      background: #fff;
      transition: all .3s;
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px); }
      .main-nav-list li .tt-dropdown-menu li {
        position: relative;
        margin-right: 0; }
        .main-nav-list li .tt-dropdown-menu li:hover a i {
          transform: translateY(calc(-50% - 5px)) rotate(180deg); }
        .main-nav-list li .tt-dropdown-menu li:hover .inner-dropdown-menu {
          opacity: 1;
          visibility: visible;
          transform: translateY(0); }
        .main-nav-list li .tt-dropdown-menu li a {
          display: block;
          padding: 0 20px 10px; }
          .main-nav-list li .tt-dropdown-menu li a i {
            position: absolute;
            top: 50%;
            right: 15px;
            display: block;
            transform: translateY(calc(-50% - 5px));
            transition: transform .3s; }
    .main-nav-list li .inner-dropdown-menu {
      position: absolute;
      top: -10px;
      padding: 10px 0;
      left: 100%;
      width: 200px;
      transition: all .3s;
      opacity: 0;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
      background: #fff;
      visibility: hidden;
      transform: translateY(20px); }
  @media (max-width: 1199px) {
    .main-nav-list {
      padding: 0; } }
  @media (max-width: 1199px) {
    .main-nav-list {
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      overflow: auto; }
      .main-nav-list li {
        display: block;
        width: 100%;
        margin-right: 0; }
        .main-nav-list li.active a i {
          transform: rotate(180deg); }
        .main-nav-list li a {
          position: relative;
          display: block;
          width: 100%;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-right: 40px; }
          .main-nav-list li a i {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%; }
        .main-nav-list li .tt-dropdown-menu {
          position: static;
          width: 100%;
          display: none;
          padding-top: 0;
          padding-bottom: 0;
          border-top: 0;
          box-shadow: none;
          visibility: visible;
          opacity: 1;
          transform: translate(0, 0);
          transition: all .0s; }
          .main-nav-list li .tt-dropdown-menu li:hover a i {
            transform: rotate(0deg); }
          .main-nav-list li .tt-dropdown-menu li.active a i {
            transform: rotate(180deg); }
          .main-nav-list li .tt-dropdown-menu li a {
            position: relative;
            padding-top: 10px;
            padding-right: 40px;
            padding-left: 10px; }
            .main-nav-list li .tt-dropdown-menu li a i {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 0;
              right: 0;
              width: 40px;
              height: 100%;
              transform: translate(0); }
        .main-nav-list li .inner-dropdown-menu {
          position: static;
          width: 100%;
          display: none;
          padding: 0;
          box-shadow: none;
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
          transition: all .0s; }
          .main-nav-list li .inner-dropdown-menu li a {
            padding-left: 20px; } }
  @media (max-width: 991px) {
    .main-nav-list li a {
      font-size: 14px; } }

/*===> End main nav list <===*/
/*===> Begin header controls <===*/
.header-controls .hamburger {
  display: none; }

.header-controls .header-search {
  position: relative; }
  .header-controls .header-search .search-form {
    position: absolute;
    top: 100%;
    right: -10px;
    width: 280px;
    transform: translateY(0px);
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }
    .header-controls .header-search .search-form.active {
      transform: translateY(20px);
      opacity: 1;
      visibility: visible; }

@media (max-width: 1199px) {
  .header-controls {
    display: flex;
    align-items: center; }
    .header-controls .hamburger {
      display: block;
      margin-left: 25px; } }

/*===> End header controls <===*/
/*===> Begin wave block <===*/
.wave-block {
  position: relative;
  overflow: hidden;
  padding: 150px 0;
  background-size: cover;
  background-size: 100% 100%; }
  .wave-block.reverce-wave::before {
    left: auto !important;
    right: 20%;
    transform: translateY(-45%) rotate(-9deg); }
  .wave-block.reverce-wave::after {
    right: auto !important;
    left: 18%; }
  .wave-block.wave-gallery {
    min-height: 1060px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1199px) {
      .wave-block.wave-gallery {
        min-height: 0; } }
  .wave-block.bg-cover {
    background-size: cover !important; }
  .wave-block::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18%;
    width: 407px;
    height: 170px;
    background: url(../img/cloudx2.png);
    background-size: cover;
    filter: brightness(0) invert(1);
    transform: translateY(-55%) rotate(0deg); }
  .wave-block::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 20%;
    width: 362px;
    height: 150px;
    background: url(../img/cloudx2.png);
    background-size: cover;
    filter: brightness(0) invert(1);
    transform: translateY(60%); }
  @media (max-width: 1440px) {
    .wave-block.reverce-wave::before {
      right: 12%; }
    .wave-block.reverce-wave::after {
      left: 12%; }
    .wave-block::before {
      top: 0;
      left: 12%;
      width: 340px;
      height: 145px; }
    .wave-block::after {
      bottom: 0;
      right: 20%;
      width: 340px;
      height: 145px; } }
  @media (max-width: 1199px) {
    .wave-block {
      background-size: auto 100%; }
      .wave-block.reverce-wave::before {
        right: 12%; }
      .wave-block.reverce-wave::after {
        left: 12%; }
      .wave-block::before {
        top: 0;
        left: 12%;
        width: 340px;
        height: 145px;
        transform: translateY(-59%) rotate(9deg); }
      .wave-block::after {
        bottom: 0;
        right: 20%;
        width: 300px;
        height: 125px;
        transform: translateY(50%); } }
  @media (max-width: 991px) {
    .wave-block {
      padding: 120px 0; }
      .wave-block.reverce-wave::before {
        right: 12%;
        transform: translateY(-59%) rotate(0deg); }
      .wave-block.reverce-wave::after {
        left: 12%;
        transform: translateY(45%); }
      .wave-block::before {
        top: 0;
        left: 12%;
        width: 200px;
        height: 85px;
        transform: translateY(-59%) rotate(7deg); }
      .wave-block::after {
        bottom: 0;
        right: 10%;
        width: 200px;
        height: 85px;
        transform: translateY(35%); } }
  @media (max-width: 480px) {
    .wave-block {
      padding: 80px 0;
      background-position: 15% 0; }
      .wave-block::before {
        top: 0;
        left: 12%;
        width: 200px;
        height: 85px;
        transform: translateY(-50%) rotate(5deg); }
      .wave-block::after {
        bottom: 0;
        right: 10%;
        width: 200px;
        height: 85px;
        transform: translateY(30%); } }

/*===> End wave block <===*/
/*===> Begin footer <===*/
footer {
  position: relative; }
  footer .footer-top {
    position: relative;
    min-height: 500px;
    padding: 130px 0;
    background-position: center center;
    background-size: cover; }
    footer .footer-top .container {
      position: relative;
      z-index: 1; }
    footer .footer-top .logo {
      margin-bottom: 30px; }
      footer .footer-top .logo + p {
        max-width: 320px;
        margin-bottom: 30px; }
  footer h4 {
    margin-bottom: 30px; }
  footer .footer-bottom-wrap {
    background: #fff;
    padding: 25px 0; }
    footer .footer-bottom-wrap p {
      margin-bottom: 0; }
      footer .footer-bottom-wrap p a {
        color: inherit; }
        footer .footer-bottom-wrap p a:hover {
          color: #46AADC; }
    footer .footer-bottom-wrap .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      footer .footer-bottom-wrap .footer-bottom .soc-link-wrap {
        display: flex;
        align-items: center; }
        footer .footer-bottom-wrap .footer-bottom .soc-link-wrap p {
          margin-bottom: 0;
          margin-right: 20px; }
  @media (max-width: 991px) {
    footer .footer-top {
      padding: 80px 0 50px;
      background: url(../img/tablet_footer.svg) !important;
      background-size: cover !important;
      background-repeat: no-repeat; }
    footer .f-nav li a {
      font-size: 14px; }
    footer .f-subscribe, footer .f-nav {
      margin-bottom: 40px; }
    footer .f-subscribe {
      display: flex;
      flex-direction: column;
      align-items: center; }
      footer .f-subscribe .logo + p {
        text-align: center;
        max-width: 510px;
        margin-bottom: 40px; }
    footer .footer-bottom-wrap .footer-bottom {
      flex-direction: column; }
      footer .footer-bottom-wrap .footer-bottom p {
        text-align: center; }
      footer .footer-bottom-wrap .footer-bottom .soc-link-wrap {
        margin-top: 15px; }
        footer .footer-bottom-wrap .footer-bottom .soc-link-wrap p {
          display: none; } }
  @media (max-width: 480px) {
    footer .footer-top {
      padding-top: 110px;
      padding-bottom: 80px; }
    footer h4 {
      margin-bottom: 20px; }
    footer .f-nav {
      margin-bottom: 30px; }
      footer .f-nav li a {
        font-size: 14px; }
    footer .signup-form {
      max-width: none;
      width: 100%; } }

/*===> End footer <===*/
/*===> Begin contact list <===*/
.contact-list {
  margin-bottom: -15px; }
  .contact-list a, .contact-list p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.8em;
    color: #777777; }
    .contact-list a img, .contact-list p img {
      margin-top: 5px;
      margin-right: 13px; }
  .contact-list a:hover {
    color: #46AADC; }
  @media (max-width: 991px) {
    .contact-list a, .contact-list p {
      font-size: 14px; } }

/*===> End contact list <===*/
/*===> Begin soc link <===*/
.soc-link {
  display: flex; }
  .soc-link.soc-link__bg li a {
    background: #F0F8FB; }
  .soc-link li {
    margin-bottom: 0;
    margin-right: 5px;
    padding-left: 0; }
    .soc-link li:last-child {
      margin-right: 0; }
    .soc-link li::after {
      display: none; }
    .soc-link li a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
      .soc-link li a:hover {
        background: #46AADC; }
        .soc-link li a:hover img {
          filter: brightness(0) invert(1); }
        .soc-link li a:hover path {
          fill: #fff; }
        .soc-link li a:hover i {
          color: #fff; }
      .soc-link li a svg, .soc-link li a img {
        height: 18px;
        width: 18px; }

/*===> End soc link <===*/
/*===> Begin quickLinks <===*/
.quickLinks-wrap {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 500;
  transform: translateY(-50%); }
  .quickLinks-wrap.mobile {
    position: relative;
    z-index: 950;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    transform: translateY(0); }
    .quickLinks-wrap.mobile .quickLinks-item {
      flex-grow: 1;
      width: 25%; }
      .quickLinks-wrap.mobile .quickLinks-item:hover .quickLinks-desc {
        visibility: hidden; }
      .quickLinks-wrap.mobile .quickLinks-item:nth-child(1) {
        order: 4; }
      .quickLinks-wrap.mobile .quickLinks-item:nth-child(2) {
        order: 5; }
      .quickLinks-wrap.mobile .quickLinks-item:nth-child(3) {
        order: 6; }
      .quickLinks-wrap.mobile .quickLinks-item:nth-child(4) {
        order: 7; }
      .quickLinks-wrap.mobile .quickLinks-item .quickLinks-desc.active {
        display: block;
        visibility: visible;
        opacity: 1; }
    .quickLinks-wrap.mobile .quickLinks-head {
      width: 100%;
      height: 65px;
      margin-bottom: 0;
      border-radius: 0; }
      .quickLinks-wrap.mobile .quickLinks-head img {
        height: 18px;
        margin-bottom: 5px; }
    .quickLinks-wrap.mobile .quickLinks-desc {
      position: static;
      display: none;
      width: 100%;
      padding-top: 0;
      visibility: visible;
      opacity: 1;
      transition: all 0s;
      border-radius: 0; }
      .quickLinks-wrap.mobile .quickLinks-desc.show {
        display: block; }
      .quickLinks-wrap.mobile .quickLinks-desc:nth-child(5) {
        order: 0;
        background: #46AADC; }
      .quickLinks-wrap.mobile .quickLinks-desc:nth-child(6) {
        order: 1;
        background: #FFAF60; }
      .quickLinks-wrap.mobile .quickLinks-desc:nth-child(7) {
        order: 2;
        background: #76BEBA; }
      .quickLinks-wrap.mobile .quickLinks-desc:nth-child(8) {
        order: 3;
        background: #7192BD; }
      .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content {
        padding: 15px 20px;
        background-color: transparent; }
        .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content h4 {
          display: none; }
        .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .contact-list a, .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .contact-list p {
          color: #fff; }
          .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .contact-list a img, .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .contact-list p img {
            filter: brightness(0) invert(1); }
        .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .download-list p {
          justify-content: flex-start; }
          .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .download-list p span {
            color: #fff; }
            .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .download-list p span img {
              filter: brightness(0) invert(1); }
          .quickLinks-wrap.mobile .quickLinks-desc .quickLinks-content .download-list p a {
            padding-left: 15px;
            color: #fff; }
    .quickLinks-wrap.mobile .book-form {
      color: #fff; }
      .quickLinks-wrap.mobile .book-form ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #fff;
        opacity: 1;
        /* Firefox */ }
      .quickLinks-wrap.mobile .book-form :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff; }
      .quickLinks-wrap.mobile .book-form ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #fff; }
      .quickLinks-wrap.mobile .book-form img {
        filter: brightness(0) invert(1); }
      .quickLinks-wrap.mobile .book-form .check-list-wrap * {
        color: #fff !important; }
      .quickLinks-wrap.mobile .book-form input {
        color: #fff; }
  .quickLinks-wrap .quickLinks-item {
    position: relative; }
    .quickLinks-wrap .quickLinks-item:hover .quickLinks-head {
      position: relative;
      border-radius: 0; }
    .quickLinks-wrap .quickLinks-item:hover .quickLinks-desc {
      top: 0;
      opacity: 1;
      visibility: visible; }
    .quickLinks-wrap .quickLinks-item:nth-child(1) .quickLinks-head, .quickLinks-wrap .quickLinks-item:nth-child(1) .quickLinks-desc {
      background: #46AADC; }
    .quickLinks-wrap .quickLinks-item:nth-child(2) .quickLinks-head, .quickLinks-wrap .quickLinks-item:nth-child(2) .quickLinks-desc {
      background: #FFAF60;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .quickLinks-wrap .quickLinks-item:nth-child(3) .quickLinks-head, .quickLinks-wrap .quickLinks-item:nth-child(3) .quickLinks-desc {
      background: #76BEBA;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .quickLinks-wrap .quickLinks-item:nth-child(4) .quickLinks-head, .quickLinks-wrap .quickLinks-item:nth-child(4) .quickLinks-desc {
      background: #7192BD;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
  .quickLinks-wrap .quickLinks-head {
    width: 104px;
    height: 98px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    border-radius: 9px 0 0 9px;
    background: #46AADC;
    cursor: pointer;
    transition: border-radius .3s;
    transition: width .3s; }
    .quickLinks-wrap .quickLinks-head img {
      margin-bottom: 10px;
      transition: margin .3s, width .3s, height .3s; }
    .quickLinks-wrap .quickLinks-head p {
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
      transition: transform .3s height .3s; }
  .quickLinks-wrap .quickLinks-desc {
    position: absolute;
    top: 10%;
    right: 100%;
    width: 445px;
    padding-top: 4px;
    border-radius: 9px 0 9px 9px;
    box-shadow: 0px 9px 14px rgba(113, 146, 189, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }
    .quickLinks-wrap .quickLinks-desc.hover {
      opacity: 1;
      visibility: visible; }
    .quickLinks-wrap .quickLinks-desc:nth-child(5) {
      background: #46AADC; }
    .quickLinks-wrap .quickLinks-desc:nth-child(6) {
      background: #FFAF60;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .quickLinks-wrap .quickLinks-desc:nth-child(7) {
      background: #76BEBA;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .quickLinks-wrap .quickLinks-desc:nth-child(8) {
      background: #7192BD;
      box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14); }
    .quickLinks-wrap .quickLinks-desc .quickLinks-content {
      padding: 25px 30px;
      border-radius: 9px;
      background: #fff; }
    .quickLinks-wrap .quickLinks-desc .map-block {
      height: 300px;
      border-radius: 9px; }
  @media (max-width: 1440px) {
    .quickLinks-wrap {
      top: 45%; } }
  @media (min-width: 991px) {
    .quickLinks-wrap:not(.mobile).scroll {
      opacity: .7; }
      .quickLinks-wrap:not(.mobile).scroll:hover {
        opacity: 1; }
        .quickLinks-wrap:not(.mobile).scroll:hover .quickLinks-head {
          width: 104px; }
          .quickLinks-wrap:not(.mobile).scroll:hover .quickLinks-head img {
            margin-bottom: 10px;
            width: auto; }
          .quickLinks-wrap:not(.mobile).scroll:hover .quickLinks-head p {
            height: auto;
            transform: scale(1); }
      .quickLinks-wrap:not(.mobile).scroll .quickLinks-head {
        width: 25px; }
        .quickLinks-wrap:not(.mobile).scroll .quickLinks-head img {
          margin: 0;
          width: 15px; }
        .quickLinks-wrap:not(.mobile).scroll .quickLinks-head p {
          transform: scale(0);
          height: 0; } }
  @media (max-height: 700px) {
    .quickLinks-wrap .quickLinks-item {
      position: relative; }
      .quickLinks-wrap .quickLinks-item:hover:nth-child(3) .quickLinks-desc, .quickLinks-wrap .quickLinks-item:hover:nth-child(4) .quickLinks-desc {
        bottom: 0; }
      .quickLinks-wrap .quickLinks-item:nth-child(3) .quickLinks-desc, .quickLinks-wrap .quickLinks-item:nth-child(4) .quickLinks-desc {
        top: auto;
        bottom: 0;
        padding-top: 0px;
        padding-bottom: 4px;
        border-radius: 9px 9px 0 9px; }
        .quickLinks-wrap .quickLinks-item:nth-child(3) .quickLinks-desc .quickLinks-content, .quickLinks-wrap .quickLinks-item:nth-child(4) .quickLinks-desc .quickLinks-content {
          padding: 25px 30px;
          border-radius: 9px;
          background: #fff; } }
  @media (max-width: 480px) {
    .quickLinks-wrap .quickLinks-item .quickLinks-head {
      height: 36px; }
      .quickLinks-wrap .quickLinks-item .quickLinks-head img {
        margin-bottom: 0; }
      .quickLinks-wrap .quickLinks-item .quickLinks-head p {
        display: none; } }

/*===> End quickLinks <===*/
/*===> Begin download list <===*/
.download-list p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }
  .download-list p:last-child {
    margin-bottom: 0; }
  .download-list p span {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #3B4757; }
    .download-list p span img {
      margin-right: 15px; }
  .download-list p a {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #46AADD; }
    .download-list p a img {
      margin-right: 10px; }

@media (max-width: 991px) {
  .download-list p span {
    font-size: 14px; } }

@media (max-width: 480px) {
  .download-list p span {
    font-size: 14px; }
    .download-list p span img {
      margin-right: 10px; }
  .download-list p a {
    text-align: center;
    font-size: 12px; }
    .download-list p a img {
      margin-right: 5px; } }

/*===> End download list <===*/
/*===> Begin calendar table <===*/
.calendar-table thead tr {
  padding: 10px 15px; }
  .calendar-table thead tr:first-child {
    display: flex; }
    .calendar-table thead tr:first-child th:nth-child(2) {
      width: 100%; }

.calendar-table tr {
  margin-bottom: 2px;
  padding: 5px 15px; }

/*===> End calendar table <===*/
/*===> Begin main slider <===*/
.main-slider-wrap {
  position: relative;
  z-index: 10; }
  .main-slider-wrap .slick-list, .main-slider-wrap .slick-track {
    height: 100%; }
  .main-slider-wrap .slick-dots {
    position: absolute;
    left: 0;
    bottom: 75px;
    width: 100%; }
  @media (max-width: 480px) {
    .main-slider-wrap {
      margin-top: -5px; }
      .main-slider-wrap .slick-dots {
        bottom: 25px; } }

.main-slider {
  position: relative;
  height: 917px;
  overflow: hidden; }
  .main-slider .sl-card {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    background-size: cover;
    overflow: hidden; }
    .main-slider .sl-card.slick-active .slide-content {
      transform: scale(1); }
      .main-slider .sl-card.slick-active .slide-content img {
        opacity: 1;
        transform: translateY(0); }
      .main-slider .sl-card.slick-active .slide-content .content-text-cursive {
        opacity: 1; }
      .main-slider .sl-card.slick-active .slide-content .content-head {
        opacity: 1;
        transform: translateY(0); }
    .main-slider .sl-card .container {
      position: relative;
      z-index: 10; }
  .main-slider .slider-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 100%;
    min-width: 1920px;
    height: 100%;
    transform: translate(-50%, -50%); }
  .main-slider .slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 538px;
    height: 422px;
    margin-left: auto;
    margin-right: -15px;
    padding: 20px;
    text-align: center;
    background-size: cover;
    transform: scale(0.85);
    transition: transform .4s; }
    .main-slider .slide-content img {
      margin-bottom: 15px;
      opacity: 0;
      transform: translateY(-15px);
      transition: opacity .4s, transform .4s;
      transition-delay: .3s; }
    .main-slider .slide-content .content-text-cursive {
      margin-bottom: 10px;
      font-family: "Georgia", sans-serif;
      font-style: italic;
      font-weight: normal;
      font-size: 22px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #333333;
      opacity: 0;
      transition: opacity .4s;
      transition-delay: .5s; }
    .main-slider .slide-content .content-head {
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 52px;
      line-height: 1.3em;
      color: #3B4757;
      opacity: 0;
      transform: translateY(15px);
      transition: opacity .4s, transform .4s;
      transition-delay: .4s; }
      .main-slider .slide-content .content-head span {
        color: #46AADD; }
  @media (max-width: 1440px) {
    .main-slider .slide-content {
      margin-right: 0; } }
  @media (max-width: 991px) {
    .main-slider {
      height: 575px; }
      .main-slider .slide-content {
        width: 400px;
        height: 314px; }
        .main-slider .slide-content .content-text-cursive {
          font-size: 18px; }
        .main-slider .slide-content .content-head {
          font-size: 42px; }
        .main-slider .slide-content img {
          width: 120px; }
      .main-slider .slider-bg {
        min-width: 100%;
        max-width: none;
        width: auto; } }
  @media (max-width: 480px) {
    .main-slider {
      height: 293px; }
      .main-slider .slide-content {
        width: 262px;
        height: 205px;
        margin-left: auto;
        margin-right: auto; }
        .main-slider .slide-content .content-text-cursive {
          font-size: 14px;
          margin-bottom: 5px; }
        .main-slider .slide-content .content-head {
          font-size: 28px; }
        .main-slider .slide-content img {
          width: 75px;
          margin-bottom: 5px; } }

/*===> End main slider <===*/
/*===> Begin block-header <===*/
.block-header p {
  position: relative;
  padding-left: 60px;
  font-family: "Georgia", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 22px;
  color: #3B4757; }
  .block-header p::after {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    margin-top: -2px;
    width: 40px;
    height: 3px;
    background: #46AADC; }

.block-header h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500; }
  .block-header h2 span {
    color: #46AADC; }

@media (max-width: 991px) {
  .block-header p {
    padding-left: 50px;
    font-size: 18px; }
    .block-header p::after {
      width: 30px;
      height: 2px; } }

@media (max-width: 480px) {
  .block-header p {
    padding-left: 40px;
    font-size: 16px; }
    .block-header p::after {
      width: 25px; } }

/*===> End block-header <===*/
/*===> Begin text-block <===*/
.text-block {
  padding-top: 120px;
  padding-bottom: 120px; }
  .text-block.on-scroll.show-scroll .block-header * {
    opacity: 1;
    transform: translateY(0); }
  .text-block.on-scroll.show-scroll p, .text-block.on-scroll.show-scroll a {
    opacity: 1;
    transform: translateY(0); }
  .text-block.on-scroll .block-header * {
    opacity: 0;
    transform: translateY(50px);
    transition: all .3s;
    transition-delay: .12s; }
    .text-block.on-scroll .block-header *:nth-child(1) {
      transition-delay: .5s; }
    .text-block.on-scroll .block-header *:nth-child(2) {
      transition-delay: .7s; }
  .text-block.on-scroll p {
    opacity: 0;
    transform: translateY(50px);
    transition: all .3s;
    transition-delay: .8s; }
  .text-block.on-scroll a {
    opacity: 0;
    transform: translateY(50px);
    transition: all .3s;
    transition-delay: 1s; }
  .text-block .svg-block {
    max-width: 500px; }
    .text-block .svg-block.video-block {
      margin-left: auto;
      margin-right: auto; }
    @media (max-width: 480px) {
      .text-block .svg-block {
        max-width: 214px; } }
  @media (max-width: 1199px) {
    .text-block {
      padding-top: 60px; } }
  @media (max-width: 991px) {
    .text-block {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (max-width: 767px) {
    .text-block {
      padding-top: 40px; }
      .text-block.text-block-reverce .svg-block {
        margin-top: 40px; } }

/*===> End text-block <===*/
/*===> Begin education short <===*/
.education-short {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
  .education-short:hover .hide-block {
    opacity: 1;
    visibility: visible; }
    .education-short:hover .hide-block > p, .education-short:hover .hide-block .education-short-info, .education-short:hover .hide-block a {
      opacity: 1;
      transform: translateY(0); }
  .education-short.color-2 .education-bottom {
    background: #76BEBA; }
    .education-short.color-2 .education-bottom p .education-age {
      color: #B5D9D7; }
  .education-short.color-3 .education-bottom {
    background: #7192BD; }
    .education-short.color-3 .education-bottom p .education-age {
      color: #9FCFE9; }
  .education-short.color-4 .education-bottom {
    background: #46AADC; }
    .education-short.color-4 .education-bottom p .education-age {
      color: #9FCFE9; }
  .education-short .education-top {
    position: relative; }
    .education-short .education-top img {
      width: 100%; }
  .education-short .hide-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 35px 25px;
    background: rgba(255, 255, 255, 0.95);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all .3s; }
    .education-short .hide-block > p {
      opacity: 0;
      transform: translateY(15px);
      transition: opacity .3s, transform .3s;
      transition-delay: .2s; }
    .education-short .hide-block p {
      margin-bottom: 20px; }
    .education-short .hide-block .education-short-info {
      margin-bottom: 20px;
      opacity: 0;
      transform: translateY(15px);
      transition: opacity .3s, transform .3s;
      transition-delay: .3s; }
      .education-short .hide-block .education-short-info p {
        margin-bottom: 5px; }
        .education-short .hide-block .education-short-info p:last-child {
          margin-bottom: 0; }
      .education-short .hide-block .education-short-info span {
        color: #333333; }
    .education-short .hide-block a {
      opacity: 0;
      transform: translateY(15px);
      transition: opacity .3s, transform .3s;
      transition-delay: .4s; }
  .education-short .education-bottom {
    background: #FFAF60; }
    .education-short .education-bottom p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 10px 25px;
      font-size: 21px;
      line-height: 21px;
      color: #fff; }
      .education-short .education-bottom p .education-age {
        padding: 0 5px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 62px;
        white-space: nowrap;
        line-height: 1em;
        color: #FFD1AA; }
      .education-short .education-bottom p .old {
        font-size: 18px;
        font-weight: normal; }
  .education-short .read-more svg {
    position: relative;
    top: -1px; }
  @media (max-width: 480px) {
    .education-short .education-bottom p {
      font-size: 19px; }
      .education-short .education-bottom p .education-age {
        font-size: 57px; }
      .education-short .education-bottom p .old {
        font-size: 14px; } }
  @media (max-width: 380px) {
    .education-short .hide-block {
      padding: 20px; }
      .education-short .hide-block .education-short-info {
        margin-bottom: 8px; }
      .education-short .hide-block p {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px; }
    .education-short .education-bottom p {
      font-size: 18px; }
      .education-short .education-bottom p .education-age {
        font-size: 37px; }
      .education-short .education-bottom p .old {
        font-size: 14px; } }

/*===> End education short <===*/
/*===> Begin learning-elements-wrap <===*/
.el-wr {
  padding: 20px 25px 0; }
  @media (max-width: 991px) {
    .el-wr {
      margin-top: 60px; } }
  @media (max-width: 480px) {
    .el-wr {
      margin-top: 25px;
      padding: 20px 0 0; } }

.learning-elements-wrap {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto; }
  .learning-elements-wrap::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    border: 5px solid #F0F8FA;
    border-radius: 50%; }
  .learning-elements-wrap .learning-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #EAF5F7;
    cursor: pointer; }
    .learning-elements-wrap .learning-item img {
      margin-bottom: 5px; }
    .learning-elements-wrap .learning-item p {
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #3B4757; }
  .learning-elements-wrap .circle-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 5; }
    .learning-elements-wrap .circle-container .learning-item {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      margin-left: -62px;
      margin-top: -62px;
      width: 124px;
      height: 124px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #EAF5F7; }
      .learning-elements-wrap .circle-container .learning-item::before {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        z-index: -2;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        background: #fff;
        border-radius: 50%;
        transition: all .3s; }
      .learning-elements-wrap .circle-container .learning-item::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #EAF5F7;
        border-radius: 50%;
        transition: all .3s; }
      .learning-elements-wrap .circle-container .learning-item.active, .learning-elements-wrap .circle-container .learning-item:hover {
        background: #fff; }
        .learning-elements-wrap .circle-container .learning-item.active::before, .learning-elements-wrap .circle-container .learning-item:hover::before {
          background: #EAF5F7; }
        .learning-elements-wrap .circle-container .learning-item.active::after, .learning-elements-wrap .circle-container .learning-item:hover::after {
          background: #fff; }
      .learning-elements-wrap .circle-container .learning-item img {
        margin-bottom: 5px; }
      .learning-elements-wrap .circle-container .learning-item p {
        margin-bottom: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 12px;
        color: #3B4757; }
  .learning-elements-wrap .tab-element-content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 55%;
    height: 60%;
    transform: translate(-50%, -50%); }
    .learning-elements-wrap .tab-element-content .for-tab {
      display: none; }
      .learning-elements-wrap .tab-element-content .for-tab.active {
        display: block; }
    .learning-elements-wrap .tab-element-content .for-tab.start {
      display: block; }
      .learning-elements-wrap .tab-element-content .for-tab.start h3 {
        margin-bottom: 0; }
    .learning-elements-wrap .tab-element-content h3 {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px; }
      .learning-elements-wrap .tab-element-content h3::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 41px;
        height: 3px;
        background: #46AADC; }
  .learning-elements-wrap .my-paroller {
    position: absolute;
    left: -50px;
    bottom: 0;
    z-index: -1; }
  @media (max-width: 1199px) {
    .learning-elements-wrap {
      max-width: 450px; }
      .learning-elements-wrap::after {
        top: 3%;
        left: 3%;
        width: 95%;
        height: 95%; }
      .learning-elements-wrap .circle-container .learning-item {
        margin-left: -50px;
        margin-top: -50px;
        width: 100px;
        height: 100px; }
      .learning-elements-wrap .for-tab h3 {
        margin-bottom: 0; }
      .learning-elements-wrap .for-tab p {
        display: none; } }
  @media (max-width: 991px) {
    .learning-elements-wrap .for-tab h3 {
      margin-bottom: 20px; }
    .learning-elements-wrap .for-tab p {
      display: block; } }
  @media (max-width: 767px) {
    .learning-elements-wrap .my-paroller {
      opacity: .5; } }
  @media (max-width: 480px) {
    .learning-elements-wrap {
      padding-left: 10px;
      padding-right: 10px; }
      .learning-elements-wrap::after {
        top: 6%;
        left: 6%;
        width: 88%;
        height: 88%;
        border: 5px solid #F0F8FA;
        border-radius: 50%; }
      .learning-elements-wrap .circle-container .learning-item {
        margin-left: -30px;
        margin-top: -30px;
        width: 60px;
        height: 60px; }
        .learning-elements-wrap .circle-container .learning-item img {
          height: 30px;
          margin-bottom: 0; }
        .learning-elements-wrap .circle-container .learning-item p {
          display: none; }
      .learning-elements-wrap .for-tab h3 {
        font-size: 18px;
        padding-bottom: 10px;
        margin-bottom: 0; }
      .learning-elements-wrap .for-tab p {
        display: none;
        font-size: 11px; } }

/*===> End learning-elements-wrap <===*/
/*===> Begin education-slier <===*/
.education-slier {
  margin-right: -15px;
  margin-left: -15px; }
  .education-slier .it-card {
    padding-left: 15px;
    padding-right: 15px; }
  .education-slier .slick-list {
    padding-bottom: 25px; }
  .education-slier .slick-dots {
    padding-top: 25px; }
  @media (max-width: 991px) {
    .education-slier .slick-dots {
      padding-top: 15px; } }
  @media (max-width: 767px) {
    .education-slier .slick-dots {
      padding-top: 25px; } }

/*===> End education-slier <===*/
/*===> Begin video-section <===*/
.video-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 676px;
  background-position: center center; }
  @media (max-width: 991px) {
    .video-section {
      height: 496px;
      background-size: auto 100%; }
      .video-section p {
        max-width: 400px; } }
  @media (max-width: 480px) {
    .video-section {
      height: 481px; }
      .video-section p {
        max-width: 240px; } }

/*===> End video-section <===*/
/*===> Begin lightbox <===*/
.modal-body {
  overflow: hidden; }
  .modal-body .ekko-lightbox-item {
    overflow: hidden; }

.modal-header .close {
  padding: 5px;
  box-shadow: none; }

.ekko-lightbox-nav-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ekko-lightbox-nav-overlay a {
    display: block;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 40px;
    color: #fff; }
    .ekko-lightbox-nav-overlay a:nth-child(1) {
      padding-left: 20px; }
    .ekko-lightbox-nav-overlay a:nth-child(2) {
      justify-content: flex-end;
      padding-right: 20px; }

/*===> End lightbox <===*/
/*===> Begin advantage-list <===*/
.advantage-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -65px; }
  .advantage-list.advantage-second {
    margin-bottom: -30px; }
    .advantage-list.advantage-second li {
      width: 25%;
      margin-bottom: 30px;
      padding-top: 45px;
      padding-left: 15px; }
      .advantage-list.advantage-second li::before {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: .14;
        font-size: 110px;
        line-height: 1em; }
      .advantage-list.advantage-second li::after {
        content: none !important;
        display: none !important; }
      .advantage-list.advantage-second li .advantage-block h5 {
        text-align: center; }
      .advantage-list.advantage-second li .advantage-block p {
        text-align: center; }
  .advantage-list li {
    position: relative;
    width: 33.33%;
    margin-bottom: 60px;
    padding-left: 70px;
    padding-right: 15px; }
    .advantage-list li::before {
      position: absolute;
      z-index: 4;
      top: 0;
      left: 15px;
      margin: 0;
      font-size: 32px; }
    .advantage-list li:nth-child(odd)::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: -20px;
      left: 0%;
      width: 100%;
      height: 192px;
      background: url(../img/cloudx2.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      transform: rotateY(180deg); }
    .advantage-list li .advantage-block {
      position: relative;
      z-index: 10; }
    .advantage-list li h5 {
      margin-bottom: 15px;
      font-weight: 500;
      color: #3B4757; }
    .advantage-list li p {
      margin-bottom: 0; }
  @media (max-width: 991px) {
    .advantage-list {
      margin-bottom: -40px; }
      .advantage-list.advantage-second li {
        width: 50%; }
      .advantage-list li {
        width: 50%;
        margin-bottom: 30px; }
        .advantage-list li:nth-child(odd):after {
          display: none; }
        .advantage-list li:nth-child(1)::after, .advantage-list li:nth-child(4)::after, .advantage-list li:nth-child(5)::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: -20px;
          display: block !important;
          left: 0%;
          width: 100%;
          height: 192px;
          background: url(../img/cloudx2.png);
          background-size: 100% auto;
          background-repeat: no-repeat;
          transform: rotateY(180deg); } }
  @media (max-width: 767px) {
    .advantage-list li::after {
      left: auto !important;
      right: 0 !important;
      max-width: 320px !important;
      background-position: center center !important; } }
  @media (max-width: 550px) {
    .advantage-list.advantage-second li {
      margin-bottom: 35px; }
    .advantage-list li {
      width: 100%; }
      .advantage-list li:nth-child(1)::after, .advantage-list li:nth-child(4)::after, .advantage-list li:nth-child(5)::after {
        display: none !important; }
      .advantage-list li:nth-child(odd)::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -20px;
        left: 0%;
        right: auto;
        display: block !important;
        width: 60%;
        height: 192px;
        background: url(../img/cloudx2.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        transform: rotateY(180deg); }
      .advantage-list li:nth-child(1)::after, .advantage-list li:nth-child(5)::after {
        left: 20px !important; } }
  @media (max-width: 480px) {
    .advantage-list.advantage-second li {
      margin-bottom: 35px;
      width: 100%; } }

.adventage-slider .slick-dots {
  padding-top: 0;
  display: none; }

@media (max-width: 1199px) {
  .adventage-slider .slick-dots {
    display: flex;
    padding-top: 0; } }

@media (max-width: 767px) {
  .adventage-slider .slick-dots {
    margin-bottom: 30px;
    padding-top: 10px; } }

/*===> End advantage-list <===*/
/*===> Begin food-section <===*/
@media (max-width: 767px) {
  .food-section .svg-block {
    max-width: 310px;
    margin-top: 40px; } }

/*===> End food-section <===*/
/*===> Begin order-list-2 <===*/
.order-list-2 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -30px; }
  .order-list-2 li {
    width: 33.33%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px; }
    .order-list-2 li::before {
      display: none; }
    .order-list-2 li .list-description {
      position: relative;
      padding: 30px 30px 30px 80px;
      background: #fff;
      box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
      border-radius: 12px; }
      .order-list-2 li .list-description::before {
        content: counter(myCounter, decimal-leading-zero) ".";
        counter-increment: myCounter;
        position: absolute;
        top: 25px;
        left: 20px;
        display: inline-block;
        margin: 5px 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 32px;
        color: #46AADC; }
      .order-list-2 li .list-description p {
        margin-bottom: 0; }
  @media (max-width: 991px) {
    .order-list-2 li {
      width: 100%; } }
  @media (max-width: 550px) {
    .order-list-2 li {
      width: 100%; } }

/*===> End order-list-2 <===*/
/*===> Begin centre-tab-content <===*/
.centre-tab-content {
  position: relative;
  z-index: 10;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .5s; }
  .centre-tab-content.active {
    height: auto;
    overflow: visible;
    opacity: 1; }
  .centre-tab-content .tab-head {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 50px; }
  @media (max-width: 991px) {
    .centre-tab-content .tab-head {
      margin-bottom: 40px; } }
  @media (max-width: 480px) {
    .centre-tab-content .tab-head {
      margin-bottom: 30px; } }

.centre-tabs-wrap {
  position: relative; }

.centre-information-section {
  position: relative;
  z-index: 10; }
  .centre-information-section .container {
    position: relative;
    z-index: 10; }
    .centre-information-section .container .my-paroller {
      position: absolute;
      margin-left: -100px; }

/*===> End centre-tab-content <===*/
/*===> Begin teacher-card <===*/
.teacher-card {
  position: relative;
  display: flex;
  max-width: 440px;
  min-height: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 45px 30px;
  background: #fff;
  box-shadow: 0px 10px 22px rgba(42, 135, 158, 0.14);
  border-radius: 11px;
  overflow: hidden; }
  .teacher-card.second-style {
    display: flex;
    flex-direction: column;
    padding: 0; }
    .teacher-card.second-style .teacher-card-top {
      position: relative;
      padding-top: 30px; }
      .teacher-card.second-style .teacher-card-top .avatar-circle {
        position: relative;
        width: 170px;
        height: 170px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        overflow: hidden; }
        .teacher-card.second-style .teacher-card-top .avatar-circle img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%); }
      .teacher-card.second-style .teacher-card-top .cloud {
        position: absolute;
        top: 0;
        left: 50%;
        width: 607px;
        max-width: none;
        transform: translate(-50%, -50%); }
    .teacher-card.second-style .teacher-card__avatar {
      position: static; }
    .teacher-card.second-style .teacher-card__info {
      width: 100%;
      max-width: none;
      padding: 20px 30px 50px; }
      .teacher-card.second-style .teacher-card__info h5 {
        text-align: center; }
      .teacher-card.second-style .teacher-card__info p {
        text-align: center; }
      .teacher-card.second-style .teacher-card__info .soc-link {
        justify-content: center; }
  .teacher-card .teacher-card__info {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 160px; }
    .teacher-card .teacher-card__info h5 {
      margin-bottom: 10px; }
    .teacher-card .teacher-card__info p {
      margin-bottom: 25px; }
  .teacher-card .teacher-card__avatar {
    position: absolute;
    z-index: 2;
    top: 30px;
    right: -30%; }
    .teacher-card .teacher-card__avatar.extra__avatar {
      right: -40%; }
  @media (max-width: 1199px) {
    .teacher-card .teacher-card__avatar {
      right: -20%; }
      .teacher-card .teacher-card__avatar.extra__avatar {
        right: -30%; }
    .teacher-card .teacher-card__info {
      max-width: 210px; } }
  @media (max-width: 991px) {
    .teacher-card .teacher-card__avatar {
      right: -30%; }
      .teacher-card .teacher-card__avatar.extra__avatar {
        right: -45%; }
    .teacher-card .teacher-card__info {
      max-width: 150px; } }
  @media (max-width: 767px) {
    .teacher-card .teacher-card__avatar {
      right: -25%; }
      .teacher-card .teacher-card__avatar.extra__avatar {
        right: -30%; }
      .teacher-card .teacher-card__avatar.extra__avatar02 {
        right: -17%; }
    .teacher-card .teacher-card__info {
      max-width: 250px; }
      .teacher-card .teacher-card__info p {
        font-size: 14px; } }
  @media (max-width: 480px) {
    .teacher-card .teacher-card__avatar {
      right: -25%; }
      .teacher-card .teacher-card__avatar.extra__avatar {
        right: -30%; }
      .teacher-card .teacher-card__avatar.extra__avatar02 {
        right: -17%; }
    .teacher-card .teacher-card__info {
      max-width: 200px; } }
  @media (max-width: 450px) {
    .teacher-card .teacher-card__avatar {
      right: -25%; }
      .teacher-card .teacher-card__avatar.extra__avatar {
        right: -40%;
        top: inherit;
        bottom: 0; }
      .teacher-card .teacher-card__avatar.extra__avatar02 {
        right: -30%; }
    .teacher-card .teacher-card__info {
      max-width: 50%; } }

/*===> End teacher-card <===*/
/*===> Begin teacher-slider <===*/
.teacher-slider {
  margin-right: -15px;
  margin-left: -15px; }
  .teacher-slider .teacher-slide {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px; }
  .teacher-slider .slick-dots {
    padding-top: 25px; }
  @media (max-width: 991px) {
    .teacher-slider .slick-dots {
      padding-top: 15px; } }
  @media (max-width: 480px) {
    .teacher-slider .slick-dots {
      padding-top: 0; } }

/*===> Ens teacher-slider <===*/
/*===> Begin tab-values <===*/
.tab-values .tab-values__img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  max-width: 580px; }
  .tab-values .tab-values__img img {
    width: 100%;
    border-radius: 12px; }

.tab-values .tab-values__description p strong {
  color: #3B4757; }

.tab-values .tab-values__description ul li {
  margin-bottom: 30px; }

@media (max-width: 1199px) {
  .tab-values .tab-values__img {
    margin-bottom: 50px; } }

@media (max-width: 767px) {
  .tab-values .tab-values__img {
    margin-bottom: 40px; }
  .tab-values .tab-values__description ul li {
    margin-bottom: 20px; } }

@media (max-width: 480px) {
  .tab-values .tab-values__img {
    margin-bottom: 30px; } }

/*===> End tab-values <===*/
/*===> Begin testimonial-block <===*/
.testimonial-block {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  padding: 45px 30px 30px;
  background: #fff;
  border: 4px solid #EAF5F8;
  border-radius: 12px;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
  .testimonial-block::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 40px 0;
    border-color: transparent #EAF5F8 transparent transparent;
    transform: translate(0, 100%) skew(5deg); }
  .testimonial-block::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #fff transparent transparent;
    transform: translate(5px, 100%) skew(5deg); }
  .testimonial-block .testimonial-img {
    margin-bottom: 30px; }
    .testimonial-block .testimonial-img img {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .testimonial-block .testimonial-description h5 {
    margin-bottom: 15px;
    text-align: center; }
  .testimonial-block .testimonial-description time {
    display: block;
    margin-bottom: 14px;
    font-size: 14px;
    text-align: center;
    color: #46AADC; }
  .testimonial-block .testimonial-description p {
    margin-bottom: 0;
    text-align: center; }
  @media (max-width: 991px) {
    .testimonial-block {
      padding: 40px 30px 35px; } }
  @media (max-width: 480px) {
    .testimonial-block {
      padding-top: 35px;
      padding-bottom: 35px; } }

/*===> End testimonial-block <===*/
/*===> Begin testimonial-list <===*/
.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: -60px; }
  .testimonial-list.grid {
    margin-left: 0;
    margin-right: 0; }
  .testimonial-list li {
    width: 33.33%;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px; }
    .testimonial-list li::after {
      display: none; }
    .testimonial-list li:nth-child(3n+1) .testimonial-block {
      border: 4px solid #EAF5F8;
      box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
    .testimonial-list li:nth-child(3n+2) .testimonial-block {
      border: 4px solid #ECF5EE;
      box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21); }
    .testimonial-list li:nth-child(3n+3) .testimonial-block {
      border: 4px solid #F1EFEC;
      box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14); }
  .testimonial-list .slick-dots {
    padding-top: 25px;
    display: none; }
  @media (max-width: 1199px) {
    .testimonial-list .slick-dots {
      display: flex;
      margin-bottom: 40px;
      padding-top: 0; } }
  @media (max-width: 991px) {
    .testimonial-list li {
      width: 50%; } }
  @media (max-width: 767px) {
    .testimonial-list li {
      width: 100%; } }

.testimonial-wrap {
  margin-left: -15px;
  margin-right: -15px; }

/*===> End testimonial-list <===*/
/*===> Begin gallery-prewiev-wrap <===*/
.gallery-prewiev-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -15px; }
  .gallery-prewiev-wrap .gallery-item {
    width: 25%;
    margin-bottom: 15px;
    padding: 0 15px; }
    .gallery-prewiev-wrap .gallery-item.mini-gallery {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px; }
      .gallery-prewiev-wrap .gallery-item.mini-gallery .inner-gallery {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px; }
      .gallery-prewiev-wrap .gallery-item.mini-gallery .mini-gallery__item {
        width: 50%;
        padding-right: 15px;
        margin-bottom: 15px; }
        .gallery-prewiev-wrap .gallery-item.mini-gallery .mini-gallery__item:nth-child(3), .gallery-prewiev-wrap .gallery-item.mini-gallery .mini-gallery__item:nth-child(4) {
          margin-bottom: 0; }
    .gallery-prewiev-wrap .gallery-item a {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      overflow: hidden; }
      .gallery-prewiev-wrap .gallery-item a img {
        min-height: 100%;
        min-width: 100%;
        max-width: none;
        width: 100%; }
    .gallery-prewiev-wrap .gallery-item img {
      width: 100%; }
  @media (max-width: 767px) {
    .gallery-prewiev-wrap .gallery-item {
      width: 33.333%; } }
  @media (max-width: 480px) {
    .gallery-prewiev-wrap .gallery-item {
      width: 50%; }
      .gallery-prewiev-wrap .gallery-item:nth-child(odd) {
        padding-right: 7px; }
      .gallery-prewiev-wrap .gallery-item:nth-child(even) {
        padding-left: 7px; }
      .gallery-prewiev-wrap .gallery-item a {
        height: 100%; }
      .gallery-prewiev-wrap .gallery-item img {
        height: 100%; } }

/*===> End gallery-prewiev-wrap <===*/
/*===> Begin news-block <===*/
.news-block {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  box-shadow: 0px 10px 22px rgba(42, 135, 158, 0.14);
  overflow: hidden; }
  .news-block .news-block__img {
    display: block; }
    .news-block .news-block__img img {
      width: 100%; }
  .news-block .news-block-description {
    padding: 40px 30px 40px; }
    .news-block .news-block-description .news-block-description__main-info {
      display: flex;
      margin-bottom: 10px; }
      .news-block .news-block-description .news-block-description__main-info p {
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 14px; }
        .news-block .news-block-description .news-block-description__main-info p:last-child {
          margin-right: 0; }
        .news-block .news-block-description .news-block-description__main-info p.news-author {
          font-style: italic; }
    .news-block .news-block-description .news-block-description__short-text h4 {
      margin-bottom: 20px; }
      .news-block .news-block-description .news-block-description__short-text h4 a {
        color: #3B4757; }
        .news-block .news-block-description .news-block-description__short-text h4 a:hover {
          color: #46AADD; }
    .news-block .news-block-description .news-block-description__short-text p {
      margin-bottom: 30px; }
    .news-block .news-block-description .news-block-description__short-text time {
      display: block;
      font-size: 14px;
      color: #46AADC; }
  @media (max-width: 991px) {
    .news-block .news-block-description {
      padding: 35px 30px 35px; }
      .news-block .news-block-description .news-block-description__main-info {
        margin-bottom: 0; }
      .news-block .news-block-description .news-block-description__short-text p {
        margin-bottom: 20px; } }
  @media (max-width: 480px) {
    .news-block .news-block-description {
      padding: 33px 30px 33px; }
      .news-block .news-block-description .news-block-description__main-info {
        margin-bottom: 0; }
      .news-block .news-block-description .news-block-description__short-text h4 {
        margin-bottom: 15px; }
      .news-block .news-block-description .news-block-description__short-text p {
        margin-bottom: 20px; } }

/*===> End news-block <===*/
/*===> Begin news-slider <===*/
.news-slider {
  margin-left: -15px;
  margin-right: -15px; }
  .news-slider .news-slider__card {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px; }
  .news-slider .slick-dots {
    padding-top: 25px; }
  @media (max-width: 991px) {
    .news-slider .slick-dots {
      padding-top: 15px; } }
  @media (max-width: 480px) {
    .news-slider .slick-dots {
      padding-top: 15px; } }

/*===> End news-slider <===*/
/*===> Begin terms-list <===*/
.terms-list {
  margin-top: 90px; }
  .terms-list h3 {
    margin-bottom: 50px; }
  @media (max-width: 991px) {
    .terms-list h3 {
      margin-bottom: 40px; } }
  @media (max-width: 480px) {
    .terms-list h3 {
      margin-bottom: 25px; }
    .terms-list .flex-style li {
      width: 100%; } }

/*===> End terms-list <===*/
/*===> Begin pricing-packages-card <===*/
.pricing-packages-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 30px;
  background: #fff;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
  border-radius: 12px;
  overflow: hidden;
  text-align: center; }
  .pricing-packages-card .pricing-packages-description {
    width: 100%; }
    .pricing-packages-card .pricing-packages-description .pricing-packages-include p {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(233, 233, 233, 0.4); }
      .pricing-packages-card .pricing-packages-description .pricing-packages-include p:last-child {
        border-bottom: 0; }
  .pricing-packages-card .pricing-packages-img {
    margin-bottom: 15px; }
  .pricing-packages-card .pricing-packages-price {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    text-align: center; }
    .pricing-packages-card .pricing-packages-price .currency {
      font-size: 16px; }
    .pricing-packages-card .pricing-packages-price .price {
      margin: 0 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 42px;
      line-height: 1em;
      text-align: center;
      color: #46AADC; }
  @media (max-width: 1199px) {
    .pricing-packages-card .pricing-packages-img {
      height: 120px; }
      .pricing-packages-card .pricing-packages-img img {
        height: 100%; } }

/*===> End pricing-packages-card <===*/
/*===> Begin pricing-packages-slider <===*/
.pricing-packages-slider {
  margin-left: -15px;
  margin-right: -15px; }
  .pricing-packages-slider .pricing-packages-slide {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
    min-width: 33.33%; }
    .pricing-packages-slider .pricing-packages-slide:nth-child(1), .pricing-packages-slider .pricing-packages-slide:nth-child(3) {
      padding-top: 22px; }
  .pricing-packages-slider .slick-dots {
    padding-top: 25px;
    display: none; }
  @media (max-width: 1199px) {
    .pricing-packages-slider .slick-track {
      display: -webkit-flex !important;
      display: -moz-flex !important;
      display: -ms-flex !important;
      display: -o-flex !important;
      display: flex !important; }
    .pricing-packages-slider .pricing-packages-slide {
      height: 100%; }
      .pricing-packages-slider .pricing-packages-slide:nth-child(1), .pricing-packages-slider .pricing-packages-slide:nth-child(3) {
        padding-top: 0; }
    .pricing-packages-slider .slick-dots {
      display: flex;
      margin-bottom: 20px;
      padding-top: 12px; } }

/*===> End pricing-packages-slider <===*/
/*===> Begin statement-card <===*/
.statement-card {
  padding: 70px 40px;
  background: #fff;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
  border-radius: 12px;
  text-align: center; }
  .statement-card .statement-ico {
    margin-bottom: 20px;
    max-width: 66px;
    margin-left: auto;
    margin-right: auto; }
    .statement-card .statement-ico img {
      height: 66px; }
  .statement-card h4 {
    margin-bottom: 20px; }
  .statement-card p {
    margin-bottom: 0; }
  @media (max-width: 1199px) {
    .statement-card {
      padding: 60px 30px; } }
  @media (max-width: 767px) {
    .statement-card {
      padding: 30px 20px; }
      .statement-card .statement-ico {
        margin-bottom: 15px; }
        .statement-card .statement-ico img {
          height: 50px; } }
  @media (max-width: 480px) {
    .statement-card .statement-ico {
      margin-bottom: 10px; } }

.statement-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px; }
  .statement-list li {
    width: 33.33%;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px; }
    .statement-list li::after {
      display: none; }
    .statement-list li .statement-card {
      margin-bottom: 30px; }
  .statement-list .slick-dots {
    padding-top: 0;
    display: none; }
  @media (max-width: 1199px) {
    .statement-list .slick-dots {
      display: flex;
      margin-bottom: 40px;
      padding-top: 10px; }
      .statement-list .slick-dots li {
        margin-left: 0;
        margin-right: 25px; } }
  @media (max-width: 991px) {
    .statement-list li {
      width: 50%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767px) {
    .statement-list li {
      width: 100%; } }

/*===> End statement-card <===*/
/*===> Begin count-list <===*/
.count-section {
  min-height: 548px;
  display: flex;
  align-items: center; }

.count-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px; }
  .count-list .count-block-wrap {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%; }
    .count-list .count-block-wrap:nth-child(1) {
      transform: rotate(2deg); }
    .count-list .count-block-wrap:nth-child(2) {
      transform: rotate(-1deg); }
      .count-list .count-block-wrap:nth-child(2) .count-numb p {
        color: #FFAF60; }
    .count-list .count-block-wrap:nth-child(3) {
      transform: rotate(2deg); }
      .count-list .count-block-wrap:nth-child(3) .count-numb p {
        color: #76BEBA; }
    .count-list .count-block-wrap:nth-child(4) {
      transform: rotate(-1deg); }
      .count-list .count-block-wrap:nth-child(4) .count-numb p {
        color: #7192BD; }
  @media (max-width: 991px) {
    .count-list .count-block-wrap {
      width: 50%; } }

.count-block {
  display: block;
  width: 100%;
  max-width: 194px;
  min-height: 170px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 15px;
  background: #fff;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
  border-radius: 12px; }
  .count-block:nth-child(1) {
    transform: rotate(2deg); }
  .count-block:nth-child(2) {
    transform: rotate(-1deg); }
    .count-block:nth-child(2) .count-numb p {
      color: #FFAF60; }
  .count-block:nth-child(3) {
    transform: rotate(2deg); }
    .count-block:nth-child(3) .count-numb p {
      color: #76BEBA; }
  .count-block:nth-child(4) {
    transform: rotate(-1deg); }
    .count-block:nth-child(4) .count-numb p {
      color: #7192BD; }
  .count-block .count-numb {
    position: relative; }
    .count-block .count-numb p {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 1em;
      color: #46AADC;
      transform: translate(-50%, -50%); }
  .count-block .count-text {
    padding-top: 15px; }
    .count-block .count-text p {
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #3B4757; }

/*===> End count-list <===*/
/*===> Begin accordion-block <===*/
.accordion-block {
  padding-bottom: 30px; }
  .accordion-block.faq-to-hide {
    display: none; }
  .accordion-block.active .accordion-header .accordion-ico::after {
    transform: rotate(90deg); }
  .accordion-block .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    padding-right: 10px;
    border-bottom: 1px solid #CEDDE0;
    cursor: pointer; }
    .accordion-block .accordion-header .accordion-ico {
      position: relative;
      width: 15px;
      height: 15px; }
      .accordion-block .accordion-header .accordion-ico::before, .accordion-block .accordion-header .accordion-ico::after {
        content: '';
        position: absolute;
        background: #46AADC;
        transition: all .3s; }
      .accordion-block .accordion-header .accordion-ico::before {
        top: 8px;
        left: 1px;
        width: 15px;
        height: 1px; }
      .accordion-block .accordion-header .accordion-ico::after {
        top: 1px;
        left: 8px;
        width: 1px;
        height: 15px; }
    .accordion-block .accordion-header p {
      margin-bottom: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #3B4757; }
  .accordion-block .accordion-content {
    display: none;
    padding-top: 30px;
    padding-bottom: 20px; }
    .accordion-block .accordion-content p:last-child {
      margin-bottom: 0; }

/*===> End accordion-block <===*/
/*===> Begin program-preview <===*/
.program-preview {
  display: flex;
  margin-bottom: 45px;
  padding: 25px;
  background: #fff;
  border-radius: 12px;
  border: 4px solid #F1EFEC;
  box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14); }
  .program-preview .program-slider-wrap {
    position: relative;
    z-index: 10;
    width: 50%;
    padding: 25px; }
    .program-preview .program-slider-wrap .program-age {
      position: absolute;
      left: 10px;
      top: 45px;
      z-index: 10;
      padding: 6px 15px;
      background: #FFAF60;
      border-radius: 12px;
      box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
      .program-preview .program-slider-wrap .program-age p {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #fff; }
        .program-preview .program-slider-wrap .program-age p span {
          margin-right: 10px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 64px;
          line-height: 1em;
          color: #FFD1AA; }
    .program-preview .program-slider-wrap .program-big-slider {
      margin-bottom: 30px; }
      .program-preview .program-slider-wrap .program-big-slider img {
        border-radius: 12px;
        width: 100%; }
    .program-preview .program-slider-wrap .program-nav-slider {
      margin-left: -7px;
      margin-right: -7px; }
      .program-preview .program-slider-wrap .program-nav-slider .program-slide {
        position: relative;
        padding-left: 7px;
        padding-right: 7px; }
        .program-preview .program-slider-wrap .program-nav-slider .program-slide.slick-current::after {
          content: '';
          position: absolute;
          top: 0;
          left: 7px;
          z-index: 5;
          width: calc(100% - 14px);
          height: 100%;
          border-radius: 12px;
          border: 2px solid #46AADC; }
        .program-preview .program-slider-wrap .program-nav-slider .program-slide.slick-current::before {
          content: '';
          position: absolute;
          top: 0;
          left: 7px;
          z-index: 3;
          width: calc(100% - 14px);
          height: 100%;
          border-radius: 12px;
          border: 4px solid  #fff; }
      .program-preview .program-slider-wrap .program-nav-slider img {
        border-radius: 12px;
        width: 100%; }
  .program-preview .program-description {
    position: relative;
    padding: 25px; }
    .program-preview .program-description::after {
      content: attr(data-text);
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 160px;
      line-height: 1em;
      text-align: right;
      color: #F1EFEC;
      opacity: .5; }
    .program-preview .program-description .program-heaer {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: flex-end;
      justify-content: space-between; }
      .program-preview .program-description .program-heaer h3 {
        margin-bottom: 35px;
        line-height: 1em; }
      .program-preview .program-description .program-heaer p {
        margin-bottom: 35px; }
    .program-preview .program-description .program-text {
      position: relative;
      z-index: 10; }
      .program-preview .program-description .program-text p {
        margin-bottom: 25px; }
      .program-preview .program-description .program-text ul {
        margin-bottom: 0; }
        .program-preview .program-description .program-text ul li p {
          margin-bottom: 0; }
  @media (max-width: 991px) {
    .program-preview {
      flex-direction: column; }
      .program-preview .program-slider-wrap {
        width: 100%; }
      .program-preview .program-description {
        width: 100%; }
        .program-preview .program-description::after {
          font-size: 130px; }
        .program-preview .program-description .program-heaer h3 {
          margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .program-preview {
      margin-bottom: 35px; }
      .program-preview .program-slider-wrap {
        padding-bottom: 10px; }
        .program-preview .program-slider-wrap .program-age p span {
          font-size: 46px; }
      .program-preview .program-description::after {
        font-size: 100px; } }
  @media (max-width: 480px) {
    .program-preview {
      padding: 0; }
      .program-preview .program-slider-wrap .program-age {
        top: 15px; }
        .program-preview .program-slider-wrap .program-age p {
          font-size: 16px; }
          .program-preview .program-slider-wrap .program-age p span {
            font-size: 30px; }
      .program-preview .program-description::after {
        right: auto !important;
        left: 50% !important;
        transform: translateX(-50%);
        font-size: 45px; }
      .program-preview .program-description .program-heaer {
        flex-direction: column;
        align-items: flex-start; }
        .program-preview .program-description .program-heaer h3 {
          margin-bottom: 20px; }
        .program-preview .program-description .program-heaer p {
          margin-bottom: 20px; }
      .program-preview .program-description .program-text p {
        margin-bottom: 15px; }
      .program-preview .program-description ul li {
        width: 100%; } }

/*===> End program-preview <===*/
/*===> Begin programs-list <===*/
.programs-list > li {
  padding-left: 0; }
  .programs-list > li::after {
    display: none; }
  .programs-list > li:nth-child(even) .program-preview {
    flex-direction: row-reverse; }
    .programs-list > li:nth-child(even) .program-preview .program-description::after {
      right: auto;
      left: 0; }
  .programs-list > li:nth-child(3n+1) .program-preview {
    border: 4px solid #EAF5F8;
    box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
    .programs-list > li:nth-child(3n+1) .program-preview .program-description::after {
      color: #EAF5F8; }
  .programs-list > li:nth-child(3n+1) .program-slider-wrap .program-age {
    background: #FFAF60;
    box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14); }
    .programs-list > li:nth-child(3n+1) .program-slider-wrap .program-age p span {
      color: #FFD1AA; }
  .programs-list > li:nth-child(3n+2) .program-preview {
    border: 4px solid #ECF5EE;
    box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21); }
    .programs-list > li:nth-child(3n+2) .program-preview .program-description::after {
      color: #ECF5EE; }
  .programs-list > li:nth-child(3n+2) .program-slider-wrap .program-age {
    background: #76BEBA;
    box-shadow: 0px 14px 22px rgba(130, 174, 151, 0.21); }
    .programs-list > li:nth-child(3n+2) .program-slider-wrap .program-age p span {
      color: #B5D9D7; }
  .programs-list > li:nth-child(3n+3) .program-preview {
    border: 4px solid #F1EFEC;
    box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14); }
    .programs-list > li:nth-child(3n+3) .program-preview .program-description::after {
      color: #F1EFEC; }
  .programs-list > li:nth-child(3n+3) .program-slider-wrap .program-age {
    background: #7192BD;
    box-shadow: 0px 14px 22px rgba(156, 122, 82, 0.14); }
    .programs-list > li:nth-child(3n+3) .program-slider-wrap .program-age p span {
      color: #9FCFE9; }

@media (max-width: 991px) {
  .programs-list > li:nth-child(even) .program-preview {
    flex-direction: column; } }

/*===> End programs-list <===*/
/*===> Begin sidebar <===*/
.sidebar .search-form {
  margin-bottom: 50px; }

.sidebar .side-block {
  margin-bottom: 50px; }
  .sidebar .side-block h5 {
    margin-bottom: 30px; }
  .sidebar .side-block li a {
    font-size: 14px; }

/*===> End sidebar <===*/
/*===> Begin calenar <===*/
.calenar .now-data,
.calenar .next-mounth {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #46AADC; }

.calenar .next-mounth {
  margin-bottom: 0; }

.calenar table {
  margin-bottom: 15px; }
  .calenar table tr {
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 8px;
    line-height: 18px;
    text-align: center; }
  .calenar table thead tr {
    background: #46AADC; }
    .calenar table thead tr td, .calenar table thead tr th {
      color: #fff; }
  .calenar table tbody tr {
    padding-top: 4px;
    padding-bottom: 4px; }
    .calenar table tbody tr:nth-child(odd) {
      background: #F8FBFB; }
    .calenar table tbody tr:nth-child(even) {
      background: #EAF5F8; }
    .calenar table tbody tr td {
      padding-top: 8px;
      padding-bottom: 8px; }
      .calenar table tbody tr td.selected {
        padding-top: 6px;
        padding-bottom: 6px;
        background: #fff;
        border: 2px solid #46AADC;
        border-radius: 4px; }

/*===> End calenar <===*/
/*===> Begin side-post <===*/
.side-post {
  display: flex;
  margin-bottom: 25px; }
  .side-post .post-image {
    width: 80px;
    min-width: 80px;
    margin-right: 20px; }
    .side-post .post-image img {
      border-radius: 8px; }
  .side-post .post-meta {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #46AADC; }
  .side-post .post-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #3B4757; }
    .side-post .post-title:hover {
      color: #46AADD; }
  @media (max-width: 991px) {
    .side-post .post-title {
      font-size: 16px; } }

/*===> End side-post <===*/
/*===> Begin tag-list <===*/
.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px; }
  .tag-list li {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px; }
    .tag-list li::after {
      display: none; }
    .tag-list li a {
      margin: 0;
      border: 1px solid #DEECEF;
      border-radius: 8px;
      padding: 8px 15px;
      text-align: center; }
      .tag-list li a:hover, .tag-list li a.active {
        background: #46AADD;
        color: #fff; }

/*===> End tag-list <===*/
/*===> Begin blog-post <===*/
.blog-post {
  margin-bottom: 30px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 10px 22px rgba(42, 135, 158, 0.14); }
  .blog-post.single-post p {
    margin-bottom: 30px; }
  .blog-post.single-post ul {
    margin-bottom: 30px; }
    .blog-post.single-post ul li p {
      margin-bottom: 0; }
  .blog-post .post-image {
    position: relative; }
    .blog-post .post-image img {
      width: 100%; }
    .blog-post .post-image a {
      display: block; }
  .blog-post .post-video {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0; }
    .blog-post .post-video iframe,
    .blog-post .post-video object,
    .blog-post .post-video embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0; }
  .blog-post .post-music {
    padding: 50px 50px 0 50px; }
    .blog-post .post-music iframe,
    .blog-post .post-music object,
    .blog-post .post-music embed {
      width: 100%;
      height: auto;
      border: 0; }
  .blog-post .post-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); }
    .blog-post .post-link span {
      display: flex;
      flex-direction: column;
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      color: #fff; }
      .blog-post .post-link span i {
        margin-bottom: 10px;
        font-size: 36px;
        color: #46AADC; }
  .blog-post .post-teaser, .blog-post .post-description {
    padding: 50px; }
  .blog-post .post-meta {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px; }
    .blog-post .post-meta time {
      display: block;
      margin-bottom: 15px;
      margin-right: 20px;
      font-size: 14px;
      color: #46AADC; }
    .blog-post .post-meta .post-meta-author {
      margin-right: 20px;
      font-style: italic; }
      .blog-post .post-meta .post-meta-author a {
        color: #46AADC; }
        .blog-post .post-meta .post-meta-author a:hover {
          text-decoration: underline;
          color: #46AADD; }
    .blog-post .post-meta p {
      margin-bottom: 15px; }
  .blog-post .post-title {
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.3em;
    color: #3B4757; }
    .blog-post .post-title:hover {
      color: #46AADC; }
  .blog-post .short-text {
    margin-bottom: 30px; }
  .blog-post .round-img {
    border-radius: 12px; }
  .blog-post .flex-img {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .blog-post .flex-img img {
      width: 50%;
      margin-bottom: 30px; }
      .blog-post .flex-img img + * {
        width: 50%;
        padding-left: 30px; }
  @media (max-width: 991px) {
    .blog-post .post-teaser, .blog-post .post-description {
      padding: 35px 50px; }
    .blog-post .post-meta {
      margin-bottom: 10px; }
    .blog-post .post-title {
      margin-bottom: 25px; }
    .blog-post .short-text {
      margin-bottom: 25px; } }
  @media (max-width: 767px) {
    .blog-post .post-music {
      padding: 25px 25px 0 25px; }
    .blog-post .post-teaser, .blog-post .post-description {
      padding: 25px; }
    .blog-post .post-title {
      font-size: 26px; }
    .blog-post .flex-img {
      flex-direction: column; }
      .blog-post .flex-img img {
        width: auto;
        display: block;
        margin-left: auto;
        margin-right: auto; }
        .blog-post .flex-img img + * {
          width: 100%;
          padding-left: 0px; } }
  @media (max-width: 480px) {
    .blog-post .post-meta {
      margin-bottom: 5px; }
      .blog-post .post-meta time {
        margin-bottom: 5px; }
      .blog-post .post-meta p {
        margin-bottom: 5px; }
    .blog-post .post-music {
      padding: 20px 20px 0 20px; }
    .blog-post .post-teaser, .blog-post .post-description {
      padding: 30px 20px 30px 20px; }
    .blog-post .post-title {
      margin-bottom: 25px;
      font-size: 22px; }
    .blog-post .short-text {
      margin-bottom: 25px; } }

/*===> End blog-post <===*/
/*===> Begin post-author <===*/
.post-author {
  display: flex;
  padding: 30px;
  border-radius: 11px;
  background: #F0F8FA; }
  .post-author .author-avatar {
    position: relative;
    width: 120px;
    min-width: 120px;
    height: 120px;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden; }
    .post-author .author-avatar img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
  .post-author .author-info h4 {
    margin-bottom: 20px; }
  .post-author .author-info p {
    margin-bottom: 25px; }
  .post-author .author-info .soc-link {
    margin-bottom: 0; }
  @media (max-width: 480px) {
    .post-author {
      flex-direction: column;
      align-items: center;
      padding: 30px 15px; }
      .post-author .author-avatar {
        margin-right: 0;
        margin-bottom: 30px; }
      .post-author .author-info {
        width: 100%; } }

/*===> End post-author <===*/
/*===> Begin blog-post-nav <===*/
.post-navigate {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 991px) {
    .post-navigate {
      flex-direction: column-reverse;
      align-items: center; }
      .post-navigate .blog-post-nav {
        margin-bottom: 30px; } }

.blog-post-nav {
  display: flex;
  max-width: 270px; }
  .blog-post-nav.post-next {
    flex-direction: row-reverse; }
    .blog-post-nav.post-next .post-image {
      margin-right: 0;
      margin-left: 20px; }
    .blog-post-nav.post-next .blog-nav-link {
      flex-direction: row-reverse; }
      .blog-post-nav.post-next .blog-nav-link img {
        margin-right: 0;
        margin-left: 15px;
        transform: rotateY(180deg); }
    .blog-post-nav.post-next .post-title {
      text-align: right; }
  .blog-post-nav a {
    display: block; }
  .blog-post-nav .post-image {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 20px; }
    .blog-post-nav .post-image img {
      border-radius: 8px; }
  .blog-post-nav .blog-nav-link {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
    color: #777777; }
    .blog-post-nav .blog-nav-link:hover {
      color: #46AADC; }
    .blog-post-nav .blog-nav-link img {
      margin-right: 15px; }
  .blog-post-nav .post-title {
    margin: 0;
    font-size: 18px; }
  @media (max-width: 991px) {
    .blog-post-nav.post-prev {
      margin-bottom: 0; }
    .blog-post-nav .blog-nav-link {
      font-size: 14px; }
    .blog-post-nav .post-title {
      font-size: 15px; } }

/*===> End blog-post-nav <===*/
/*===> Begin comment-block <===*/
.comment-block {
  display: flex;
  margin-bottom: 50px; }
  .comment-block .user-pick {
    min-width: 80px;
    width: 80px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden; }
  .comment-block .text .meta {
    display: flex;
    flex-wrap: wrap; }
    .comment-block .text .meta time {
      margin-right: 20px;
      font-size: 14px;
      color: #46AADC; }
    .comment-block .text .meta .post-meta-author {
      font-style: italic; }
    .comment-block .text .meta p {
      margin-bottom: 10px;
      margin-right: 20px; }
  .comment-block .text p {
    margin-bottom: 10px; }
  .comment-block .text a {
    color: #46AADC; }
  @media (max-width: 991px) {
    .comment-block {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .comment-block {
      margin-bottom: 35px; } }

/*===> End comment-block <===*/
/*===> Begin comments-list <===*/
.comments-list {
  padding-left: 0; }
  .comments-list .comment-wrap {
    padding-left: 0; }
    .comments-list .comment-wrap::after {
      display: none; }
  .comments-list .comments-list {
    padding-left: 40px; }

/*===> End comments-list <===*/
/*===> Begin comments <===*/
.comments {
  margin-bottom: 90px; }
  .comments h4 {
    margin-bottom: 40px; }
    .comments h4 span {
      font-size: 18px;
      color: #46AADC; }
  @media (max-width: 991px) {
    .comments {
      margin-bottom: 70px; } }
  @media (max-width: 767px) {
    .comments {
      margin-bottom: 50px; } }

/*===> End comments <===*/
/*===> Begin parent-info-list <===*/
.parent-info-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .parent-info-list li {
    width: 50%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px; }
    .parent-info-list li::after {
      display: none; }
  @media (max-width: 991px) {
    .parent-info-list li {
      width: 100%; } }

/*===> End parent-info-list <===*/
/*===> Begin parent-info-item <===*/
.parent-info-item {
  display: flex;
  padding: 35px 30px 35px 35px;
  border: 4px solid #EAF5F8;
  box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
  border-radius: 12px; }
  .parent-info-item .parent-info-image {
    width: 164px;
    min-width: 164px;
    margin-right: 30px; }
    .parent-info-item .parent-info-image img {
      border-radius: 8px; }
  .parent-info-item .parent-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .parent-info-item .parent-title h5 {
      margin-bottom: 0; }
    .parent-info-item .parent-title .parent-title__ico {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: #46AADC;
      border-radius: 6px; }
      .parent-info-item .parent-title .parent-title__ico img {
        filter: brightness(0) invert(1); }
  @media (max-width: 480px) {
    .parent-info-item {
      flex-direction: column;
      align-items: center;
      padding: 20px; }
      .parent-info-item .parent-info-image {
        margin-right: 0;
        margin-bottom: 30px; } }

/*===> End parent-info-item <===*/
/*===> Begin resources-list <===*/
.resources-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -40px; }
  .resources-list li {
    width: 25%;
    padding-left: 15px;
    padding-right: 15px; }
    .resources-list li::after {
      display: none; }
  @media (max-width: 991px) {
    .resources-list li {
      width: 50%; } }
  @media (max-width: 480px) {
    .resources-list li {
      width: 100%; } }

/*===> End resources-list <===*/
/*===> Begin resources-block <===*/
.resources-block {
  max-width: 195px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto; }
  .resources-block .resources-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 170px;
    margin-bottom: 25px;
    padding: 45px;
    background: #FFFFFF;
    box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
    border-radius: 12px; }
    .resources-block .resources-img .resource-ico {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 75px; }
  .resources-block .resources-text {
    text-align: center; }
    .resources-block .resources-text p {
      margin-bottom: 10px;
      font-family: #3B4757;
      font-weight: 500;
      color: #3B4757; }
    .resources-block .resources-text a {
      font-family: #3B4757;
      font-weight: 500;
      color: #46AADC; }
      .resources-block .resources-text a img {
        margin-right: 10px; }

/*===> End resources-block <===*/
/*===> Begin practices-list-wrap <===*/
.practices-list-wrap {
  position: relative;
  padding-left: 100px;
  z-index: 1; }
  .practices-list-wrap .practices-ico {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #46AADC;
    border-radius: 8px; }
    .practices-list-wrap .practices-ico.prohibited {
      background: #FFAF60; }
    .practices-list-wrap .practices-ico img {
      height: 22px;
      filter: brightness(0) invert(1); }
  .practices-list-wrap .practices-list.prohibited li::after {
    content: url("../img/close.svg"); }
  .practices-list-wrap .practices-list li::after {
    content: url("../img/check.svg"); }
  @media (max-width: 767px) {
    .practices-list-wrap {
      padding-left: 50px; }
      .practices-list-wrap .practices-ico {
        width: 30px;
        height: 30px; }
        .practices-list-wrap .practices-ico img {
          height: 18px; } }

.guidance-section {
  position: relative; }
  .guidance-section::after {
    content: '';
    position: absolute;
    z-index: -1;
    right: 15%;
    top: 120px;
    width: 300px;
    height: 146px;
    background: url(../img/cloud.svg);
    background-size: auto 100%;
    background-repeat: no-repeat; }
  .guidance-section .row {
    z-index: -10; }
  @media (max-width: 767px) {
    .guidance-section::after {
      display: none; } }

/*===> End practices-list-wrap <===*/
/*===> Begin full-width-gallery-grid <===*/
.full-width-gallery-grid {
  display: flex;
  flex-wrap: wrap; }
  .full-width-gallery-grid .grid-item {
    width: 20%; }
    .full-width-gallery-grid .grid-item a {
      display: block; }
    .full-width-gallery-grid .grid-item img {
      width: 100%; }
  @media (max-width: 991px) {
    .full-width-gallery-grid .grid-item {
      width: 25%; } }
  @media (max-width: 767px) {
    .full-width-gallery-grid .grid-item {
      width: 33.33%; } }
  @media (max-width: 480px) {
    .full-width-gallery-grid .grid-item {
      width: 50%; } }

/*===> End full-width-gallery-grid <===*/
/*===> Begin boxed-gallery-grid <===*/
.boxed-gallery-grid {
  margin-left: -15px;
  margin-right: -15px; }
  .boxed-gallery-grid .grid-item {
    width: 25%;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px; }
    .boxed-gallery-grid .grid-item img {
      border-radius: 8px; }
  @media (max-width: 767px) {
    .boxed-gallery-grid .grid-item {
      width: 33.33%; } }
  @media (max-width: 480px) {
    .boxed-gallery-grid {
      margin-left: -7px;
      margin-right: -7px; }
      .boxed-gallery-grid .grid-item {
        width: 50%;
        margin-bottom: 15px;
        padding-left: 7px;
        padding-right: 7px; } }

/*===> End boxed-gallery-grid <===*/
/*===> Begin event-table <===*/
.event-table {
  border-collapse: collapse;
  margin-bottom: 0; }
  .event-table thead tr td {
    width: 14.285%;
    padding: 15px;
    border-top: 1px solid #DDE7E9;
    background: #EAF5F8;
    text-align: center;
    color: #46AADC; }
    .event-table thead tr td:nth-child(1) {
      border-radius: 12px 0 0 0; }
    .event-table thead tr td:last-child {
      border-radius: 0 12px 0 0; }
  .event-table tbody tr:last-child td:nth-child(1) {
    border-radius: 0 0 0 12px; }
  .event-table tbody tr:last-child td:last-child {
    border-radius: 0 0 12px 0; }
  .event-table tbody tr:nth-child(even) td {
    background: #F8FBFB; }
  .event-table tbody tr td {
    position: relative;
    background: #fff;
    text-align: left;
    padding: 20px;
    transition: all .0s; }
    .event-table tbody tr td.last-mounth {
      opacity: .4; }
    .event-table tbody tr td:hover {
      opacity: 1;
      background: #EAF5F8;
      box-shadow: 0px 0px 12px rgba(70, 170, 220, 0.4); }
      .event-table tbody tr td:hover .hide-event {
        opacity: 1;
        visibility: visible; }
    .event-table tbody tr td:nth-child(n+4) .hide-event {
      left: auto;
      right: 50%; }
      .event-table tbody tr td:nth-child(n+4) .hide-event::after {
        content: '';
        position: absolute;
        top: 20px;
        right: auto;
        left: 100%;
        z-index: -1;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 0 16px  16px;
        border-color: transparent transparent transparent #fff; }
  .event-table tr {
    margin-bottom: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent; }
    .event-table tr td {
      width: 14.285%;
      border-right: 1px solid #DDE7E9;
      border-bottom: 1px solid #DDE7E9; }
      .event-table tr td:first-child {
        border-left: 1px solid #DDE7E9; }
      .event-table tr td.currentle-day .data {
        color: #fff; }
        .event-table tr td.currentle-day .data:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #46AADC; }
      .event-table tr td .data {
        display: inline-block;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
        color: #46AADC; }
      .event-table tr td .table-event p {
        font-weight: 500;
        color: #3B4757; }
  .event-table .hide-event {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 30;
    width: 388px;
    background: #FFFFFF;
    box-shadow: 3px 10px 30px rgba(42, 135, 158, 0.23);
    border-radius: 12px;
    padding: 25px 30px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s; }
    .event-table .hide-event::after {
      content: '';
      position: absolute;
      top: 20px;
      right: 100%;
      z-index: -1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 16px 16px 16px 0;
      border-color: transparent #fff transparent transparent; }
    .event-table .hide-event .event-title {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      line-height: 1em;
      color: #3B4757; }
    .event-table .hide-event time {
      display: block;
      margin-bottom: 20px; }
    .event-table .hide-event img {
      margin-bottom: 20px;
      border-radius: 12px; }
    .event-table .hide-event p {
      font-family: "Roboto", sans-serif;
      font-weight: 400 !important;
      color: #777777 !important; }
  @media (max-width: 991px) {
    .event-table {
      min-width: 1000px; }
      .event-table tr:nth-child(n+4) .hide-event {
        top: auto;
        bottom: 20px; }
        .event-table tr:nth-child(n+4) .hide-event::after {
          top: auto !important;
          bottom: 20px !important; }
      .event-table .hide-event {
        width: 200px; }
        .event-table .hide-event .event-img {
          margin-bottom: 10px;
          height: 80px; }
          .event-table .hide-event .event-img a {
            display: block;
            height: 100%; }
          .event-table .hide-event .event-img img {
            height: 100%; }
        .event-table .hide-event .event-title {
          display: block; } }

.table-wrap-nav {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .table-wrap-nav a {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #46AADD; }

@media (max-width: 991px) {
  .event-table-wrap {
    overflow-x: auto; } }

/*===> End event-table <===*/
/*===> Begin single-event <===*/
.single-event {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .single-event .event-image {
    position: relative;
    width: 50%;
    margin-bottom: 90px;
    padding-left: 15px;
    padding-right: 15px; }
    .single-event .event-image img {
      width: 100%;
      border-radius: 12px; }
    .single-event .event-image time {
      position: absolute;
      top: 20px;
      left: 35px;
      z-index: 10;
      width: 110px;
      height: 110px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 72px;
      line-height: 1em;
      display: flex;
      align-items: center;
      text-align: center;
      color: #46AADC; }
      .single-event .event-image time span {
        padding-top: 5px;
        font-weight: normal;
        font-size: 14px;
        line-height: 1em;
        text-align: center;
        color: #3B4757; }
  .single-event .event-description {
    width: 50%;
    margin-bottom: 90px;
    padding-left: 15px;
    padding-right: 15px; }
    .single-event .event-description h2 {
      margin-bottom: 30px; }
    .single-event .event-description p strong {
      color: #3B4757; }
  @media (max-width: 991px) {
    .single-event .event-image {
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px; }
    .single-event .event-description {
      width: 100%;
      margin-bottom: 50px; } }
  @media (max-width: 767px) {
    .single-event .event-image {
      margin-bottom: 40px; }
    .single-event .event-description {
      margin-bottom: 30px; }
      .single-event .event-description h2 {
        margin-bottom: 20px; } }

/*===> End single-event <===*/
/*===> Begin short-event  <===*/
.short-event {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px; }
  .short-event a {
    display: block;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #3B4757; }
    .short-event a img {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 12px; }
  .short-event time {
    display: block;
    font-size: 14px;
    color: #46AADC; }
  @media (max-width: 991px) {
    .short-event {
      margin-bottom: 40px; }
      .short-event a {
        margin-bottom: 5px; } }
  @media (max-width: 480px) {
    .short-event a {
      margin-bottom: 0; } }

.related-event-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -30px; }
  .related-event-list .event-slide {
    width: 25%;
    padding-left: 15px;
    padding-right: 15px; }
  @media (max-width: 991px) {
    .related-event-list .event-slide {
      width: 50%; } }
  @media (max-width: 767px) {
    .related-event-list h3 {
      margin-bottom: 30px; } }

/*===> End short-event  <===*/
/*===> Begin contact-container <===*/
.contact-container .map-block {
  min-height: 300px;
  height: 100%; }

/*===> End contact-container <===*/
