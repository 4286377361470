/*===> Begin comment-block <===*/
.comment-block {
	display: flex;
	margin-bottom: 50px;

	.user-pick {
		min-width: 80px;
		width: 80px;
		margin-right: 20px;
		border-radius: 8px;
		overflow: hidden;
	}

	.text {
		.meta {
			display: flex;
			flex-wrap: wrap;

			time {
				margin-right: 20px;
				font-size: 14px;
				color: $main_color;
			}

			.post-meta-author {
				font-style: italic;
			}

			p {
				margin-bottom: 10px;
				margin-right: 20px;
			}
		}

		p {
			margin-bottom: 10px;
		}

		a {
			color: $main_color;
		}
	}

	@include breakpoint(small) {
		margin-bottom: 40px;
	}

	@include breakpoint(extra-small) {
		margin-bottom: 35px;
	}
}
/*===> End comment-block <===*/