/*===> Begin contact list <===*/
.contact-list {
	margin-bottom: -15px;
	a, p {
		display: flex;
		align-items: flex-start;
		margin-bottom: 15px;
		font-family: $main_font;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 1.8em;
		color: $text_color;

		img {
			margin-top: 5px;
			margin-right: 13px;
		}
	}

	a {
		&:hover {
			color: $main_color;
		}
	}

	@include breakpoint(small) {
		a, p {
	    	font-size: 14px;
		}
	}
}
/*===> End contact list <===*/