/*===> Begin learning-elements-wrap <===*/
.el-wr {
	padding: 20px 25px 0;

	@include breakpoint(small) {
		margin-top: 60px;
	}

	@include breakpoint(mobile) {
		margin-top: 25px;
		padding: 20px 0 0;
	}
}

.learning-elements-wrap {
	position: relative;
	width: 100%;
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;

	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 5%;
		left: 5%;
		width: 90%;
		height: 90%;
		border: 5px solid $bloquote_bg;
		border-radius: 50%;
	}

	.learning-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #EAF5F7;
		cursor: pointer;
	
		img {
			margin-bottom: 5px;
		}
	
		p {
			margin-bottom: 0;
			font-family: $head_font;
			font-weight: 500;
			font-size: 12px;
			color: $head_color;
		}
	}

	.circle-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		border-radius: 50%;
		z-index: 5;
	
		.learning-item {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 5;
			margin-left: -62px;
			margin-top: -62px;
			width: 124px;
			height: 124px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #EAF5F7;

			&::before {
				content: '';
				position: absolute;
				top: -4px;
				left: -4px;
				z-index: -2;
				width: calc(100% + 8px);
				height: calc(100% + 8px);
				background: #fff;
				border-radius: 50%;
				transition: all .3s;
			}

			&::after {
				content: '';
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: #EAF5F7;
				border-radius: 50%;
				transition: all .3s;
			}

			&.active, &:hover {
				background: #fff;

				&::before {
					background: #EAF5F7;
				}

				&::after {
					background: #fff;
				}

			}
	
			img {
				margin-bottom: 5px;
			}
	
			p {
				margin-bottom: 0;
				font-family: $head_font;
				font-weight: 500;
				font-size: 12px;
				color: $head_color;
			}
		}
	}

	.tab-element-content {
		position: absolute;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 55%;
		height: 60%;
		transform: translate(-50%, -50%);

		.for-tab {
			display: none;

			&.active {
				display: block;
			}
		}

		.for-tab.start {
			display: block;
			h3 {
				margin-bottom: 0;
			}
		}

		h3 {
			position: relative;
			padding-bottom: 20px;
			margin-bottom: 20px;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 41px;
				height: 3px;
				background: $main_color;
			}
		}
	}

	.my-paroller {
		position: absolute;
		left: -50px;
		bottom: 0;
		z-index: -1;
	}
	
	@include breakpoint(medium) {
		max-width: 450px;

		&::after {
			top: 3%;
			left: 3%;
			width: 95%;
			height: 95%;
		}

		.circle-container {
			.learning-item {
				margin-left: -50px;
				margin-top: -50px;
				width: 100px;
				height: 100px;
			}
		}

		.for-tab {
			h3 {
				margin-bottom: 0;
			}

			p {
				display: none;
			}
		}
	}

	@include breakpoint(small) {
		.for-tab {
			h3 {
				margin-bottom: 20px;
			}

			p {
				display: block;
			}
		}
	}

	@include breakpoint(extra-small) {
		.my-paroller {
			opacity: .5;
		}
	}

	@include breakpoint(mobile) {
		padding-left: 10px;
		padding-right: 10px;

		&::after {
			top: 6%;
			left: 6%;
			width: 88%;
			height: 88%;
			border: 5px solid $bloquote_bg;
			border-radius: 50%;
		}

		.circle-container {
			.learning-item {
				margin-left: -30px;
				margin-top: -30px;
				width: 60px;
				height: 60px;

				img {
					height: 30px;
					margin-bottom: 0;
				}

				p {
					display: none;
				}
			}
		}

		.for-tab {
			h3 {
				font-size: 18px;
				padding-bottom: 10px;
				margin-bottom: 0;
			}

			p {
				display: none;
				font-size: 11px;
				// display: none;
			}
		}
	}
}
/*===> End learning-elements-wrap <===*/