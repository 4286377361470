/*===> Begin full-width-gallery-grid <===*/
.full-width-gallery-grid {
	display: flex;
	flex-wrap: wrap;
	.grid-item {
		width: 20%;

		a {
			display: block;
		}

		img {
			width: 100%;
		}
	}

	@include breakpoint(small) {
		.grid-item {
			width: 25%;
		}
	}

	@include breakpoint(extra-small) {
		.grid-item {
			width: 33.33%;
		}
	}

	@include breakpoint(mobile) {
		.grid-item {
			width: 50%;
		}
	}
}
/*===> End full-width-gallery-grid <===*/