/*===> Begin main nav list <===*/
.main-nav-list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 17px;

	li {
		position: relative;
		margin-right: 35px;
		margin-bottom: 0;
		padding-left: 0;

		&:last-child {
			margin-right: 0;
		}

		&::after {
			display: none;
		}

		&:nth-child(n+4) {
			.inner-dropdown-menu {
				left: auto;
				right: 100%;
			}
		}

		&:hover {
			.tt-dropdown-menu {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
			}
		}

		a {
			padding: 15px 0;
			font-family: $head_font;
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: normal;
			color: $head_color;
			transition: all .3s;

			&:hover,
			&.active-link {
				color: $hover_color;
			}

			i {
				display: none;
			}
		}

		.tt-dropdown-menu {
			position: absolute;
			z-index: 100;
			top: 100%;
			left: 0;
			width: 220px;
			padding-top: 20px;
			padding-bottom: 10px;
			border-top: 3px solid  $hover_color;
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
			background: #fff;
			transition: all .3s;
			opacity: 0;
			visibility: hidden;
			transform: translateY(20px);

			li {
				position: relative;
				margin-right: 0;

				&:hover {

					a {
						i {
							transform: translateY(calc(-50% - 5px)) rotate(180deg);
						}
					}

					.inner-dropdown-menu {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}

				a {
					display: block;
					padding: 0 20px 10px;

					i {
						position: absolute;
						top: 50%;
						right: 15px;
						display: block;
						transform: translateY(calc(-50% - 5px));
						transition: transform .3s;
					}
				}
			}
		}

		.inner-dropdown-menu {
			position: absolute;
			top: -10px;
			padding: 10px 0;
			left: 100%;
			width: 200px;
			transition: all .3s;
			opacity: 0;
			box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
			background: #fff;
			visibility: hidden;
			transform: translateY(20px);
		}
	}

	@include breakpoint(medium) {
		padding: 0;
	}
	
	@include breakpoint(medium) {
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
		overflow: auto;

		li {
			display: block;
			width: 100%;
			margin-right: 0;

			&.active {
				a i {
					transform: rotate(180deg);
				}
			}

			a {
				position: relative;
				display: block;
				width: 100%;
				padding-bottom: 10px;
				padding-top: 10px;
				padding-right: 40px;

				i {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					top: 0;
					right: 0;
					width: 40px;
					height: 100%;
				}
			}

			.tt-dropdown-menu {
				position: static;
				width: 100%;
				display: none;
				padding-top: 0;
				padding-bottom: 0;
				border-top: 0;
				box-shadow: none;
				visibility: visible;
				opacity: 1;
				transform: translate(0, 0);
				transition: all .0s;

				li {

					&:hover {
						a {
							i {
								transform: rotate(0deg);
							}
						}
					}

					&.active {
						a i {
							transform: rotate(180deg);
						}
					}

					a {
						position: relative;
						padding-top: 10px;
						padding-right: 40px;
						padding-left: 10px;

						i {
							position: absolute;
							display: flex;
							align-items: center;
							justify-content: center;
							top: 0;
							right: 0;
							width: 40px;
							height: 100%;
							transform: translate(0);
						}
					}
				}
			}

			.inner-dropdown-menu {
				position: static;
				width: 100%;
				display: none;
				padding: 0;
				box-shadow: none;
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				transition: all .0s;

				li {
					a {
						padding-left: 20px;
					}
				}
			}
		}
	}

	@include breakpoint(small) {
	    li {
	    	a {
	    		font-size: 14px;
	    	}
	    }
	}
}
/*===> End main nav list <===*/