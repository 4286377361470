/*===> Begin datetimepicker <===*/
.bootstrap-datetimepicker-widget, .datepicker   {

 	&[class^="far"],
 	&[class*="far"],
 	&[class^="fas"],
 	&[class*="fas"] {
 		font-family: $font_awsome !important;
 		padding-left: 0 !important; 
 		font-weight: 900;
 	}

	.list-unstyled {
		margin-bottom: 0;
		li {
			padding-left: 0;

			&::after {
				display: none;
			}

			&:nth-child(1) {
				margin-bottom: 0;
			}

			&:nth-child(2) {
				// display: none;
			}
		}
	}

	thead {
		tr {
			display: flex;
			border-radius: 3px;

			th {
				flex-grow: 1;
			}
		}
	}

	table {
		tr {
			padding-top: 5px;
			padding-bottom: 5px;

			&:nth-child(2) {
				padding-left: 0;
				padding-right: 0;
				background: #fff;

				 th {
					margin: 2px;
					background: $table_color;
					text-align: center;
				}
			}
		}

		td {
			height: auto;
		}
	}

	table,
	.datepicker-months,
	.datepicker-years,
	.datepicker-decades {
		tbody tr {
			background: #fff;
			padding-left: 0;
			padding-right: 0;

			span, td {
				text-align: center;

				&.active {
					background: $main_color;
				}
			}

			td {
				text-align: left;
			}

			span {
				background: $table_color;
				&:not([class]) {
					display: none;
				}
			}
		}
	}

	.datepicker-days {
		tbody tr {
			margin-bottom: 0;
			padding-top: 0;
			padding-left: 0;
			padding-bottom: 2px;
			padding-right: 0;
			background: #fff;

			 td {
				margin: 2px;
				background: $table_color;
				text-align: center;

				&.active {
					background: $main_color;
				}
			}
		}
	}
} 

.bootstrap-datetimepicker-widget.dropdown-menu {
	@include breakpoint(mobile) {
		width: 275px;
	}
}

/*===> End datetimepicker <===*/