/*===> Begin parent-info-item <===*/
.parent-info-item {
	display: flex;
	padding: 35px 30px 35px 35px;
	border: 4px solid $table_color;
	box-shadow: 0px 14px 22px rgba(42, 135, 158, 0.14);
	border-radius: 12px;

	.parent-info-image {
		width: 164px;
		min-width: 164px;
		margin-right: 30px;

		img {
			border-radius: 8px;
		}
	}

	.parent-title {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		h5 {
			margin-bottom: 0;
		}

		.parent-title__ico {
			width: 34px;
			height: 34px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 15px;
			background: $main_color;
			border-radius: 6px;

			img {
				filter: brightness(0) invert(1);
			}
		}
	}

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.parent-info-image {
			margin-right: 0;
			margin-bottom: 30px;
		}
	}
}
/*===> End parent-info-item <===*/