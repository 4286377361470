/*===> Begin post-author <===*/
.post-author {
	display: flex;
	padding: 30px;
	border-radius: 11px;
	background: $bloquote_bg;

	.author-avatar {
		position: relative;
		width: 120px;
		min-width: 120px;
		height: 120px;
		margin-right: 30px;
		border-radius: 50%;
		overflow: hidden;

		img {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.author-info {
		h4 {
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 25px;
		}

		.soc-link {
			margin-bottom: 0;
		}
	}

	@include breakpoint(mobile) {
		flex-direction: column;
		align-items: center;
		padding: 30px 15px;

		.author-avatar {
			margin-right: 0;
			margin-bottom: 30px;
		}

		.author-info {
			width: 100%;

			h4 {
				// font-size: 18px;
			}
		}
	}
}
/*===> End post-author <===*/