/*===> Begin lightbox <===*/
.modal-body {
	overflow: hidden;

	.ekko-lightbox-item {
		overflow: hidden;
	}
}

.modal-header {
	.close {
		padding: 5px;
		box-shadow: none;
	}
}

.ekko-lightbox-nav-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	a {
		display: block;
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 40px;
		color: #fff;

		&:nth-child(1) {
			padding-left: 20px;
		}

		&:nth-child(2) {
			justify-content: flex-end;
			padding-right: 20px;
		}
	}
}
/*===> End lightbox <===*/