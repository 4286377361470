/* ============================================================

 Theme Name: Child IT
 Theme URI: http://
 Description: The Child IT Multipage Template
 Author: MPIThemes
 Author URI: https://
 Version: 1.0

============================================================== */
/* ==============================================================

 ====>> Table Of Content

	* COLORS
	* Fonts
	* base style

	* ===== Components ===== *
		* section header
		* square icon
		* round icon
		* icons list
		* first letter
		* table
		* button
		* checkbox
		* time picker
		* datetimepicker
		* read-more
		* svg-block
		* video-btn
		* centre-tab-list
		* pagination
		* on-scroll
		* up-btn
	

	* ==== Slick ==== *
		* slick arrow
		* slick-dots
		

	* ===== Forms ===== *
		* Main form
		* signup form
		* book form
		* form controls
		* search-form

	* ===== Widgets ===== *
		* page name
		* wave block
		* contact list
		* soc link
		* quickLinks
		* download list
		* calendar table
		* main slider
		* block-header
		* text-block
		* education short
		* learning-elements-wrap
		* education-slier
		* video-section
		* lightbox
		* advantage-list
		* food-section
		* order-list-2
		* centre-tab-content
		* teacher-card
		* teacher-slider
		* tab-values
		* testimonial-block
		* testimonial-list
		* gallery-prewiev-wrap
		* news-block
		* news-slider
		* terms-list
		* pricing-packages-card
		* pricing-packages-slider
		* statement-card
		* count-list
		* accordion-block
		* program-preview
		* sidebar
		* calenar
		* side-post
		* tag-list
		* blog-post
		* post-author
		* blog-post-nav
		* comment-block
		* comments-list
		* comments
		* parent-info-list
		* parent-info-item
		* resources-list
		* resources-block
		* practices-list-wrap
		* full-width-gallery-grid
		* boxed-gallery-grid
		* event-table
		* single-event
		* short-event
		* contact-container

		* ==== Footer ==== *
			* footer
		
		* ==== Header ==== *
			* header
			* main nav
			* top line
			* main nav list
			* header controls

	* ===== Layouts ===== *
		* 
		


============================================================== */