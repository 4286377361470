/*===> Begin book form <===*/
.book-form {
	input {
		margin-bottom: 5px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.check-list-wrap {
		padding-top: 15px;
		display: flex;

		.check-list-header {
			margin-right: 20px;
		}

		.check-list {
			display: flex;
			flex-wrap: wrap;

			label {
				width: 50%;
			}
		}
	}

	.time-picker {
		margin-bottom: 5px;
	}

	@include breakpoint(mobile) {
		.check-list-wrap {
			flex-direction: column;
		}
	}
}
/*===> End book form <===*/