/*===> Begin sidebar <===*/
.sidebar {
	.search-form {
		margin-bottom: 50px;
	}

	.side-block {
		margin-bottom: 50px;
		h5 {
			margin-bottom: 30px;
		}

		li {
			a {
				font-size: 14px;
			}
		}
	}
}
/*===> End sidebar <===*/