/*===> Begin slick-dots <===*/
.slick-dots {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 50px;

	li {
		width: auto !important;
		margin-bottom: 0 !important;
		margin-right: 25px;
		padding: 0 !important;

		&.last-child {
			margin-right: 0;
		}

		&.slick-active {
			button {
				width: 16px;
				height: 16px;

				&:after {
					width: 16px;
					height: 16px;
					border: 3px solid $main_color;
					background: #fff;
				}
			}
		}

		&::after {
			display: none;
		}

		button {
			position: relative;
			display: block;
			width: 16px;
			height: 16px;
			padding: 0;
			font-size: 0;
			line-height: 0;
			background-color: transparent;
			box-shadow: none;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8px;
				height: 8px;
				background: $main_color;
				border-radius: 50%;
				transition: all .3s;
			}
		}
	}

	@include breakpoint(small) {
		padding-top: 15px;
	}
}

.white-dots {
	.slick-dots {
		li {
			&.slick-active {
				button {
					&:after {
						border: 3px solid #fff;
						background-color: transparent;
					}
				}
			}

			button {
				&:after {
					background: #fff;
				}
			}
		}
	}
}
/*===> Ens slick-dots <===*/