/*===> Begin resources-list <===*/
.resources-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -40px;

	li {
		width: 25%;
		padding-left: 15px;
		padding-right: 15px;

		&::after {
			display: none;
		}
	}

	@include breakpoint(small) {
		li {
			width: 50%;
		}
	}

	@include breakpoint(mobile) {
		li {
			width: 100%;
		}
	}
}
/*===> End resources-list <===*/